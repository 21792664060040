<template>
  <div v-loading.fullscreen.lock="loading">
    <!-- Portion 1  -->
    <transition name="fade">
      <div class="container-fluid" v-if="showForm">
        <div class="row">
          <!-- Left Section -->
          <div class="col-md-6 p-0 position-relative hide-on-mobile">
            <img
              src="signup-img/bg-left.jpg"
              alt="Left Image"
              class="img-fluid w-100"
              style="object-fit: cover; height: 95vh"
            />

            <div class="text-overlay">
              <div class="text-content">
                <img src="signup-img/hot-white.png" class="img-fluid" alt="" />

                <p class="text-subtitle">
                  Our mission is to help businesses stay compliant with industry
                  regulations and ensure the safety of their customers.
                </p>
              </div>
              <div class="overlay-bg"></div>
            </div>
          </div>
          <!-- Right Section -->
          <div class="col-md-6 mt-3 mb-5">
            <div class="container">
              <div class="flexed f-col gap-3">
                <div class="logo-section">
                  <img
                    src="signup-img/hot.svg"
                    alt="Hot Icon"
                    class="logo show-on-mobile"
                  />
                  <div class="top-selection-banner">
                    <img
                      src="signup-img/company-icon.svg"
                      class="img-fluid"
                      alt=""
                    />
                    <div class="inner-info">
                      <span class="selection-type">Company</span>
                      <span class="selection-desc">New company signup</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 ml-2">
                    <span style="color: #4f4f4f; font-size: 13px">
                      <li style="margin-bottom: 8px">
                        <strong>Current Users:</strong> If you are a team member
                        of a company that uses our service, please
                        <a
                          :href="'mailto:' + infoEmail"
                          style="text-decoration: underline"
                          >click here</a
                        >
                        to email our Support team to be added to the system.
                      </li>
                      <li style="margin-bottom: 8px">
                        <strong>New User:</strong> If you are an individual not
                        currently working for a company we train, select
                        'Individual User.'
                      </li>
                      <li>
                        <strong>New Company:</strong> To sign up a company not
                        currently using our service, select 'Company.'
                      </li>
                    </span>
                  </div>
                </div>

                <validation-observer
                  v-slot="{ handleSubmit }"
                  ref="formValidator"
                >
                  <form
                    role="form"
                    @submit.prevent="handleSubmit(showCoursePage)"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <label class="form-control-label"
                          >First Name <span class="req"> *</span></label
                        >
                        <base-input
                          type="text"
                          name="First Name"
                          placeholder="First Name"
                          rules="required"
                          v-model="company.first_name"
                        ></base-input>
                      </div>
                      <div class="col-md-6">
                        <label class="form-control-label"
                          >Last Name <span class="req"> *</span></label
                        >
                        <base-input
                          type="text"
                          name="Last Name"
                          placeholder="Last Name"
                          rules="required"
                          v-model="company.last_name"
                        >
                        </base-input>
                      </div>
                      <div class="col-md-6">
                        <label class="form-control-label"
                          >Email Address <span class="req"> *</span></label
                        >
                        <base-input
                          type="email"
                          name="Email Address"
                          placeholder="Email Address"
                          rules="required"
                          v-model="company.email"
                        >
                        </base-input>
                      </div>
                      <div class="col-md-6">
                        <label class="form-control-label"
                          >Phone <span class="req"> *</span></label
                        >
                        <base-input
                          name="Phone Number"
                          placeholder="Phone"
                          rules="required"
                          v-model="company.telephone_no"
                          @input="acceptNumber"
                        >
                        </base-input>
                      </div>
                      <div class="col-md-6">
                        <label class="form-control-label"
                          >Company Name <span class="req"> *</span></label
                        >
                        <base-input
                          type="text"
                          name="Company name"
                          placeholder="Company Name"
                          rules="required"
                          v-model="company.name"
                        >
                        </base-input>
                      </div>
                      <div class="col-md-6">
                        <label class="form-control-label"
                          ># of Locations <span class="req"> *</span></label
                        >
                        <base-input
                          type="text"
                          name="Location"
                          rules="required"
                          v-model="company.no_of_locations"
                        >
                        </base-input>
                      </div>
                      <div class="col-md-6">
                        <label class="form-control-label"
                          >Estimated # of Users
                          <span class="req"> *</span></label
                        >
                        <base-input
                          type="text"
                          name="Users "
                          rules="required"
                          v-model="company.no_of_employees"
                        >
                        </base-input>
                      </div>
                      <div class="col-12 mt-2 mb-4">
                        <button
                          @click.prevent="showCoursePage()"
                          style="border-radius: 0"
                          class="btn-black"
                        >
                          Next
                        </button>
                      </div>
                      <br />
                      <div class="col-12 text-center mb-5">
                        <span style="color: #1e1e1e; font-size: 16px">
                          If you have any questions, please contact us at.
                        </span>
                        <br />
                        <span
                          style="
                            display: flex;
                            gap: 10px;
                            color: #1e1e1e;
                            font-size: 13px;
                            justify-content: center;
                            margin-top: 10px;
                          "
                        >
                          <a href="mailto:support@homeoftraining.com"
                            ><img
                              src="signup-img/email.svg"
                              class="img-fluid"
                              alt=""
                            />
                            support@homeoftraining.com</a
                          >
                          <a href="tel:954-676-7900"
                            ><img
                              src="signup-img/phone.svg"
                              class="img-fluid"
                              alt=""
                            />
                            954-676-7900</a
                          >
                        </span>
                      </div>
                    </div>
                  </form>
                </validation-observer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Portion 2  -->
    <transition name="fade">
      <div class="container-fluid" v-if="!showForm">
        <button
          class="btn px-5 py-2 sticky-submit-btn"
          @click.prevent="InputModelOpen()"
        >
          <span>Continue</span>
          <span class="mobile-total-price"
            >{{ formatPrice(total_cost)
            }}<span class="mobile-courses-label">{{
              `${selectedCourses.length} courses`
            }}</span></span
          >
        </button>
        <div class="row align-items-stretch">
          <div class="col-sm-12 col-lg-8 mt-3 pb-4">
            <!-- Back Button, User Icon, and Text -->
            <div class="row align-items-center mb-4">
              <div class="col-md-3 d-flex align-items-center">
                <img
                  src="signup-img/backbtn.svg"
                  class="img-fluid"
                  style="cursor: pointer"
                  alt="Back Button"
                  @click="showFormPage()"
                />
              </div>
              <!-- Empty Space -->
              <div class="col-md-9"></div>
            </div>

            <!-- Centered Text and Icon -->
            <div class="row">
              <div class="col-12 text-center">
                <img
                  src="signup-img/hot.svg"
                  alt="Hot Icon"
                  class="mb-2"
                  style="width: 150px"
                />
                <h1 style="color: #1e1e1e">Course Selection</h1>
                <p style="color: #4f4f4f; font-size: 13px; font-weight: 500">
                  Select folders or individual courses, or both. <br />Hover
                  over the top right of each folder to see what courses are
                  included. Once a folder is selected, add as many as many
                  courses as you’d like below.
                </p>
              </div>
            </div>

            <!-- Folders -->
            <div class="row mt-4">
              <div class="col-md-3 col-6">
                <div
                  class="card text-center p-2 mx-2"
                  style="width: 150px; height: 150px; cursor: pointer"
                  :class="openFolder1 ? 'active-fldr' : ''"
                  @click="selectFolder(1)"
                >
                  <div class="text-right">
                    <div class="info-container">
                      <img
                        :src="
                          openFolder1
                            ? 'signup-img/whitei.svg'
                            : 'signup-img/blacki.svg'
                        "
                        alt="Icon"
                      />
                      <div class="info-popup">Food and Alcohol Compliance</div>
                    </div>
                  </div>
                  <div class="card-body">
                    <img
                      src="signup-img/folders.svg"
                      class="img-fluid"
                      alt="Folder Icon"
                    />
                    <h2
                      :class="
                        openFolder1 ? 'active-fldr-text' : 'nrml-fldr-text'
                      "
                    >
                      Basic
                    </h2>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div
                  class="card text-center p-2 mx-2"
                  style="width: 150px; height: 150px; cursor: pointer"
                  :class="openFolder2 ? 'active-fldr' : ''"
                  @click="selectFolder(2)"
                >
                  <div class="text-right">
                    <div class="info-container">
                      <img
                        :src="
                          openFolder2
                            ? 'signup-img/whitei.svg'
                            : 'signup-img/blacki.svg'
                        "
                        alt="Icon"
                      />
                      <div class="info-popup">
                        Food, Alcohol, Human Trafficking & Sexual Harassment
                        Prevention, plus 5 additional courses
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <img
                      src="signup-img/folders.svg"
                      class="img-fluid"
                      alt="Folder Icon"
                    />
                    <h2
                      :class="
                        openFolder2 ? 'active-fldr-text' : 'nrml-fldr-text'
                      "
                    >
                      Pro
                    </h2>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div
                  class="card text-center p-2 mx-2"
                  style="width: 150px; height: 150px; cursor: pointer"
                  :class="openFolder3 ? 'active-fldr' : ''"
                  @click="selectFolder(3)"
                >
                  <div class="text-right">
                    <div class="info-container">
                      <img
                        :src="
                          openFolder3
                            ? 'signup-img/whitei.svg'
                            : 'signup-img/blacki.svg'
                        "
                        alt="Icon"
                      />
                      <div class="info-popup">
                        Food, Alcohol, Human Trafficking & Sexual Harassment
                        Prevention, plus 20 additional courses
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <img
                      src="signup-img/folders.svg"
                      class="img-fluid"
                      alt="Folder Icon"
                    />
                    <h2
                      :class="
                        openFolder3 ? 'active-fldr-text' : 'nrml-fldr-text'
                      "
                    >
                      Premium
                    </h2>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div
                  class="card text-center p-2 mx-2"
                  style="width: 150px; height: 150px; cursor: pointer"
                  :class="openFolder4 ? 'active-fldr' : ''"
                  @click="selectFolder(4)"
                >
                  <div class="text-right">
                    <div class="info-container">
                      <img
                        :src="
                          openFolder4
                            ? 'signup-img/whitei.svg'
                            : 'signup-img/blacki.svg'
                        "
                        alt="Icon"
                      />
                      <div class="info-popup">Create your own folder</div>
                    </div>
                  </div>
                  <div class="card-body">
                    <img
                      src="signup-img/folders.svg"
                      class="img-fluid"
                      alt="Folder Icon"
                    />
                    <h2
                      :class="
                        openFolder4 ? 'active-fldr-text' : 'nrml-fldr-text'
                      "
                    >
                      Custom
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <!-- pre selected  -->
            <div
              class="row"
              :class="openFolder1 || openFolder2 || openFolder3 ? 'mb-4' : ''"
            >
              <div class="col-md-6" v-if="preSelectedCourses.length > 0">
                <div class="pre-courses-box">
                  <h2 style="color: 11eE1E">Pre-Selected Courses</h2>
                  <ul class="course-list-ul">
                    <li
                      v-for="(course, index) in preSelectedCourses"
                      :key="course.id"
                      class="course-item"
                    >
                      <div class="course-checkbox">
                        <input
                          type="checkbox"
                          :id="'preselected-course' + index"
                          :checked="isCourseSelected(course)"
                          @change="toggleCourse(course)"
                        />
                        <label
                          class="course-label"
                          :for="'preselected-course' + index"
                        >
                          {{ course.course_name }}
                        </label>
                      </div>
                      <div
                        v-if="
                          specialCourses.some(
                            (special) => special.id === course.id
                          )
                        "
                        class="special-course-options"
                      >
                        <!-- <span
                      class="question-mark"
                      @click="showInput(course)"
                      >?</span> -->
                        <img
                          v-if="
                            selectedCourses.some(
                              (selected) => selected.id === course.id
                            )
                          "
                          src="signup-img/signup-group-icon.svg"
                          class="img-fluid"
                          width="30px"
                          @click="showInput(course)"
                          alt="User Icon"
                          style="cursor: pointer"
                        />
                      </div>
                    </li>
                  </ul>
                  <!-- Additional Courses List  -->

                  <span
                    style="
                      width: 100%;
                      border: 1px solid #8f8f8f;
                      background-color: #d0cfcf;
                      color: 8F8F8F;
                      padding: 5px 10px;
                    "
                    v-if="openFolder2 || openFolder3"
                  >
                    <!-- <div class="card" > -->
                    Please select {{ showFolderLimit }} additional courses below
                    <!-- </div> -->
                  </span>
                </div>
              </div>
            </div>

            <div class="row margin-0-mobile">
              <div class="col-sm-12 col-lg-6">
                <div class="courses-box">
                  <h2 style="color: 11eE1E">Compliance Courses</h2>
                  <ul class="course-list-ul">
                    <!-- Iterate through additional courses -->
                    <li
                      class="mb-2"
                      v-for="(course, index) in additionalCourses"
                      :key="course.id"
                      v-if="course.course_type == 1"
                    >
                      <div class="d-flex align-items-center">
                        <!-- Checkbox -->
                        <input
                          type="checkbox"
                          :id="'additional-course' + index"
                          :checked="isCourseSelected(course)"
                          :disabled="
                            isLimitReached() && !isCourseSelected(course)
                          "
                          @change="toggleCourse(course)"
                        />
                        <label
                          :for="'additional-course' + index"
                          class="mb-0 ml-3"
                        >
                          {{ course.course_name }}
                        </label>

                        <!-- Special course input (only visible for special courses) -->
                        <div
                          v-if="
                            specialCourses.some(
                              (special) => special.id === course.id
                            )
                          "
                          class="special-course-options ml-2"
                        >
                          <img
                            v-if="
                              selectedCourses.some(
                                (selected) => selected.id === course.id
                              )
                            "
                            src="signup-img/signup-group-icon.svg"
                            class="img-fluid"
                            width="20px"
                            @click="showInput(course)"
                            alt="User Icon"
                            style="cursor: pointer"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-12 col-lg-6 mobile-margin-top-10">
                <div class="courses-box">
                  <h2 style="color: 11eE1E">Additional Courses</h2>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Course Name"
                    v-model="searchAdditionalCourse"
                  />
                  <ul
                    class="course-list-ul"
                    v-if="filteredAdditionalCourses.length > 0"
                  >
                    <!-- Iterate through additional courses -->
                    <li
                      class="mb-2"
                      v-for="(course, index) in filteredAdditionalCourses"
                      :key="course.id"
                    >
                      <div class="d-flex align-items-center">
                        <!-- Checkbox -->
                        <input
                          type="checkbox"
                          :id="'additional-course' + index"
                          :checked="isCourseSelected(course)"
                          :disabled="
                            isLimitReached() && !isCourseSelected(course)
                          "
                          @change="toggleCourse(course)"
                        />
                        <label
                          :for="'additional-course' + index"
                          class="mb-0 ml-3"
                        >
                          {{ course.course_name }}
                        </label>

                        <!-- Special course input (only visible for special courses) -->
                        <div
                          v-if="
                            specialCourses.some(
                              (special) => special.id === course.id
                            )
                          "
                          class="special-course-options ml-2"
                        >
                          <!-- <span
                      class="question-mark"
                      v-show="!isEditing[course.id]"
                      @click="showInput(course)"
                      >?</span>

                      <input
                      v-if="isEditing[course.id]"
                      class="small-input"
                        label="Users:"
                        type="number"
                        name="# Users"
                        min="0"
                        style="width: 50px;"
                        v-model="users"
                      />
                      <button
                        v-if="isEditing[course.id]"
                        @click="updateUsers(course.id)"
                        class="transparent-btn"
                        title="Update Users"
                      >
                        ✔
                      </button> -->
                          <img
                            v-if="
                              selectedCourses.some(
                                (selected) => selected.id === course.id
                              )
                            "
                            src="signup-img/signup-group-icon.svg"
                            class="img-fluid"
                            width="20px"
                            @click="showInput(course)"
                            alt="User Icon"
                            style="cursor: pointer"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul class="course-list-ul" v-else>
                    <!-- Iterate through additional courses -->
                    <li
                      class="mb-2"
                      v-for="(course, index) in additionalCourses"
                      :key="course.id"
                      v-if="course.course_type === 0"
                    >
                      <div class="d-flex align-items-center">
                        <!-- Checkbox -->
                        <input
                          type="checkbox"
                          :id="'additional-course' + index"
                          :checked="isCourseSelected(course)"
                          :disabled="
                            isLimitReached() && !isCourseSelected(course)
                          "
                          @change="toggleCourse(course)"
                        />
                        <label
                          :for="'additional-course' + index"
                          class="mb-0 ml-3"
                        >
                          {{ course.course_name }}
                        </label>

                        <!-- Special course input (only visible for special courses) -->
                        <div
                          v-if="
                            specialCourses.some(
                              (special) => special.id === course.id
                            )
                          "
                          class="special-course-options ml-2"
                        >
                          <img
                            v-if="
                              selectedCourses.some(
                                (selected) => selected.id === course.id
                              )
                            "
                            src="signup-img/signup-group-icon.svg"
                            class="img-fluid"
                            width="20px"
                            @click="showInput(course)"
                            alt="User Icon"
                            style="cursor: pointer"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- Right side  -->
          <div
            class="col-sm-12 col-lg-4 height-cart"
            style="background-color: #fff3eb"
          >
            <img
              src="signup-img/fire-signup.svg"
              class="img-fluid"
              alt=""
              style="position: absolute; bottom: 0; right: 0"
            />
            <div class="row mt-5">
              <div class="col-12">
                <div class="card cart-box">
                  <div class="card-body">
                    <div class="row">
                      <div
                        class="col-12 d-flex justify-content-between align-items-between"
                      >
                        <span
                          class="mt-3 mb-3"
                          style="
                            color: #1e1e1e;
                            font-weight: 600;
                            font-size: 16px;
                          "
                        >
                          {{
                            `${selectedCourses.length} Courses Added to Cart`
                          }}
                        </span>
                      </div>
                    </div>
                    <div class="row" v-if="promoCodeApplied">
                      <div
                        class="col-12 d-flex justify-content-between align-items-between"
                      >
                        <span class="course-cost-label">
                          Original Cost Per Month:
                        </span>
                        <p class="course-cost-label">
                          {{ formatPrice(course_cost_monthly) }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col-12 d-flex justify-content-between align-items-between"
                      >
                        <span class="course-cost-label">
                          Total Cost Per Month
                          <span v-if="promoCodeApplied"
                            >(including discount)</span
                          >:
                        </span>
                        <p class="course-cost-label">
                          {{ formatPrice(total_cost) }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col-12 d-flex justify-content-between align-items-between"
                      >
                        <span class="course-cost-label">
                          No of Locations :
                        </span>
                        <p class="course-cost-label">
                          {{ company.no_of_locations }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col-12 d-flex justify-content-between align-items-between"
                      >
                        <span class="course-cost-label"> No of Users : </span>
                        <p class="course-cost-label">
                          {{ company.no_of_employees }}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <!-- Per year cost  -->
                    <div class="row">
                      <div
                        class="col-12 d-flex justify-content-between align-items-between"
                      >
                        <span class="course-cost-label">
                          Price if paid in full for the year
                          <span v-if="!specialCourseFlag">(5% Off)</span>:
                        </span>
                        <p class="course-cost-label">
                          {{ formatPrice(perYearCost) }}
                        </p>
                      </div>
                    </div>
                    <!-- Monthly Cost Per Location  -->
                    <div class="row">
                      <div
                        class="col-12 d-flex justify-content-between align-items-between"
                      >
                        <span class="course-cost-label">
                          Monthly Cost per location
                        </span>
                        <p class="course-cost-label">
                          {{ formatPrice(discountperlocation) }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col-12 d-flex justify-content-between align-items-between"
                      >
                        <span class="course-cost-label">
                          Monthly Cost per user:
                        </span>
                        <p class="course-cost-label">
                          {{ formatPrice(discountperemp) }}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div class="row" v-if="!promoCodeApplied">
                      <div class="col-md-12">
                        <div
                          style="
                            position: relative;
                            display: flex;
                            align-items: flex-start;
                          "
                        >
                          <base-input
                            placeholder="Gift Card or Promo Code"
                            name="Promotional Code"
                            v-model="promo_code"
                            style="position: relative; flex: 1"
                          ></base-input>
                          <button
                            class="discount-btn"
                            @click.prevent="applyPromoCode"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                      <!-- <div class="col-12 text-right">

                      </div> -->
                    </div>
                    <div class="row" v-else>
                      <div v-if="lead_id" class="mb-2 text-right">
                        <div class="col-12 text-right">
                          <button
                            size="sm"
                            class="btn"
                            style="
                              background-color: #e66412;
                              color: white;
                              border-radius: 5px !important;
                            "
                            type="danger"
                            @click.prevent="submitLead()"
                          >
                            Re Estimate
                          </button>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <span class="promocode-applied">
                          <span class="promocode-applied"
                            >Promotional Discount Applied ({{
                              discount_percentage
                            }}%)</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <div
                  class=""
                  style="background-color: transparent; min-height: 350px"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 my-3">
                        <span
                          class="my-3"
                          style="
                            color: #1e1e1e;
                            font-weight: 600;
                            font-size: 22px;
                          "
                        >
                          You May Also Like
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 mb-3">
                        <span style="color: #1e1e1e;font-weight: 600;font-size: 14px;">
                          <a href="mailto:sales@homeoftraining.com">API Connection (Click Here)</a>
                        </span>
                      </div>
                      <div
                        class="col-12 d-flex justify-content-between align-items-between"
                      >
                        <label
                          class="course-cost-label-you"
                          for="locations-addon"
                        >
                          Call Location ($4.00 Per Month, Per Location)
                          <div class="info-container">
                            <img src="signup-img/blacki.svg" alt="Icon" />
                            <div class="info-popup">
                              Quarterly service calls
                            </div>
                          </div>
                        </label>
                        <p class="course-cost-label-you">
                          <input
                            type="checkbox"
                            id="locations-addon"
                            v-model="isCallLocationChecked"
                            @change="updateTotalPrice('Call Location', 4.0)"
                          />
                        </p>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div
                        class="col-12 d-flex justify-content-between align-items-between"
                      >
                        <label class="course-cost-label-you" for="sms-addon">
                          SMS ($9.00 Per Month, Per 100 users)
                          <div class="info-container">
                            <img src="signup-img/blacki.svg" alt="Icon" />
                            <div class="info-popup">
                              Course & Compliance Notifications
                            </div>
                          </div>
                        </label>
                        <p class="course-cost-label-you">
                          <input
                            type="checkbox"
                            id="sms-addon"
                            v-model="isSMSChecked"
                            @change="updateTotalPrice('SMS', 9.0)"
                          />
                        </p>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div
                        class="col-12 d-flex justify-content-between align-items-between"
                      >
                        <label
                          class="course-cost-label-you"
                          for="onboarding-addon"
                        >
                          Onboarding ($3.00 Per Month, Per Location)
                          <div class="info-container">
                            <img src="signup-img/blacki.svg" alt="Icon" />
                            <div class="info-popup">Uploading team members</div>
                          </div>
                        </label>
                        <p class="course-cost-label-you">
                          <input
                            type="checkbox"
                            id="onboarding-addon"
                            v-model="isOnboardingChecked"
                            @change="updateTotalPrice('Onboarding', 3.0)"
                          />
                        </p>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div
                        class="col-12 d-flex justify-content-between align-items-between"
                      >
                        <label
                          class="course-cost-label-you"
                          for="surveys-addon"
                        >
                          Surveys ($5.00 Per Month, Per Location)
                          <div class="info-container">
                            <img src="signup-img/blacki.svg" alt="Icon" />
                            <div class="info-popup">
                              Gather anonymous feedback
                            </div>
                          </div>
                        </label>
                        <p class="course-cost-label-you">
                          <input
                            type="checkbox"
                            id="surveys-addon"
                            v-model="isSurveysChecked"
                            @change="updateTotalPrice('Surveys', 5.0)"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-5">
              <div class="col-12">
                <div
                  class="col-12 d-flex justify-content-center align-items-center"
                >
                  <button
                    class="btn px-5 py-2 hide-on-mobile"
                    style="
                      background-color: #1e1e1e;
                      color: white;
                      border-radius: 5px !important;
                    "
                    @click.prevent="InputModelOpen()"
                  >
                    Sign up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <NewSignupFooter />
    <modal :show.sync="showInputModal" class="user-modal">
      <h3 slot="header">Please Enter Your Company Informations</h3>
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <label class="form-control-label"
              >Address <span class="req"> *</span></label
            >
            <base-input
              type="text"
              name="Address"
              placeholder="Address"
              rules="required"
              v-model="company.address_1"
            >
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label"
              >City <span class="req"> *</span></label
            >
            <base-input
              type="text"
              name="City"
              placeholder="city"
              rules="required"
              v-model="company.city"
            >
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label"
              >State <span class="req"> *</span></label
            >
            <base-input
              type="text"
              name="State"
              placeholder="State"
              rules="required"
              v-model="company.state"
            >
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label"
              >Zip code <span class="req"> *</span></label
            >
            <base-input
              type="number"
              name="Zip code"
              placeholder="Zip"
              rules="required"
              v-model="company.zip"
            >
            </base-input>
          </div>
          <div class="col-md-6">
            <base-input
              type="text"
              label="Website"
              name="Website"
              placeholder="Website"
              v-model="company.website"
            >
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label">Company Logo</label>
            <form>
              <file-input v-on:change="onImageChange"></file-input>
            </form>
          </div>
          <div class="col-md-6">
            <label class="form-control-label">Company Type </label><br />
            <el-select
              class="mr-3"
              style="width: 100%"
              placeholder="Select Company Type"
              rules="required"
              v-model="company.company_type"
            >
              <el-option
                v-for="(option, index) in company_types"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="'company_type_' + index"
              >
              </el-option>
            </el-select>
          </div>

          <div class="col-md-6">
            <label class="form-control-label"
              >Password <span class="req"> *</span></label
            >
            <base-input
              :type="passwordFieldType"
              v-if="!(company_id !== '')"
              name="Password"
              placeholder="Password"
              rules="required"
              v-model="company.password"
            >
            </base-input>
          </div>

          <div class="col-md-12 mt-4 mb-4 text-end">
            <button
              @click.prevent="InputModelClose()"
              style="
                border-radius: 20px !important;
                color: white;
                background-color: black;
              "
              class="btn"
            >
              Back
            </button>
            <button
              @click.prevent="showAgreement()"
              style="
                border-radius: 20px !important;
                color: white;
                background-color: #e66412;
              "
              class="btn"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </modal>
    <modal :show.sync="agreementModal" class="user-modal">
      <h3 slot="header">Service Activation Agreement</h3>
      <form>
        <div class="agreement-content">
          <agreement type="company"></agreement>
        </div>
        <div class="text-center mt-2">
          <!-- <base-button type="success" @click.prevent="finalCreateAccount">
            I Agree
          </base-button> -->
          <base-button type="success" @click.prevent="paymenrModelOpen()">
            I Agree
          </base-button>
          <base-button type="danger" @click.prevent="cancelAgreement">
            Cancel
          </base-button>
        </div>
      </form>
    </modal>
    <modal :show.sync="paymentOptionModal">
      <h3 slot="header">Select Your Payment Option</h3>
      <div class="container">
        <div class="row">
          <div style="display: flex; flex-direction: column; gap: 10px">
            <div class="form-control">
              <div
                style="
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  font-size: 14px;
                "
              >
                <input
                  type="radio"
                  id="creditCard"
                  name="creditCard"
                  value="creditCard"
                  v-model="paymentType"
                />
                <label for="creditCard" style="margin: 0px">Credit Card</label>
              </div>
            </div>
            <div class="form-control">
              <div
                style="
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  font-size: 14px;
                "
              >
                <input
                  type="radio"
                  id="Ach"
                  name="Ach"
                  value="ach"
                  v-model="paymentType"
                />
                <label for="Ach" style="margin: 0px">ACH</label>
              </div>
            </div>
            <div class="form-control">
              <div
                style="
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  font-size: 14px;
                "
              >
                <input
                  type="radio"
                  id="invoice"
                  name="invoice"
                  value="invoice"
                  v-model="paymentType"
                />
                <label for="invoice" style="margin: 0px"
                  >Invoice (Additional 10%)</label
                >
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-4 mb-4 text-end">
            <button
              @click.prevent="showAgreement()"
              style="
                border-radius: 20px !important;
                color: white;
                background-color: black;
              "
              class="btn"
            >
              Back
            </button>
            <button
              @click.prevent="finalCreateAccount()"
              style="
                border-radius: 20px !important;
                color: white;
                background-color: #e66412;
              "
              class="btn"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </modal>
    <modal :show.sync="showPaymentOption">
      <div class="d-flex align-items-center justify-content-between">
        <h4
          slot="header"
          style="color: #444c57"
          class="title title-up text-center"
        >
          Payment
        </h4>
        <button
          type="button"
          @click.prevent="paymenrModelOpen()"
          class="btn"
          style="background-color: #e66412; color: white"
        >
          Cancel
        </button>
      </div>

      <credit-card
        v-if="showPaymentOption"
        type="company"
        :monthlyAmount="total_cost"
        :yearlyAmount="perYearCost"
        :specialCourseFlag="specialCourseFlag"
        :city="company.city"
        :state="company.state"
        :address="company.address_1"
        :zip="company.zip"
        :enablePaymentButton="enablePaymentButton"
        v-on:payClicked="payClicked"
      />
    </modal>
    <modal :show.sync="showusermodel">
      <h4
        slot="header"
        style="color: #444c57"
        class="title title-up text-center"
      >
        {{ specialCourseName }}
      </h4>

      <div class="col-md-8">
        <base-input
          label="How many users will be taking this course:"
          type="text"
          name="# Users"
          :key="specialCourseName"
          v-model="users"
        ></base-input>
      </div>
      <div class="col-md-6">
        <base-button
          size="md"
          class="custom-btn right"
          style="background-color: #e66412; color: white; border-color: #e66412"
          @click.prevent="updateUsers()"
          >Done</base-button
        >
      </div>
    </modal>
    <!-- ACH inputs  -->
    <modal :show.sync="achModal">
      <div class="d-flex align-items-center justify-content-between">
        <h4
          slot="header"
          style="color: #444c57"
          class="title title-up text-center"
        >
          Payment using ACH
        </h4>
        <button
          type="button"
          @click.prevent="paymenrModelOpen()"
          class="btn"
          style="background-color: #e66412; color: white"
        >
          Cancel
        </button>
      </div>
      <div class="container">
        <div class="row mb-3">
          <div class="col-6 col-md-4">
            <input
              type="radio"
              name="paymenttype"
              value="monthly"
              v-model="paymentObjects.paymentMethod"
            />
            Monthly ({{ formatPrice(total_cost) }})
          </div>
          <div class="col-6 col-md-4">
            <input
              type="radio"
              value="yearly"
              name="paymenttype"
              v-model="paymentObjects.paymentMethod"
            />
            Yearly ({{ formatPrice(perYearCost) }})
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="form-control-label"
              >Transit Number / ABA Routing Number
              <span class="req"> *</span></label
            >
            <base-input
              type="text"
              name="transit_number"
              placeholder="Transit Number / ABA Routing Number"
              rules="required"
              v-model="ACH.transit_number"
            >
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label"
              >Account Number <span class="req"> *</span></label
            >
            <base-input
              type="text"
              name="account_number"
              placeholder="Account Number"
              rules="required"
              v-model="ACH.account_number"
            >
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label"
              >Financial Institution <span class="req"> *</span></label
            >
            <base-input
              type="text"
              name="financial_instituation"
              placeholder="Financial Institution"
              rules="required"
              v-model="ACH.financial_instituation"
            >
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label"
              >Address <span class="req"> *</span></label
            >
            <base-input
              type="text"
              name="Address"
              placeholder="Address"
              rules="required"
              v-model="company.address_1"
            >
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label"
              >City <span class="req"> *</span></label
            >
            <base-input
              type="text"
              name="City"
              placeholder="city"
              rules="required"
              v-model="company.city"
            >
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label"
              >State <span class="req"> *</span></label
            >
            <base-input
              type="text"
              name="State"
              placeholder="State"
              rules="required"
              v-model="company.state"
            >
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label"
              >Zip code <span class="req"> *</span></label
            >
            <base-input
              type="number"
              name="Zip code"
              placeholder="Zip"
              rules="required"
              v-model="company.zip"
            >
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label"
              >Branch <span class="req"> *</span></label
            >
            <base-input
              type="text"
              name="branch"
              placeholder="Branch"
              rules="required"
              v-model="ACH.branch"
            >
            </base-input>
          </div>
          <base-button
            class="custom-btn"
            @click.prevent="ACHpayment()"
            block
            style="
              background-color: #e66412;
              color: white;
              border-color: #e66412;
            "
            >Create Account</base-button
          >
        </div>
      </div>
    </modal>
    <!-- Invoice inputs  -->
    <modal :show.sync="invoiceModal">
      <div class="d-flex align-items-center justify-content-between">
        <h4 slot="header" style="color: #444c57" class="title title-up">
          Payment using Invoice (Additional 10%)
        </h4>
        <button
          type="button"
          @click.prevent="paymenrModelOpen()"
          class="btn"
          style="background-color: #e66412; color: white"
        >
          Cancel
        </button>
      </div>
      <div class="container">
        <div class="row mb-2">
          <div class="col-5">
            <input
              type="radio"
              name="paymenttype"
              value="monthly"
              v-model="paymentObjects.paymentMethod"
            />
            Monthly ({{ formatPrice(adjustedMonthlyCost) }})
          </div>
          <div class="col-5">
            <input
              type="radio"
              value="yearly"
              name="paymenttype"
              v-model="paymentObjects.paymentMethod"
            />
            Yearly ({{ formatPrice(adjustedYearlyCost) }})
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label class="form-control-label"
              >Name of Recipient <span class="req"> *</span></label
            >
            <base-input
              type="text"
              name="invoice_name"
              placeholder="Invoice Name"
              rules="required"
              v-model="Invoice.invoice_name"
            >
            </base-input>
          </div>
          <div class="col-md-12">
            <label class="form-control-label"
              >2. Recipient email address ( email1@gmail.com, email2@gmail.com)
              <span class="req"> *</span></label
            >
            <base-input
              type="text"
              name="invoice_emails"
              placeholder="Invoice Emails"
              rules="required"
              v-model="Invoice.invoice_emails"
            >
            </base-input>
          </div>
          <div class="col-md-12">
            <label class="form-control-label"
              >Phone <span class="req"> *</span></label
            >
            <base-input
              name="Phone Number"
              placeholder="Phone"
              rules="required"
              v-model="company.telephone_no"
              @input="acceptNumber"
            >
            </base-input>
          </div>

          <base-button
            class="custom-btn"
            @click.prevent="Invoicepayment()"
            block
            style="
              background-color: #e66412;
              color: white;
              border-color: #e66412;
            "
            >Create Account</base-button
          >
          <!-- <div class="col-md-12 mt-4 mb-4 text-end">
                    <button @click.prevent="ACHpayment()" style="border-radius:20px !important;color:white;background-color: #E66412;" class="btn">Create Account</button>
                  </div> -->
        </div>
      </div>
    </modal>
    <modal :show.sync="paymentModalMessage">
      <h1
        class="text-capitalize text-center text-strong m-0 payment-modal-text text-danger font-weight-500"
      >
        Your payment is processing...
      </h1>
    </modal>
  </div>
</template>

<script>
import Vue from "vue";
import FileInput from "@/components/Inputs/FileInput";
import { Option, OptionGroup, Select, Table, TableColumn } from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import CreditCard from "@/views/Widgets/CreditCard";
import Agreement from "./Agreement.vue";
import { Dynamic } from "../../wl";
import VueGtag from "vue-gtag";
import NewSignupFooter from "../Components/NewSignupFooter.vue";

Vue.use(VueGtag, {
  config: { id: "AW-754017760" },
});
export default {
  name: "register",
  components: {
    Agreement,
    FileInput,
    CreditCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NewSignupFooter,
  },
  data() {
    return {
      showModalOfCourses: false,
      paymentObjects: {
        paymentMethod: "yearly",
        transaction_amount: "",
      },
      invoiceModal: false,
      achModal: false,
      ACH: {
        account_holder_name: "",
        transit_number: "",
        account_number: "",
        financial_instituation: "",
        branch: "",
      },
      Invoice: {
        invoice_name: "",
        invoice_emails: [],
      },
      specialCourseQueue: [], // Initialize an empty queue if not already defined
      paymentType: "creditCard",
      paymentOptionModal: false,
      creditCardOption: false,
      aCHOption: false,
      invoiceOption: false,
      searchAdditionalCourse: "",
      showInputModal: false,
      isCallLocationChecked: false, // To check if Call Location is selected
      isSMSChecked: false, // To check if SMS is selected
      isOnboardingChecked: false, // To check if Onboarding is selected
      isSurveysChecked: false, // To check if Surveys is selected
      isEditing: {},
      folder2stockLimit: 10,
      folder2stockLimitShow: 5,
      folder3stockLimit: 25,
      folder3stockLimitShow: 20,
      showFolderLimit: "",
      limitstart: 0,
      selectedFolderLimit: "",
      openFolder1: false,
      openFolder2: false,
      openFolder3: false,
      openFolder4: false,
      folder1Courses: [123, 122, 23],
      folder2Courses: [123, 122, 23, 103, 51], // Have upto 5 stock courses
      folder3Courses: [123, 122, 23, 103, 51], // Have upto 20 stock courses
      folder4Courses: [], // Unlimited courses ...
      preselectedCourses: [],
      additionalCourses: [],
      additionalCoursesCopy: [],
      defaultSelectedCourses: [],
      selectedCourses: [],
      showForm: true,
      loading: false,
      paymentModalMessage: false,
      users: null,
      courseSelectionFocused: false,
      focused: "blue-theme",
      formData: {
        company_name: "",
        first_name: "",
        last_name: "",
        company_location_num: "",
        company_employee_num: "",
        company_address_1: "",
        company_address_2: "",
        company_phone: "",
        company_email: "",
        company_zip: "",
        website: "",
        company_type: "",
        username: "",
        parent_id: "",
        image: "",
        company_city: "",
        company_state: "",
        company_password: "",
      },
      value_true: true,
      baseUrl: this.$baseUrl,
      complete: false,
      hot_user: "",
      hot_token: "",
      config: "",
      company_id: "",
      creatAccountClicked: false,
      company_types: [],
      parent_companies: [],
      payment_screen: false,
      image: "",
      sub_total: 0,
      special_courses: [],
      company: {
        first_name: "",
        last_name: "",
        company_type: "",
        parent_company: "",
        name: "",
        administrator: "",
        no_of_locations: null,
        no_of_employees: null,
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zip: "",
        logo: "",
        telephone_no: "",
        email: "",
        password: "",
      },

      search: "",
      estimate: false,
      showPass: false,
      contactUsModal: false,
      agreementModal: false,
      passwordFieldType: "password",
      courses: [],
      services: [],
      contact: {
        name: "",
        phone: "",
        email: "",
        message: "",
      },
      siteName: "",
      infoEmail: "",
      lead_id: "",
      checked_courses: [],
      companyEstimateDetailModel: false,
      showCompanyinformation: false,
      showPricePlan: false,
      total_cost: 0,
      total_discount: "",
      specialCourseFlag: "",
      special_courses_with_users: "",
      discountperlocation: 0,
      perYearCost: 0,
      discount_msg: "",
      employees_count: "",
      locations_count: "",
      discountperemp: 0,
      discount_per: "",
      specialCourses: [],
      course: "",
      role: "",
      showPaymentOption: false,
      spacialCourseFlag: [],
      selectedRows: [],
      selectedUsers: [],
      olde: "",
      showusermodel: false,
      specialCourseId: "",
      specialCourseName: "",
      old_length: "",
      specialCoursescopy: [],
      cardProcessing: false,
      promo_code: "",
      appliedText: "",
      showPromoCodeOption: false,
      promoCodeApplied: false,
      course_cost_monthly: "",
      yearlyAmount: "",
      discount_percentage: "",
      isContinueButtonDisabled: true,
      enablePaymentButton: false,
    };
  },
  mounted() {
    this.siteName = Dynamic.SITE_NAME;
    this.infoEmail = Dynamic.INFO_EMAIL;
    this.$gtag.event("Company signup", { method: "Google" });
    history.pushState(null, null, window.location.href);
    window.onpopstate = this.handleBackButton;
  },
  created() {
    localStorage.removeItem("fname");
    localStorage.removeItem("lname");
    localStorage.removeItem("email");
    localStorage.removeItem("courses");
    if (this.$route.query.role) {
      this.role = this.$route.query.role;
    }
    this.$http.post("company/company_dropdown_data", {}).then((resp) => {
      for (let type of resp.data.companytype) {
        let obj = {
          label: type.type,
          value: type.id,
        };
        this.company_types.push(obj);
      }
    });
    this.$http.get("user/discountRules").then((resp) => {
      for (let course of resp.data.courses) {
        let obj = {
          checked: false,
          id: course.id,
          course_name: course.name,
          course_type: course.course_type,
          cost: course.cost,
          discounted: course.is_discounted_course,
        };
        this.courses.push(obj);
      }

      var result1 = this.courses.filter((obj) => {
        return obj.discounted === 1;
      });

      this.course = this.courses;

      this.specialCourses = [];

      for (let course of resp.data.non_discounted_courses) {
        let obj = {
          checked: false,
          id: course.id,
          course_name: course.name,
          course_type: course.course_type,
          cost: course.cost,
          discounted: course.is_discounted_course,
          discounted_comment: course.discounted_course_comment,
          users: 0,
        };

        this.specialCourses.push(obj);
      }
      this.specialCoursescopy = JSON.parse(JSON.stringify(this.specialCourses));

      for (let service of resp.data.services) {
        let obj = {
          id: service.id,
          name: service.name,
          price: service.price,
          frequency: service.frequency,
        };
        this.services.push(obj);
      }

      this.selectedCourses = this.courses.filter((course) =>
        this.defaultSelectedCourses.includes(course.id)
      );
      this.preSelectedCourses = this.courses.filter((course) =>
        this.defaultSelectedCourses.includes(course.id)
      );
      this.additionalCourses = this.courses.filter(
        (course) => !this.defaultSelectedCourses.includes(course.id)
      );
      this.additionalCourses.sort((a, b) => {
        if (a.course_name.toLowerCase() < b.course_name.toLowerCase())
          return -1;
        if (a.course_name.toLowerCase() > b.course_name.toLowerCase()) return 1;
        return 0;
      });
      this.additionalCoursesCopy = this.additionalCourses.filter(
        (course) => course.course_type === 0
      );
    });
  },
  computed: {
    filteredAdditionalCourses() {
      // Dynamically filter based on search input
      return this.additionalCoursesCopy.filter((course) =>
        course.course_name
          .toLowerCase()
          .includes(this.searchAdditionalCourse.toLowerCase())
      );
    },
    // Add 10% surcharge for monthly cost if Invoice type is selected
    adjustedMonthlyCost() {
      return this.paymentType === "invoice"
        ? this.total_cost * 1.1 // Add 10% surcharge
        : this.total_cost; // Default price
    },
    // Add 10% surcharge for yearly cost if Invoice type is selected
    adjustedYearlyCost() {
      return this.paymentType === "invoice"
        ? this.perYearCost * 1.1 // Add 10% surcharge
        : this.perYearCost; // Default price
    },
  },
  methods: {
    Invoicepayment() {
      const requiredFields = {
        invoice_name: "Invoice Name",
        invoice_emails: "Invoice Emails",
      };
      for (const field in requiredFields) {
        if (!this.Invoice[field]) {
          Swal.fire({
            icon: "warning",
            title: "Missing Field",
            text: `Please fill in the ${requiredFields[field]}.`,
            confirmButtonText: "OK",
          });
          return; // Stop further execution if a field is empty
        }
      }
      this.paymentObjects.transaction_amount =
        this.paymentObjects.paymentMethod == "yearly"
          ? this.perYearCost
          : this.total_cost;
      this.formData.paymentMethod = this.paymentObjects.paymentMethod;
      this.formData.invoice = true;
      this.formData.invoice_name = this.Invoice.invoice_name;
      this.formData.invoice_emails = this.Invoice.invoice_emails;
      this.formData.transaction_amount =
        this.paymentObjects.transaction_amount * 1.1;

      this.createAccount(this.formData);
    },
    ACHpayment() {
      const requiredFields = {
        transit_number: "Transit Number",
        account_number: "Account Number",
        financial_instituation: "Financial Institution",
        branch: "Branch",
      };
      for (const field in requiredFields) {
        if (!this.ACH[field]) {
          Swal.fire({
            icon: "warning",
            title: "Missing Field",
            text: `Please fill in the ${requiredFields[field]}.`,
            confirmButtonText: "OK",
          });
          return; // Stop further execution if a field is empty
        }
      }

      this.paymentObjects.transaction_amount =
        this.paymentObjects.paymentMethod == "yearly"
          ? this.perYearCost
          : this.total_cost;

      this.formData.transaction_amount = this.paymentObjects.transaction_amount;
      this.formData.paymentMethod = this.paymentObjects.paymentMethod;
      this.formData.ach = true;
      this.formData.ACH = {
        account_holder_name: this.ACH.account_holder_name || "",
        transit_number: this.ACH.transit_number || "",
        account_number: this.ACH.account_number || "",
        financial_instituation: this.ACH.financial_instituation || "",
        branch: this.ACH.branch || "",
      };
      console.log(this.formData);
      this.createAccount(this.formData);

      // console.log(this.paymentObjects);
    },
    InputModelOpen() {
      if (this.selectedCourses.length == 0) {
        Swal.fire({
          icon: "warning",
          title: "Missing Field",
          text: `Please select atleast one course`,
          confirmButtonText: "OK",
        });
        return;
      }
      this.showInputModal = true;
    },
    InputModelClose() {
      this.showInputModal = false;
    },
    paymenrModelOpen() {
      this.showPaymentOption = false;
      this.paymentOptionModal = true;
      this.agreementModal = false;
      this.achModal = false;
      this.invoiceModal = false;
    },
    paymenrModelClose() {
      this.paymentOptionModal = false;
    },
    handleBackButton(event) {
      // Prevent the default back button behavior
      event.preventDefault();

      // Change the showForm state to show or hide the form
      this.showForm = true; // Toggle showForm state when back button is clicked

      // Optionally, you can display a confirmation dialog before changing the form state
      if (!this.showForm) {
        Swal.fire({
          icon: "info",
          title: "Are you sure?",
          text: "You are leaving this page. Do you want to go back?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            // If user confirms, allow normal back navigation
            history.back(); // Go back to the previous page
          } else {
            // Otherwise, we reset the state without navigating
            history.pushState(null, null, window.location.href);
          }
        });
      }
    },

    updateTotalPrice(item, price) {
      const itemsConfig = {
        "Call Location": {
          unitCount: this.company.no_of_locations,
          isChecked: this.isCallLocationChecked,
          discountVar: "discountperlocation",
        },
        Onboarding: {
          unitCount: this.company.no_of_locations,
          isChecked: this.isOnboardingChecked,
          discountVar: "discountperlocation",
        },
        Surveys: {
          unitCount: this.company.no_of_locations,
          isChecked: this.isSurveysChecked,
          discountVar: "discountperlocation",
        },
        SMS: {
          unitCount: this.company.no_of_employees,
          isChecked: this.isSMSChecked,
          discountVar: "discountperemp",
          perUnitDivisor: 100, // SMS pricing is per 100 users
        },
      };

      const config = itemsConfig[item];
      if (!config) return; // Exit if the item is not recognized

      // Determine the cost per unit
      let singleUnitCostPerMonth;
      let totalCostPerMonthPerEmp;
      if (config.perUnitDivisor) {
        // For SMS, divide the price by the specified divisor (e.g., per 100 users)
        singleUnitCostPerMonth = price / config.perUnitDivisor;

        totalCostPerMonthPerEmp = ((this.company.no_of_employees * this.company.no_of_locations) * (price / config.perUnitDivisor))
      } else {
        // For other items, use the price directly
        singleUnitCostPerMonth = price;

        totalCostPerMonthPerEmp = (price/this.company.no_of_employees)*this.company.no_of_locations;
      }

      // Calculate the total monthly cost
      const totalCostPerMonth = singleUnitCostPerMonth * config.unitCount;

      

      if (config.isChecked) {
        // Add costs if the item is checked
        this.total_cost += totalCostPerMonth;
        this.perYearCost += totalCostPerMonth * 12;
        this.discountperlocation += singleUnitCostPerMonth;
        this.discountperemp +=  totalCostPerMonthPerEmp;
      } else {
        // Subtract costs if the item is unchecked
        this.total_cost -= totalCostPerMonth;
        this.perYearCost -= totalCostPerMonth * 12;
        this.discountperlocation -= singleUnitCostPerMonth;
        this.discountperemp -=  totalCostPerMonthPerEmp;
      }
    },
    showInput(course) {
      this.showusermodel = true;
      this.specialCourseName = course.course_name;
      this.specialCourseId = course.id;
      // Show input and button when the question mark is clicked
      //  this.$set(this.isEditing, courseId, true);
    },
    async showCoursePage() {
      // Define all the fields explicitly for validation
      const requiredFields = {
        first_name: "First Name",
        last_name: "Last Name",
        name: "Company Name",
        no_of_locations: "Number of Locations",
        no_of_employees: "Number of Employees",
        //address_1: "Address Line 1",
        // city: "City",
        // state: "State",
        // zip: "Zip Code",
        // telephone_no: "Telephone Number",
        email: "Email",
        // password: "Password"
      };

      for (const field in requiredFields) {
        if (!this.company[field]) {
          Swal.fire({
            icon: "warning",
            title: "Missing Field",
            text: `Please fill in the ${requiredFields[field]}.`,
            confirmButtonText: "OK",
          });
          return; // Stop further execution if a field is empty
        }
      }
      // Convert the string inputs to numbers
      const no_of_locations = parseInt(this.company.no_of_locations, 10);
      const no_of_employees = parseInt(this.company.no_of_employees, 10);

      // Validate if 'no_of_locations' and 'no_of_employees' are valid integers
      if (!Number.isInteger(no_of_locations) || no_of_locations <= 0) {
        Swal.fire({
          icon: "warning",
          title: "Invalid Input",
          text: "Please enter a valid number for Locations (positive integer).",
          confirmButtonText: "OK",
        });
        return; // Stop further execution if the value is not a valid integer
      }

      if (!Number.isInteger(no_of_employees) || no_of_employees <= 0) {
        Swal.fire({
          icon: "warning",
          title: "Invalid Input",
          text: "Please enter a valid number for Employees (positive integer).",
          confirmButtonText: "OK",
        });
        return; // Stop further execution if the value is not a valid integer
      }
      await this.submitLead();

      // Add "You May Also Like" prices if checked
      // Uncheck all "You May Also Like" options if they are checked
      if (this.isCallLocationChecked) {
        this.isCallLocationChecked = false;
      }
      if (this.isSMSChecked) {
        this.isSMSChecked = false;
      }
      if (this.isOnboardingChecked) {
        this.isOnboardingChecked = false;
      }
      if (this.isSurveysChecked) {
        this.isSurveysChecked = false;
      }

      //   window.scrollTo({ top: 0, behavior: "smooth" });
      this.showForm = false;
      // Proceed to show the course page if all required fields are filled
    },
    showFormPage() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.showForm = true;
    },
    isLimitReached() {
      // Check if the selected folder has a limit and if the number of selected courses exceeds the limit
      if (this.openFolder2 || this.openFolder3) {
        return this.selectedCourses.length >= this.selectedFolderLimit;
      }
      return false; // No limit for folder 1 and folder 4
    },
    async selectFolder(id) {
      for (let i = 1; i <= 4; i++) {
        this[`openFolder${i}`] = i === id;
      }

      if (id === 1) {
        this.defaultSelectedCourses = this.folder1Courses;
      } else if (id === 2) {
        this.defaultSelectedCourses = this.folder2Courses;
        this.selectedFolderLimit = this.folder2stockLimit;
        this.showFolderLimit = this.folder2stockLimitShow;
      } else if (id === 3) {
        this.defaultSelectedCourses = this.folder3Courses;
        this.selectedFolderLimit = this.folder3stockLimit;
        this.showFolderLimit = this.folder3stockLimitShow;
      } else if (id === 4) {
        this.defaultSelectedCourses = this.folder4Courses;
      } else {
        this.defaultSelectedCourses = this.folder4Courses;
        this.selectedFolderLimit = "";
      }
      this.selectedCourses = this.courses.filter((course) =>
        this.defaultSelectedCourses.includes(course.id)
      );
      this.preSelectedCourses = this.courses.filter((course) =>
        this.defaultSelectedCourses.includes(course.id)
      );
      this.additionalCourses = this.courses.filter(
        (course) => !this.defaultSelectedCourses.includes(course.id)
      );

      // Check for special courses and apply logic
      // Check for special courses and apply logic
      this.specialCourseQueue = []; // Initialize an empty queue if not already defined
      this.defaultSelectedCourses.forEach((selectedId) => {
        const specialCourse = this.specialCourses.find(
          (course) => course.id === selectedId
        );

        if (specialCourse) {
          this.specialCourseQueue.push({
            course_name: specialCourse.course_name,
            id: specialCourse.id,
          });
        }
      });
      // Function to display the next modal in the queue
      const showNextModal = () => {
        if (this.specialCourseQueue.length > 0) {
          const nextCourse = this.specialCourseQueue.shift(); // Get the next special course
          this.specialCourseName = nextCourse.course_name;
          this.specialCourseId = nextCourse.id;
          this.showusermodel = true; // Show the modal
        }
      };

      // Show the first modal
      showNextModal();

      // Trigger the next modal after user interaction
      this.$watch("showusermodel", (newValue) => {
        if (!newValue) {
          // When the modal is closed, show the next modal if available
          showNextModal();
        }
      });

      // Uncheck and deduct prices for "You May Also Like" options
      if (this.isCallLocationChecked) {
        this.total_cost -= 35.0; // Deduct the price for Call Location
        this.perYearCost -= 35.0 * 12; // Deduct the price for Call Location
      }
      if (this.isSMSChecked) {
        this.total_cost -= 0.25 * this.company.no_of_employees; // Deduct the price for SMS per user
        this.perYearCost -= 0.25 * this.company.no_of_employees * 12; // Deduct the price for SMS per user
      }
      if (this.isOnboardingChecked) {
        this.total_cost -= 3.0; // Deduct the price for Onboarding
        this.perYearCost -= 3.0 * 12; // Deduct the price for Onboarding
      }
      if (this.isSurveysChecked) {
        this.total_cost -= 10.0; // Deduct the price for Surveys
        this.perYearCost -= 10.0 * 12; // Deduct the price for Surveys
      }

      // Uncheck all "You May Also Like" options
      this.isCallLocationChecked = false;
      this.isSMSChecked = false;
      this.isOnboardingChecked = false;
      this.isSurveysChecked = false;

      // Reset users for all special courses
      this.specialCourses.forEach((specialCourse) => {
        if (specialCourse.users !== 0) {
          specialCourse.users = 0; // Reset user count to zero for special courses
        }
      });

      await this.submitLead();
      // this.submitLead();
    },
    isCourseSelected(course) {
      return this.selectedCourses.some((selected) => selected.id === course.id);
    },
    async toggleCourse(course) {
      const courseIndex = this.selectedCourses.findIndex(
        (c) => c?.id === course?.id
      );

      // Check if the course is in special courses
      const specialCourse = this.specialCourses.find(
        (special) => special.id === course.id
      );
      if (specialCourse && specialCourse.users == 0) {
        this.specialCourseName = specialCourse.course_name;
        this.specialCourseId = specialCourse.id;
        this.showusermodel = true;
      }
      if (specialCourse && specialCourse.users !== 0) {
        specialCourse.users = 0; // Reset user count to zero
        // console.log(specialCourse);
      }
      if (courseIndex !== -1) {
        // Course is already selected, remove it from the array
        this.selectedCourses.splice(courseIndex, 1);
        // and if the user count is not zero

        // this.limitstart=this.selectedCourses.length;
        this.limitstart = this.selectedCourses.length;
      } else {
        if (this.openFolder2 || this.openFolder3) {
          // Get the selected folder's limit (folder 2 has limit 10, folder 3 has limit 25)
          const limit = this.selectedFolderLimit;

          // Only allow adding more courses if the current limit is not reached
          if (this.selectedCourses.length >= limit) {
            alert(`Limit of ${limit} courses reached for this folder`);
            return; // Prevent adding more courses if the limit is reached
          }
        }
        // Course is not selected, add it to the array
        this.selectedCourses = [
          ...this.selectedCourses.filter((c) => c.id !== course.id),
          course,
        ];
      }

      if (
        this.selectedCourses.length > 0 &&
        this.preSelectedCourses.length === 0
      ) {
        this.openFolder4 = true; // Enable folder 4
      } else if (this.selectedCourses.length === 0) {
        this.openFolder4 = false; // Disable folder 4 when no courses are selected
      }

      await this.submitLead();
    },
    applyPromoCode() {
      // this.loading = true;
      let data = {
        monthlyAmount: this.total_cost,
        yearlyAmount: this.perYearCost,
        locationAmount: this.discountperlocation,
        userAmount: this.discountperemp,
        promocode: this.promo_code,
      };
      this.$http
        .post("promocode/apply_promocode", data)
        .then((resp) => {
          this.total_cost = resp.data.final_amount_monthly;
          this.course_cost_monthly = resp.data.previous_amount_monthly;
          this.course_cost_yearly = resp.data.previous_amount_yearly;
          this.perYearCost = resp.data.final_amount_yearly;
          this.discount_percentage = resp.data.discount_percentage;
          this.discountperlocation = resp.data.final_location_amount;
          this.discountperemp = resp.data.final_user_amount;
          this.loading = false;
          this.submitLead();
        })
        .catch(function (error) {
          this.loading = false;
          if (error.response.status === 422) {
            return Swal.fire({
              title: "Error!",
              html: error.response.data.message,
              icon: "error",
            });
          }
        });
    },
    //  async updateUsers(courseId) {
    //     // Ensure `courseId` and `users` are valid
    //     if (!courseId || !this.users || isNaN(parseInt(this.users))) {
    //         console.warn("Invalid courseId or users value");
    //         return;
    //     }

    //     // Find and update the matching course
    //     const courseToUpdate = this.specialCourses.find(course => course.id === courseId);
    //     if (courseToUpdate) {
    //         courseToUpdate.users = parseInt(this.users); // Update users field
    //         console.log(`Updated users for course ID ${courseId}:`, courseToUpdate);
    //     } else {
    //         console.warn(`No special course found with ID ${courseId}`);
    //     }
    //     console.log(`Updated user count for course ${courseId}`);
    //     this.$set(this.isEditing, courseId, false);

    //     // Reset modal state and input
    //     this.showusermodel = false;
    //     this.users = 0;
    //     await this.submitLead();
    //   },
    async updateUsers() {
      this.specialCourses.filter((obj) => {
        if (obj.id == this.specialCourseId) {
          obj.users = parseInt(this.users);
        }
      });

      this.showusermodel = false;
      this.users = null;
      await this.submitLead();
    },
    dropdownselectionChange(e) {
      if (e.length < this.old_length) {
        const result1 = this.specialCourses.map((data) => {
          return data.id;
        });
        let diff10 = result1.filter((data1) => e.indexOf(data1) === -1);
        if (diff10 === result1 || e.length == 0 || e.indexOf(result1) != -1) {
          // this.specialCourses = this.specialCoursescopy;

          this.resetSpecialCourseUsers();
        } else if (diff10.length < result1.length) {
          //  this.specialCourseId = diff10;
          let diff11 = this.olde.filter((data1) => e.indexOf(data1) === -1);
          this.specialCourseId = parseInt(diff11.toString());
          this.users = 0;
          this.updateUsers();
        } else {
          this.users = 0;
          this.updateUsers();
        }
      }

      var existSpecialCourse = this.specialCourses.filter((obj) => {
        return obj.id == e[e.length - 1];
      });

      if (existSpecialCourse.length > 0) {
        this.$nextTick(() => {
          this.$refs.dropdown.visible = false;
        });

        this.specialCourseId = e[e.length - 1];

        var result2 = this.courses.filter((obj) => {
          return obj.id === this.specialCourseId;
        });

        this.specialCourseName = result2[0].course_name;

        if (e.length > this.old_length) {
          this.showusermodel = true;
        } else {
        }
      }
      this.olde = e;
      this.old_length = e.length;
    },
    specialCourseUsers() {
      this.spacialCourseFlag = this.specialCourses.map((item) => {
        if (item.users > 0) {
          return true;
        }
        return false;
      });
    },
    resetSpecialCourseUsers() {
      for (let i = 0; i < this.specialCourses.length; i++) {
        this.specialCourses[i].users = 0;
      }

      return this.specialCourses;
    },
    showDone() {
      this.courseSelectionFocused = true;
    },
    doneClicked(e) {
      this.courseSelectionFocused = false;
    },
    cancelAgreement() {
      this.agreementModal = false;
    },
    showContactUs() {
      this.contactUsModal = true;
    },
    finalCreateAccount() {
      //this.createAccount(this.formData);
      if (this.paymentType === "creditCard") {
        this.agreementModal = false;
        this.showPaymentOption = true;
        this.paymentOptionModal = false;
        this.achModal = false;
        this.invoiceModal = false;
      }
      if (this.paymentType === "ach") {
        this.achModal = true;
        this.agreementModal = false;
        this.showPaymentOption = false;
        this.paymentOptionModal = false;
        this.invoiceModal = false;
      }
      if (this.paymentType === "invoice") {
        this.achModal = false;
        this.invoiceModal = true;
        this.agreementModal = false;
        this.showPaymentOption = false;
        this.paymentOptionModal = false;
      }
    },
    payClicked(cardData) {
      this.loading = true;
      this.paymentModalMessage = true;
      let payment = {
        payment_type: "",
        cardholder_street_address:
          cardData.address + "," + cardData.city + "," + cardData.state,
        cardholder_zip: cardData.zip,
        transaction_amount: "",
        token: cardData.token,
      };
      if (this.specialCourseFlag) {
        payment.payment_type = "one-time";
      } else {
        payment.payment_type = cardData.paymentType;
      }
      if (cardData.paymentType == "monthly") {
        payment.transaction_amount = this.total_cost.toFixed(2);
      }
      if (cardData.paymentType == "yearly") {
        payment.transaction_amount = this.perYearCost.toFixed(2);
      }
      this.formData.payment = payment;
      this.formData.address_1 = cardData.address;
      this.formData.company_address_1 = cardData.address;
      this.formData.company_state = cardData.state;
      this.formData.company_city = cardData.city;
      this.formData.company_zip = cardData.zip;

      if (this.promoCodeApplied) {
        this.formData.promo_code = this.promo_code;
        this.formData.course_cost_monthly = this.course_cost_monthly;
      }
      this.loading = false;
      this.createAccount(this.formData);
    },

    showAgreement() {
      const requiredFields = {
        first_name: "First Name",
        last_name: "Last Name",
        name: "Company Name",
        no_of_locations: "Number of Locations",
        no_of_employees: "Number of Employees",
        address_1: "Address Line 1",
        city: "City",
        state: "State",
        zip: "Zip Code",
        telephone_no: "Telephone Number",
        email: "Email",
        password: "Password",
      };

      for (const field in requiredFields) {
        if (!this.company[field]) {
          Swal.fire({
            icon: "warning",
            title: "Missing Field",
            text: `Please fill in the ${requiredFields[field]}.`,
            confirmButtonText: "OK",
          });
          return; // Stop further execution if a field is empty
        }
      }
      this.showInputModal = false;
      this.paymentOptionModal = false;
      let selectedCourses = [...this.selectedCourses]; // Assuming checked_courses contains all selected courses
      let specialCourseIds = this.specialCourses.map((course) => course.id); // Get all special course IDs

      let specialCourses = selectedCourses.filter((course) =>
        specialCourseIds.includes(course.id)
      );
      selectedCourses = selectedCourses.filter(
        (course) => !specialCourseIds.includes(course.id)
      );

      let idsOfNomralCourses = selectedCourses.map((course) => course.id); // IDs of regular courses
      const result1 = this.specialCourses.map((data) => {
        return data.id;
      });
      const diff1 = this.checked_courses.filter(
        (data1) => result1.indexOf(data1) === -1
      );
      for (let special of this.specialCourses) {
        this.selectedRows.push(special.id);
        this.selectedUsers.push(special.users);
      }
      this.formData = {
        company_name: this.company.name,
        first_name: this.company.first_name,
        last_name: this.company.last_name,
        company_location_num: this.company.no_of_locations,
        company_employee_num: this.company.no_of_employees,
        company_address_1: this.company.address_1,
        company_address_2: this.company.address_2,
        company_phone: this.company.telephone_no,
        company_email: this.company.email,
        company_zip: this.company.zip,
        website: this.company.website,
        company_type: this.company.company_type,
        username: this.company.email,
        parent_id: this.company.parent_company,
        image: this.image,
        company_city: this.company.city,
        company_state: this.company.state,
        company_password: this.company.password,
        course_ids: idsOfNomralCourses,
        // special_courses: this.selectedRows,
        // selected_users: this.selectedUsers,
        special_courses: this.specialCourses,
        status: 1,
        payment: [],
        card_info: [],
        i_agree: true,
        call_location: this.isCallLocationChecked,
        sms: this.isSMSChecked,
        onboarding: this.isOnboardingChecked,
        survey: this.isSurveysChecked,
      };
      this.agreementModal = true;
    },

    formatPrice(value) {
      return (
        "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
      );
    },
    submitLead() {
      let selectedCourses = [...this.selectedCourses]; // Assuming checked_courses contains all selected courses
      let specialCourseIds = this.specialCourses.map((course) => course.id); // Get all special course IDs

      let specialCourses = selectedCourses.filter((course) =>
        specialCourseIds.includes(course.id)
      );
      selectedCourses = selectedCourses.filter(
        (course) => !specialCourseIds.includes(course.id)
      );

      let idsOfNomralCourses = selectedCourses.map((course) => course.id); // IDs of regular courses

      //  console.log(idsOfNomralCourses);
      //  return;

      const result = this.specialCourses.map((data) => {
        return data.id;
      });
      const diff = this.checked_courses.filter(
        (data1) => result.indexOf(data1) === -1
      );
      let selectedSpecialCourses = [];

      var result4 = this.specialCourses.filter((obj) => {
        return obj.users != 0;
      });

      selectedSpecialCourses = result4;

      var resulttest = selectedSpecialCourses.map(function (a) {
        return a.id;
      });
      const finalids = diff.concat(resulttest);
      if (
        this.company.no_of_locations !== "" &&
        this.company.no_of_employees !== "" &&
        this.company.no_of_locations < 2 &&
        this.company.no_of_employees < 2
      ) {
        if (
          this.company.name !== "" &&
          this.company.first_name !== "" &&
          this.company.last_name !== "" &&
          this.company.email !== ""
        ) {
          return Swal.fire({
            title: "Warning!",
            html: "Based on the details provided, it appears you requesting a quote for an individual user.  Please click OK to proceed.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            confirmButtonText: "Ok",
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.value) {
              localStorage.setItem("fname", this.company.first_name);
              localStorage.setItem("lname", this.company.last_name);
              localStorage.setItem("email", this.company.email);
              localStorage.setItem("courses", JSON.stringify(finalids));
              window.location.href = "#/user_register?redirection=yes";
            } else {
              this.company.no_of_locations = "";
              this.company.no_of_employees = "";
            }
          });
        } else {
          return Swal.fire({
            title: "Error",
            html: "Please fill all required feilds.",
            icon: "error",
          });
        }
      }
      // this.loading = true;
      let data = {
        company_name: this.company.name,
        first_name: this.company.first_name,
        last_name: this.company.last_name,
        number_of_locations: this.company.no_of_locations,
        number_of_employees: this.company.no_of_employees,
        phone_num: this.company.telephone_no,
        email: this.company.email,
        user_type: "corporate",
        // course_ids: diff,
        course_ids: idsOfNomralCourses,
        special_courses: selectedSpecialCourses,
        promo_code: this.promo_code,
        course_cost: this.course_cost_monthly,
        discounted_cost: this.total_cost,
        total_cost_per_year: this.perYearCost,
        per_location: this.discountperlocation,
        per_user: this.discountperemp,
      };
      this.$http
        .post("user/lead", data)
        .then((resp) => {
          if (!this.promo_code) {
            this.lead_id = resp.data.user_id;
            this.companyEstimateDetailModel = false;
            this.total_cost = resp.data.total;
            this.sub_total = resp.data.sub_total;
            this.total_discount = resp.data.discount_value;
            if (resp.data.discount != null) {
              this.discount_msg = resp.data.discount.title;
            }

            this.employees_count = resp.data.number_of_employees;
            this.locations_count = resp.data.number_of_locations;
            this.discountperemp =
              resp.data.total / resp.data.number_of_employees;
            this.discountperlocation = resp.data.per_location_cost;
            this.showPricePlan = true;
            this.specialCourseFlag = resp.data.onlySpecialCourse;
            this.special_courses_with_users = resp.data.special_courses_users;
            if (this.special_courses_with_users) {
              var result1 = this.special_courses_with_users.filter((obj) => {
                return obj.users != 0;
              });
              this.special_courses_with_users = result1;
            }
            this.promo_code = "";
            this.showPromoCodeOption = true;
            this.appliedText = "";
            this.promoCodeApplied = false;

            this.perYearCost = resp.data.perYearCost;
          } else {
            this.promoCodeApplied = true;
            this.showPromoCodeOption = false;
            this.promo_code = "";
          }
          this.loading = false;
          this.isContinueButtonDisabled = false;
        })
        .catch(function (error) {
          this.loading = false;
          if (error.response.status === 422) {
            return Swal.fire({
              title: "Error!",
              html: error.response.data.message,
              icon: "error",
            });
          }
        });
    },
    companyDetails() {
      this.showCompanyinformation = true;
    },
    companyEstimateDetail() {
      this.companyEstimateDetailModel = true;
    },
    hideModel() {
      this.companyEstimateDetailModel = false;
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    cancelContact() {
      this.contactUsModal = false;
    },
    saveContact() {
      this.loading = true;
      let data = {
        name: this.contact.name,
        email: this.contact.email,
        phone: this.contact.phone,
        message: this.contact.message,
      };
      this.$http
        .post("user/contact", data)
        .then((resp) => {
          this.contactUsModal = false;
          this.loading = false;
          Swal.fire({
            title: "Success!",
            text: resp.data.message,
            icon: "success",
          });
        })
        .catch(function (error) {
          this.loading = false;
          if (error.response.status === 422) {
            let respmessage = error.response.data.message.replace(/,/g, "\n");
            Swal.fire({
              title: "Error!",
              text: respmessage,
              icon: "error",
            });
          }
        });
    },

    acceptNumber() {
      var x = this.company.telephone_no
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.company.telephone_no = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

      var y = this.contact.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.contact.phone = !y[2]
        ? y[1]
        : "(" + y[1] + ") " + y[2] + (y[3] ? "-" + y[3] : "");
    },
    onImageChange(e) {
      let files = e;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    createAccount(formDataSubmitted) {
      this.loading = true;
      delete this.$http.defaults.headers["authorization"];
      this.creatAccountClicked = true;
      this.$http
        .post("company/register", formDataSubmitted)
        .then((resp) => {
          let ids = [];
          let obj = {
            id: resp.data.id,
          };
          ids.push(obj);
          this.agreementModal =
            this.showPaymentOption =
            this.showusermodel =
            this.loading =
            this.enablePaymentButton =
              false;
          this.welcomeEmail(formDataSubmitted, ids);
        })
        .catch((error) => {
          this.loading = false;
          this.agreementModal =
            this.showPaymentOption =
            this.showusermodel =
            this.loading =
            this.enablePaymentButton =
              false;
          if (error.response.status === 422) {
            Swal.fire({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false;
          }
        })
        .finally(() => {
          this.loading = false;
          this.paymentModalMessage = false;
        });
    },

    welcomeEmail(formDataSubmitted, ids) {
      this.loading = true;
      this.$http
        .post("company/welcome_email", {
          form_data: formDataSubmitted,
          password: this.company.password,
          ids: ids,
        })
        .then((resp) => {
          this.agreementModal =
            this.showPaymentOption =
            this.showusermodel =
            this.loading =
            this.enablePaymentButton =
              false;
          this.$router.push("/login");
          Swal.fire({
            title: "Success!",
            text: `Account created successfully.`,
            icon: "success",
          });
        })
        .catch(function (error) {
          this.agreementModal =
            this.showPaymentOption =
            this.showusermodel =
            this.loading =
            this.enablePaymentButton =
              false;
          this.$router.push("/login");
          Swal.fire({
            title: "Success!",
            text: "Account created successfully.",
            icon: "success",
          });
        });
    },
  },
  watch: {
    lead_id: function () {
      if (this.lead_id == "") {
        this.isContinueButtonDisabled = true;
      }
    },
    checked_courses: function () {
      this.isContinueButtonDisabled = true;
    },
  },
};
</script>
<style scoped>
.line-break {
  display: inline;
}

/* On larger screens (e.g., desktops) */
@media (min-width: 992px) {
  .line-break {
    display: block; /* Forces line break */
  }
}
.footer {
  background-color: black;
  color: white;
  font-size: 14px;
  padding: 10px 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1000;
  left: 0%;
}

.footer-text {
  color: rgba(255, 255, 255, 0.8);
}

.footer-links {
  display: flex;
  gap: 15px;
}

.footer-link {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
  color: white;
}

.question-mark {
  color: red;
  font-weight: bold;
  cursor: pointer;
  margin-left: 1rem;
}
/* Extend existing styles */
.course-list-ul {
  max-height: 400px; /* Retained existing max-height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 0;
  margin: 0;
  list-style-type: none;
  color: #787676;
}

.course-list-ul li {
  line-height: 10px; /* Retained line spacing */

  display: flex;
  align-items: center;
  justify-content: space-between; /* Add spacing between checkbox and special-course-options */
}

.course-checkbox {
  display: flex;
  align-items: center;
}

.course-label {
  margin-top: 12px; /* Add space between checkbox and label */
  margin-left: 10px; /* Add space between checkbox and label */
  font-size: 14px;
  color: inherit; /* Ensure it uses the parent color (#787676) */
}

.special-course-options {
  display: flex;
  align-items: center;
  gap: 8px; /* Add spacing between the input and button */
}

.small-input input {
  width: 50px; /* Adjust width to make it smaller */
  padding: 2px; /* Reduce padding */
  font-size: 14px; /* Adjust font size */
  text-align: center; /* Center align the text */
  border: 1px solid #ccc; /* Optional: Add border for clarity */
  border-radius: 4px; /* Optional: Slight border rounding */
}

.small-input input::-webkit-inner-spin-button,
.small-input input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Remove default number input arrows */
}

.transparent-btn {
  background: none;
  border: none;
  color: green;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  padding: 0;
}

.transparent-btn:hover {
  color: darkgreen; /* Slight hover effect */
}

/*
Text on Image
*/

.text-title {
  font-size: 32px;
  font-weight: bolder;
  line-height: 1.2;
  margin-bottom: 10px;
  color: white;
}
.text-subtitle {
  font-size: 13px;
  line-height: 1.5;
  max-width: 80%;
  color: white;
}
/* Checkbox */
input[type="checkbox"] {
  accent-color: #e66412; /* Change this to your desired color */
}
/* NEXT BTN */
.btn-black {
  width: 100%;
  background-color: black;
  color: white;
  border: none;
  border-radius: 0%;
  padding: 10px; /* Adjust padding for desired height */
  font-size: 16px; /* Adjust font size */
  text-align: center;
  cursor: pointer;
  border-radius: 5px !important;
}
.btn-black:hover {
  background-color: #333; /* Slightly lighter black for hover effect */
}
/*Transitions Buttons*/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/* Folder Styling */
.active-fldr {
  background-color: #e66412;
}
.nrml-fldr-text {
  color: #767676;
  font-size: 16px;
}
.active-fldr-text {
  color: #ffffff;
  font-size: 16px;
}

.course-list-ul {
  max-height: 400px; /* Adjust based on your needs */
  /* over-flow-y:auto; */
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
  color: #787676;
}
.mgbtm {
  margin-bottom: 100px;
}
.course-list-ul li {
  line-height: 16px; /* Line spacing */
}
.payment-modal-text {
  animation: textBlink 2s linear infinite;
}
@keyframes textBlink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.form-section {
  background-color: transparent;
  padding: 40px;
  border-right: 1px solid #999999;
}
.promocode-applied {
  color: #00ccff;
  font-weight: bold;
  font-size: 14px;
}
.course-section {
  padding: 40px;
  background-color: #ffffff !important;
}

.py-5 {
  padding-bottom: 0px !important;
}
.mt--6 {
  margin-top: -6rem !important;
}
.mt--12 {
  margin-top: -12rem !important;
}
.search-wrapper {
  position: relative;
}
.courseList {
  max-height: 250px !important;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}
hr {
  margin-top: 2px !important;
  margin-bottom: 20px !important;
}
.basebutton.disabled:hover {
  cursor: not-allowed;
}
@media (min-width: 992px) {
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 3rem !important;
  }
  .password-eye span {
    border: 1px solid #808080b3;
    padding: 8px;
    border-radius: 5px;
    background: #80808029;
  }
}
@media (min-width: 768px) {
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 25.33333%;
  }
}

/* ============17/11/2020============ */

#selected_course li {
  font-size: 0.89em;
}
#serviceAgreement {
  float: left;
  height: 300px;
  overflow: auto;
}
#serviceAgreement p {
  font-size: 0.81rem;
  text-align: justify;
}

.el-select-group__title {
  text-decoration: underline !important;
  font-weight: bolder !important;
}

.el-select-dropdown__item {
  font-size: 13px !important;
}
.reduceFont {
  font-weight: 400 !important;
}
.price-area .row {
  margin-top: 5px;
}
.bg-gradient-primary {
  background: linear-gradient(87deg, #07c9fb 0, #ffffff 100%) !important;
}
.req {
  color: red;
}

.flexed {
  display: flex;
}

.f-col {
  flex-direction: column;
}

.gap-2 {
  gap: 20px;
}

.gap-3 {
  gap: 20px;
}

.top-selection-banner {
  display: flex;
  gap: 20px;
}

.top-selection-banner .inner-info {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.text-overlay {
  position: absolute;
  bottom: 0 !important;
  left: 0 !important;
  width: 100%;
  /* -webkit-transform: translateY(-50%);
    transform: translateY(-50%); */
  color: white;
  z-index: 100;
  text-align: left;
}

.text-content {
  z-index: 100 !important;
  position: relative;
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.text-content img {
  width: 120px;
  height: auto;
}

.overlay-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent 0%, black 100%);
}

.modal-dialog {
  justify-content: center !important;
}

.modal-dialog .modal-content {
  width: fit-content;
}

.text-title {
  font-size: 32px;
  font-weight: bolder;
  line-height: 1.2;
  margin-bottom: 10px;
  color: white;
}

.text-subtitle {
  font-size: 13px;
  line-height: 1.5;
  max-width: 80%;
  color: white;
}

.show-on-mobile {
  display: none;
}
@media (min-width: 992px) {
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 3rem !important;
  }
}

.pre-courses-box {
  background: white;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.courses-box {
  background: white;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 475px;
}

.courses-box h2 {
  margin: 0;
  padding: 0;
}

.checkbox-flex {
  display: flex;
  gap: 10px;
  margin: 10px 0px;
}

.height-cart {
  padding: 20px 20px;
}
.sticky-submit-btn {
  display: none;
}

.inner-info .selection-type {
  color: black;
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
}

.inner-info .selection-desc {
  color: #7a7a7a;
  font-size: 13px;
  line-height: normal;
}

@media (max-width: 990px) {
  .show-on-mobile {
    display: block;
  }
  .mobile-margin-top-10 {
    margin-top: 10px;
  }
  .height-cart {
    height: fit-content;
    padding: 20px 20px;
  }

  .hide-on-mobile {
    display: none;
  }

  .sticky-submit-btn {
    display: flex;
    justify-content: space-between;
    background-color: rgb(30, 30, 30);
    color: white;
    border-radius: 0px !important;
    position: fixed;
    width: 95%;
    margin: 0px 0px 10px -3px !important;
    padding: 8px 20px !important;
    border-radius: 100px !important;
    bottom: 0;
    z-index: 100;
    align-items: center;
  }

  .margin-0-mobile {
    margin-top: 0px;
  }

  .top-selection-banner {
    gap: 8px;
  }
  .top-selection-banner img {
    width: 32px;
    margin-top: 5px;
  }

  .inner-info .selection-type {
    color: black;
    font-size: 15px;
    line-height: normal;
    font-weight: 600;
  }

  .inner-info .selection-desc {
    color: #7a7a7a;
    font-size: 12px;
    line-height: normal;
  }

  .inner-info {
    display: none !important;
  }
}

.discount-btn {
  border-radius: 5px !important;
  background-color: rgb(230, 100, 18);
  color: white;
  margin-left: 10px;
  position: absolute;
  right: 0px;
  height: 43px;
  border: 0;
  padding: 0px 20px;
}

.mobile-cart-total {
  display: flex;
  flex-direction: column;
}

.mobile-total-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mobile-courses-label {
  font-weight: 400;
  font-size: 12px;
}

.course-cost-label {
  color: #1e1e1e;
  font-weight: 400;
  font-size: 11px;
}
.course-cost-label-you {
  color: #1e1e1e;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  gap: 10px;
}

.logo-section {
  display: flex;
  justify-content: space-between;
}

.logo-section img.logo {
  width: 100px !important;
}

.cart-box {
  background-color: #ffe9da;
  padding: 10px;
  border: 1px solid #ffdcc6;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.info-container {
  position: relative;
  justify-self: right;
}

.info-container:hover .info-popup {
  display: block;
}

.info-popup {
  display: none; /* Initially hidden */
  position: absolute;
  top: 28px; /* Below the image */
  z-index: 1;
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
