<template>
  <div v-loading.fullscreen.lock="loading">
    <div class="generic-layout">
      <Header />

      <section class="py-4 py-md-5">
        <div class="container">
          <div class="steps-wrap position-relative">
            <div class="steps-tab-wrap-outer steps-wrap-width">
              <router-link v-if="active == 1" to="/new_registration">
                <span :disabled="active === 1" class="back-btn">
                  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM20 7L1 7V9L20 9V7Z"
                      fill="black" />
                  </svg>
                  <span class="ps-2 fw-bold text-dark">Back</span>
                </span>
              </router-link>

              <span :disabled="active === 1" v-else @click="prevStep" class="back-btn">
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM20 7L1 7V9L20 9V7Z"
                    fill="black" />
                </svg>
                <span class="ps-2 fw-bold">Back</span>
              </span>
              <el-steps class="steps-tab-wrap pb-4 pb-md-5" :active="active" finish-status="success">
                <el-step></el-step>
                <el-step></el-step>
                <el-step></el-step>
                <el-step></el-step>
              </el-steps>
            </div>
          </div>
          <div class="steps pt-4">
            <div class="steps-wrap-width" v-if="active === 0">
              <div class="ac-list-block border p-4 rounded-4 mb-4 mb-md-5">
                <ul class="list-unstyled">
                  <li class="d-flex mb-3">
                    <span><img src="img/price/orange-tick.svg" alt="" /></span>
                    <div class="ac-list-content ps-3">
                      <h6 class="fw-bold mb-1">Current users</h6>
                      <p class="lh-sm">If you are a team member of a company that uses our service, please <a href="mailto:support@homeoftraining.com"
                          class="fw-bold">click here</a> to email our Support team, and they will add you to the system.
                      </p>
                    </div>
                  </li>
                  <li class="d-flex mb-3">
                    <span><img src="img/price/orange-tick.svg" alt="" /></span>
                    <div class="ac-list-content ps-3">
                      <h6 class="fw-bold mb-1">New user</h6>
                      <p class="lh-sm">For an individual currently not working for a company we provide training to,
                        click Individual User.</p>
                    </div>
                  </li>
                  <li class="d-flex mb-4">
                    <span><img src="img/price/orange-tick.svg" alt="" /></span>
                    <div class="ac-list-content ps-3">
                      <h6 class="fw-bold mb-1">New Company</h6>
                      <p class="lh-sm"> To sign up a company currently not using our service, select Company.</p>
                    </div>
                  </li>
                </ul>
                <h6 class="fw-bold">Thank you for your business! 🎉</h6>
              </div>
              <div class="ac-selection-wrap mb-4">
                <h2 class="fw-bold text-center mb-3 mb-md-5">Choose Your Account Type</h2>
                <div class="acc-type-wrap row">
                  <div class="col-md-6 mb-3 mb-md-0">
                    <div :disabled="active === 4" @click="nextStep"
                      class="ac-type-block border rounded-4 p-5 text-center position-relative" style="cursor: pointer;">
                      <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                      <img src="img/price/individual.svg" alt="" />

                      <h4 class="fw-bold">Individual</h4>
                      <span>New single user</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div :disabled="active === 4" @click="nextStep"
                      class="ac-type-block border rounded-4 p-5 text-center position-relative" style="cursor: pointer;">
                      <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                      <img src="img/price/company.svg" alt="" />
                      <h4 class="fw-bold">Company</h4>
                      <span>New Company signup</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="links-area text-center">
                <p class="mb-0">Already have an account? <a href="#" class="fw-bold">Login</a></p>
                <p>Already work for a location and need to be added to the system? <a href="#" class="fw-bold">Click
                    here</a></p>
              </div>
            </div>
            <div class="steps-wrap-width" v-if="active === 1">
              <div class="selected_user d-flex align-items-center mb-4">
                <span><img src="img/price/company.svg" alt="" /></span>
                <div class="select-user-con pl-3">
                  <h5 class="fw-bold mb-0">Individual</h5>
                  <span>New single user</span>
                </div>
              </div>
              <div class="form-list-block border p-4 rounded-4 mb-4">
                <form class="general-form">
                  <div class="row">
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">First Name<span class="requireField"> *</span></label>
                      <base-input type="text" name="First Name" placeholder="Enter your first name" rules="required"
                        v-model="employee.first_name">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Last Name<span class="requireField"> *</span></label>
                      <base-input type="text" name="Last Name" placeholder="Enter your Last Name" rules="required"
                        v-model="employee.last_name">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Email Address<span class="requireField">
                          *</span></label>
                      <base-input type="text" name="Email Address" placeholder="Enter your email address"
                        rules="required" v-model="employee.email">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Phone Number<span class="requireField"> *</span></label>
                      <base-input type="text" name="Phone Number" placeholder="Enter your phone number" rules="required"
                        v-model="employee.phone" @input="acceptNumber">
                      </base-input>
                    </div>


                  </div>
                  <!-- <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">First Name <span class="requireField">*</span></label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
            </div>
            <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">Password</label>
            <input type="password" class="form-control" id="exampleInputPassword1">
            </div>
            <div class="mb-3 form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1">
            <label class="form-check-label" for="exampleCheck1">Check me out</label>
            </div> -->
                  <base-button type="primary" :disabled="active === 4" @click="nextStep"
                    class="primary-orange border-0 w-100 py-2 rounded-3">Next</base-button>
                </form>
              </div>
              <div class="ac-list-block border p-4 rounded-4 mb-3 mb-md-5">
                <ul class="list-unstyled">
                  <li class="d-flex mb-3">
                    <span><img src="img/price/orange-tick.svg" alt="" /></span>
                    <div class="ac-list-content ps-3">
                      <h6 class="fw-bold mb-1">Current users</h6>
                      <p class="lh-sm">If you are a team member of a company that uses our service, please <a
                          :href="'mailto:' + infoEmail" class="fw-bold">click here</a> to email our Support team, and
                        they will add you to the system.</p>
                    </div>
                  </li>
                  <li class="d-flex mb-3">
                    <span><img src="img/price/orange-tick.svg" alt="" /></span>
                    <div class="ac-list-content ps-3">
                      <h6 class="fw-bold mb-1">New user</h6>
                      <p class="lh-sm">For an individual currently not working for a company we provide training to,
                        click Individual User.</p>
                    </div>
                  </li>
                  <li class="d-flex mb-4">
                    <span><img src="img/price/orange-tick.svg" alt="" /></span>
                    <div class="ac-list-content ps-3">
                      <h6 class="fw-bold mb-1">New Company</h6>
                      <p class="lh-sm"> To sign up a company currently not using our service, select Company.</p>
                    </div>
                  </li>
                </ul>
                <h6 class="fw-bold">Thank you for your business! 🎉</h6>
              </div>

              <div class="links-area text-left mb-4">
                <p class="mb-2">If you have any questions, please contact us at.</p>
                <ul class="list-unstyled d-flex gap-2 gap-sm-4 flex-column flex-sm-row">
                  <li>
                    <a href="mailto:support@homeoftraining.com" style="color:#252525; ">
                    <span class="icon-img pe-2"><img src="img/price/sms.svg"></span>
                    <span class="icon-cont fw-bold ">support@homeoftraining.com</span>
                  </a>
                  </li>
                  <li>
                    <a href="tel:954.676.7900" style="color:#252525; ">
                    <span class="icon-img pe-2"><img src="img/price/call.svg"></span>
                    <span class="icon-cont fw-bold ">954-676-7900</span>
                  </a>
                  </li>
                </ul>
              </div>

            </div>
            <div v-if="active === 2">

              <div class="row mb-5">
                <div class="col-xl-8">
                  <div class="d-flex align-items-center mb-3">
                    <img src="img/price/sizzle-plan.svg" alt="">
                    <h4 class="mb-0 px-3 fw-bold">Select Courses</h4>
                    <!-- <span class="ps-2">
                      <img src="img/price/info.svg" alt="">
                    </span> -->
                  </div>
                  <!-- <p class="fs-5">Includes the required Food and Alcohol compliance courses, Sexual Harassment, 24 course saftey program.</p> -->
                  <div class="row">
                    <!-- Included Courses -->
                    <div class="col-md-6 mb-3 mb-md-0">
                      <div class="ac-list-block border p-4 rounded-4 h-100 d-flex flex-column">
                        <h5 class="fw-bold mb-3"> Compliance Courses</h5>
                        <div class="flex-grow-1" style="height: 285px; overflow-y: auto;">
                          <ul class="list-unstyled list-group m-0 p-0">
                            <li class="list-group-item border-0 p-0 pb-2" v-for="(course, index) in additionalCourses"
                              :key="course.id" v-if="course.course_type === 1">
                              <span class="d-flex align-items-center">
                                <input type="checkbox" :id="'included-course' + index"
                                  :checked="isCourseSelected(course)"
                                  :disabled="isLimitReached() && !isCourseSelected(course)"
                                  @change="toggleCourse(course)" />
                                <label :for="'included-course' + index" class="mb-0 ml-3">
                                  {{ course.course_name }}</label>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <!-- Additional Courses -->
                    <div class="col-md-6 mb-3 mb-md-0">
                      <div class="ac-list-block border p-4 rounded-4 h-100 d-flex flex-column">
                        <h5 class="fw-bold mb-3">Additional Courses</h5>
                        <input type="text" class="form-control mb-3" placeholder="Search Courses"
                          v-model="searchAdditionalCourse">
                        <div class="flex-grow-1" style="height: 285px; overflow-y: auto;">
                          <ul class="list-unstyled list-group m-0 p-0">
                            <li class="list-group-item border-0 p-0 pb-2"
                              v-for="(course, index) in filteredAdditionalCourses" :key="course.id">
                              <span class="d-flex align-items-center">
                                <input type="checkbox" :id="'additional-course' + index"
                                  :checked="isCourseSelected(course)"
                                  :disabled="isLimitReached() && !isCourseSelected(course)"
                                  @change="toggleCourse(course)" />
                                <label :for="'additional-course' + index" class="mb-0 ml-3">
                                  {{ course.course_name }}</label>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
                <div class="col-xl-4">
                  <div class="payment-area p-4 rounded-4 mt-4">
                    <h5 class="fw-bold mb-3">Payment Details</h5>
                    <ul class="border-top border-gray custom-border-color list-unstyled p-0 pt-3 m-0 mb-3">
                      <li class="mb-2 d-flex justify-content-between" v-if="!promoCodeApplied">
                        <span> Amount Payable </span>
                        <span>{{ formatPrice(sub_total) }}</span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between" v-if="promoCodeApplied">
                        <span> Course Cost: </span>
                        <span>{{ formatPrice(course_cost) }}</span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between" v-if="promoCodeApplied">
                        <span> Minus Discount: </span>
                        <span>{{ formatPrice(discounted_cost) }}</span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between" v-if="promoCodeApplied">
                        <span> Total Amount Due: </span>
                        <span>{{ formatPrice(sub_total) }}</span>
                      </li>
                      <li class="mb-1 d-flex justify-content-between" v-if="promoCodeApplied">
                        <span class="promocode-applied">Promotional Discount Applied ({{ discount_percentage }}%)

                        </span>
                          <span style="cursor: pointer;"  v-if="lead_id" @click.prevent="submitLead()">
                            <i class="fas fa-times"></i>

                             </span>
                      </li>
                    </ul>

                    <div class="discount-code-area border-top pt-3 custom-border-color" v-if="!promoCodeApplied">
                      <h6 class="fw-bold mb-3">Discount Code?</h6>
                      <form class="d-flex mb-3" role="search">
                        <input class="form-control me-3 rounded-4 py-2" type="text" v-model="promo_code"
                          placeholder="Promotional Code">
                        <button
                          class="create-account_btn btn btn-primary nav-link rounded-3 px-4 text-white fw-medium rounded-4 py-2"
                          @click.prevent="applyPromoCode" type="submit">APPLY</button>
                      </form>
                    </div>
                    <!-- <div class="discount-code-area border-top pt-3 custom-border-color" v-else>
                      <form class="d-flex mb-3" role="search ">
                        <span v-if="lead_id">
                          <button
                            class="create-account_btn btn btn-primary nav-link rounded-3 px-4 text-white fw-medium rounded-4 py-2"
                            @click.prevent="submitLead()" type="submit">Re Estimate</button>
                        </span>
                      </form>
                    </div> -->

                    <div>
                      <!-- @click="nextStep" -->
                      <!-- :disabled="active === 4" -->
                      <base-button type="primary" @click.prevent="nextStep()"
                        class="primary-orange border-0 w-100 py-2 rounded-3">Select to Payment Method</base-button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div v-if="active === 3">

              <div class="row mb-5">
                <div class="col-xl-8">
                  <div class="mb-3">
                    <h4 class="mb-2 fw-bold">Billing Details</h4>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Phone no. <span class="req"> *</span></label>
                  <base-input
                    rules="required"
                    name="Telephone"
                    placeholder="Phone"
                    v-model="employee.phone"
                    @input="acceptNumber"
                  >
                  </base-input>
                    </div>
                    <div class="col-md-6">
                  <label class="form-control-label fw-bold"
                  >Address <span class="req"> *</span></label
                >
                <base-input
                  type="text"
                  name="Address"
                  placeholder="Address"
                  v-model="employee.address"
                ></base-input>
                </div>
                <div class="col-md-6">
                  <label class="form-control-label fw-bold"
                  >City <span class="req"> *</span></label
                >
                <base-input
                  type="text"
                  name="City"
                  placeholder="City"
                  v-model="employee.city"
                >
                </base-input>
                </div>
                <div class="col-md-6">
                  <label class="form-control-label fw-bold"
                  >State <span class="req"> *</span></label
                >
                <base-input
                  type="text"
                  name="State"
                  placeholder="State"
                  v-model="employee.state"
                >
                </base-input>
                </div>
                <div class="col-md-6">
                  <label class="form-control-label fw-bold"
                  >Zip Code <span class="req"> *</span></label
                >
                <base-input
                  type="number"
                  name="Zip code"
                  placeholder="Zip Code"
                  v-model="employee.zipcode"
                >
                </base-input>
                </div>
                <!-- User Name and Password  -->
                 <div class="col-md-6">
                  <label class="form-control-label fw-bold"
                  >Username <span class="req"> *</span></label
                >
                <base-input
                  type="test"
                  name="Username"
                  placeholder="Username"
                  readonly
                  v-model="employee.email"
                >
                </base-input>
                 </div>
                 <div class="col-md-6">
                  <label class="form-control-label fw-bold"
                  >Password <span class="req"> *</span></label
                >
                <base-input
                  :type="passwordFieldType"
                  name="Password"
                  placeholder="Password"
                  v-model="employee.password"
                >
                </base-input>
              </div>
                  </div>
                  <div class="mb-3 mt-4">
                    <h4 class="mb-0 fw-bold">Payment</h4>
                    <p class="fs-5">All transactions are secure and encrypted.</p>
                  </div>
                  <div class="payment-block-wrap border rounded-4 mb-3">
                    <div
                      class="payment-header border-bottom d-flex justify-content-between align-items-center p-3 mb-3">
                      <div class="left d-flex align-items-center">
                        <input type="radio" checked>
                        <h6 class="fw-bold m-0 pl-2">Credit card</h6>
                      </div>
                      <div class="right">
                        <ul class="list-unstyled m-0 p-0 d-flex gap-2">
                          <li><img src="img/price/payment1.svg" alt="" /> </li>
                          <li><img src="img/price/payment2.svg" alt="" /> </li>
                          <li><img src="img/price/payment3.svg" alt="" /> </li>
                        </ul>
                      </div>
                    </div>
                    <div class="payment-body p-3 mb-3">
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <input type="text" class="form-control rounded-4 " placeholder="Name on card">
                          </div>
                        </div>
                        <span class="mb-3">
                          <!-- Stripe Element   -->
                          <stripe-element-card ref="elementRef" :pk="publicKey" :hidePostalCode="postalCode"
                            @token="tokenCreated" />
                          <!-- End Stripe Element   -->
                        </span>
                        <base-button type="primary" @click.prevent="showAgreement()"
                        class="primary-orange border-0 py-2 rounded-3">Pay Now</base-button>
                        <!-- <div class="col-6">
                          <div class="input-group mb-3">
                            <input type="text" class="form-control rounded-4 rounded-end-0 border-right-0"
                              placeholder="Username" aria-label="Username">
                            <span class="input-group-text rounded-4 rounded-start-0 bg-transparent">
                              <svg width="19" height="18" viewBox="0 0 19 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.3">
                                  <path d="M5 7.5V6C5 3.5175 5.75 1.5 9.5 1.5C13.25 1.5 14 3.5175 14 6V7.5"
                                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                  <path
                                    d="M9.5 13.875C10.5355 13.875 11.375 13.0355 11.375 12C11.375 10.9645 10.5355 10.125 9.5 10.125C8.46447 10.125 7.625 10.9645 7.625 12C7.625 13.0355 8.46447 13.875 9.5 13.875Z"
                                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                  <path
                                    d="M13.25 16.5H5.75C2.75 16.5 2 15.75 2 12.75V11.25C2 8.25 2.75 7.5 5.75 7.5H13.25C16.25 7.5 17 8.25 17 11.25V12.75C17 15.75 16.25 16.5 13.25 16.5Z"
                                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                </g>
                              </svg>
                            </span>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="mb-3">
                            <input type="date" class="form-control rounded-4 " placeholder="MM/YY">
                          </div>
                        </div> -->
                        <!-- <div class="col-6">
                          <div class="input-group mb-3">
                            <input type="text" class="form-control rounded-4 rounded-end-0 border-right-0"
                              placeholder="Security code" aria-label="Username">
                            <span class="input-group-text rounded-4 rounded-start-0 bg-transparent">
                              <svg width="19" height="18" viewBox="0 0 19 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M9.50734 17.055C9.03484 17.055 8.56984 16.8975 8.20234 16.5825L7.01734 15.57C6.89734 15.465 6.59734 15.36 6.43984 15.36H5.12734C4.01734 15.36 3.11734 14.46 3.11734 13.35V12.0675C3.11734 11.91 3.01234 11.61 2.91484 11.4975L1.89484 10.2975C1.27984 9.56999 1.27984 8.42999 1.89484 7.69499L2.91484 6.49499C3.01234 6.38249 3.11734 6.08249 3.11734 5.92499V4.64999C3.11734 3.53999 4.01734 2.63999 5.12734 2.63999H6.42484C6.58234 2.63999 6.87484 2.52749 7.00234 2.42249L8.18734 1.40999C8.92234 0.78749 10.0698 0.78749 10.8048 1.40999L11.9898 2.42249C12.1098 2.52749 12.4173 2.63249 12.5748 2.63249H13.8498C14.9598 2.63249 15.8598 3.53249 15.8598 4.64249V5.91749C15.8598 6.07499 15.9723 6.36749 16.0773 6.49499L17.0898 7.67999C17.7198 8.42249 17.7123 9.56999 17.0898 10.2975L16.0773 11.4825C15.9723 11.61 15.8673 11.9025 15.8673 12.06V13.335C15.8673 14.445 14.9673 15.345 13.8573 15.345H12.5823C12.4248 15.345 12.1323 15.4575 11.9973 15.5625L10.8123 16.575C10.4448 16.8975 9.97234 17.055 9.50734 17.055ZM5.12734 3.76499C4.63984 3.76499 4.24234 4.16249 4.24234 4.64999V5.92499C4.24234 6.35249 4.04734 6.89249 3.76984 7.22249L2.74984 8.42249C2.49484 8.72999 2.49484 9.26999 2.74984 9.56999L3.76234 10.7625C4.03234 11.07 4.23484 11.6325 4.23484 12.06V13.3425C4.23484 13.83 4.63234 14.2275 5.11984 14.2275H6.42484C6.84484 14.2275 7.39984 14.43 7.72984 14.7075L8.92234 15.7275C9.22984 15.99 9.76984 15.99 10.0773 15.7275L11.2623 14.715C11.5998 14.43 12.1473 14.235 12.5673 14.235H13.8423C14.3298 14.235 14.7273 13.8375 14.7273 13.35V12.075C14.7273 11.655 14.9298 11.1075 15.2073 10.77L16.2273 9.57749C16.4898 9.26999 16.4898 8.72999 16.2273 8.42249L15.2148 7.23749C14.9298 6.89999 14.7348 6.35249 14.7348 5.93249V4.64999C14.7348 4.16249 14.3373 3.76499 13.8498 3.76499H12.5748C12.1473 3.76499 11.5923 3.56249 11.2623 3.28499L10.0698 2.26499C9.76234 2.00249 9.22984 2.00249 8.91484 2.26499L7.73734 3.28499C7.39984 3.56249 6.85234 3.76499 6.42484 3.76499H5.12734Z"
                                  fill="#292D32" />
                                <path
                                  d="M9.5 12.6525C9.0875 12.6525 8.75 12.315 8.75 11.9025C8.75 11.49 9.08 11.1525 9.5 11.1525C9.9125 11.1525 10.25 11.49 10.25 11.9025C10.25 12.315 9.92 12.6525 9.5 12.6525Z"
                                  fill="#292D32" />
                                <path
                                  d="M9.5 10.29C9.1925 10.29 8.9375 10.035 8.9375 9.72747V6.09747C8.9375 5.78997 9.1925 5.53497 9.5 5.53497C9.8075 5.53497 10.0625 5.78997 10.0625 6.09747V9.71997C10.0625 10.035 9.815 10.29 9.5 10.29Z"
                                  fill="#292D32" />
                              </svg>

                            </span>
                          </div>
                        </div> -->
<!--
                        <div class="col-12">
                          <div class="form-check mb-0">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                            <label class="form-check-label" for="flexCheckChecked">
                              Use shipping address as billing address
                            </label>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>

                  <!-- <h6 class="fw-bold mb-2">Remember me</h6>
                  <div class="form-check mb-0">
                    <input class="form-check-input" type="checkbox" value="" checked>
                    <label class="form-check-label">
                      Save my information for a faster checkout
                    </label>
                  </div> -->

                </div>
                <div class="col-xl-4">
                  <div class="payment-area p-4 rounded-4 mt-4">
                    <h5 class="fw-bold mb-3">Payment Details</h5>
                    <ul class="border-top border-gray custom-border-color list-unstyled p-0 pt-3 m-0 mb-3">
                      <li class="mb-2 d-flex justify-content-between" v-if="!promoCodeApplied">
                        <span> Amount Payable </span>
                        <span>{{ formatPrice(sub_total) }}</span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between" v-if="promoCodeApplied">
                        <span> Course Cost: </span>
                        <span>{{ formatPrice(course_cost) }}</span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between" v-if="promoCodeApplied">
                        <span> Minus Discount: </span>
                        <span>{{ formatPrice(discounted_cost) }}</span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between" v-if="promoCodeApplied">
                        <span> Total Amount Due: </span>
                        <span>{{ formatPrice(sub_total) }}</span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between" v-if="promoCodeApplied">
                        <span class="promocode-applied">Promotional Discount Applied ({{ discount_percentage }}%)
                          </span>
                          <span style="cursor: pointer;" v-if="lead_id" @click.prevent="submitLead()">
                            <i class="fas fa-times"></i>
                           </span>
                      </li>
                    </ul>



                    <div>
                      <!-- @click="nextStep" -->
                      <!-- :disabled="active === 4" -->
                      <base-button type="primary" @click.prevent="showAgreement()"
                        class="primary-orange border-0 w-100 py-2 rounded-3">Pay Now</base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="active === 4"
              class="thankyou-wrap border p-5 rounded-4 mb-5 d-flex flex-column align-items-center">
              <img width="110" class="mb-3" src="img/price/thankyou_tick.svg" alt="">
              <h5 class="fw-bold mb-3">Payment Confirmed</h5>
              <p class="text-center">Congratulations! 🎉 Your payment has been successfully processed. Thank you </p>
              <router-link to="/login">
                <base-button type="primary" class="primary-orange border-0 px-5 py-2 fw-bold">Go to Login</base-button>
              </router-link>

            </div>
          </div>
        </div>
      </section>

      <Footer />

    <modal :show.sync="showInputModal" class="user-modal">
      <h3 slot="header">Additional Information</h3>
      <div class="container">
        <div class="row">
          <!-- <div class="col-12">
              <div class="checkbox-flex">
                <input type="checkbox" id="agreement-checkbox" style="color: #E66412; background-color: #E66412;">
                <label style="font-size: 13px; color: #4F4F4F;margin:0px;" for="agreement-checkbox">
                  I Agree to Home of Training’s Terms of Services and Privacy Policy.
                </label>
              </div>
              </div> -->
          <div class="col-md-6">
            <label class="form-control-label">Phone no. <span class="req"> *</span></label>
            <base-input rules="required" name="Telephone" placeholder="Phone" v-model="employee.phone"
              @input="acceptNumber">
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label">Address <span class="req"> *</span></label>
            <base-input type="text" name="Address" placeholder="Address" v-model="employee.address"></base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label">City <span class="req"> *</span></label>
            <base-input type="text" name="City" placeholder="City" v-model="employee.city">
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label">State <span class="req"> *</span></label>
            <base-input type="text" name="State" placeholder="State" v-model="employee.state">
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label">Zip Code <span class="req"> *</span></label>
            <base-input type="number" name="Zip code" placeholder="Zip Code" v-model="employee.zipcode">
            </base-input>
          </div>
          <!-- User Name and Password  -->
          <div class="col-md-6">
            <label class="form-control-label">Username <span class="req"> *</span></label>
            <base-input type="test" name="Username" placeholder="Username" readonly v-model="employee.email">
            </base-input>
          </div>
          <div class="col-md-6">
            <label class="form-control-label">Password <span class="req"> *</span></label>
            <base-input :type="passwordFieldType" name="Password" placeholder="Password" v-model="employee.password">
            </base-input>
          </div>
          <div class="col-md-6 mt-4 mb-4">
            <button @click.prevent="InputModelClose()"
              style="border-radius:20px !important;color:white;background-color: black;" class="btn">Back</button>
            <button @click.prevent="showAgreement()"
              style="border-radius:20px !important;color:white;background-color: #E66412;" class="btn">Next</button>
          </div>

        </div>
      </div>
    </modal>
    <modal :show.sync="agreementModal" class="user-modal">
      <h3 slot="header" class="fw-bold mb-0 pb-0">Service Activation Agreement</h3>
      <form>
        <div class="agreement-content">
          <agreement type="individual"></agreement>
        </div>
        <div class="text-center d-flex justify-content-center mt-2">
          <base-button class="btn base-button primary-orange border-0 py-2 rounded-3 btn-primary" @click.prevent="moveNext">
            I Agree
          </base-button>
          <base-button class="btn base-button primary-orange border-0 py-2 rounded-3 btn-primary" @click.prevent="cancelAgreement">
            Cancel
          </base-button>
        </div>
      </form>
    </modal>
  </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Option, OptionGroup, Select, Table, TableColumn, Steps, Step } from "element-ui";
import Header from '../Common/Header.vue';
import Footer from '../Common/Footer.vue';
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
// import CreditCard from "@/views/Widgets/CreditCard";
import { StripeElementCard } from '@vue-stripe/vue-stripe';

import Agreement from "./Agreement.vue";
import { Dynamic } from "../../wl";
import VueGtag from "vue-gtag";
import NewSignupFooter from "../Components/NewSignupFooter.vue";

// Vue.use(VueGtag, {
//     config: {id: "AW-754017760"}
// });
export default {
  name: "register",
  components: {
    Agreement,
    // CreditCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NewSignupFooter,
    Header,
    Footer,
    [Steps.name]: Steps,
    [Step.name]: Step,
    StripeElementCard,

  },
  data() {
    return {
      card: {
        cardNumber: "",
        expire: "",
        paymentType: "monthly",
        address: this.address,
        state: this.state,
        city: this.city,
        zip: this.zip,
        token: null,
      },
      publicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      postalCode: true,
      active: 1,
      searchAdditionalCourse: "",
      showInputModal: false,
      sub_total: 0,
      showForm: true,
      folder2stockLimit: 10,
      folder2stockLimitShow: 5,
      folder3stockLimit: 25,
      folder3stockLimitShow: 20,
      showFolderLimit: '',
      limitstart: 0,
      selectedFolderLimit: '',
      openFolder1: false,
      openFolder2: false,
      openFolder3: false,
      openFolder4: false,
      folder1Courses: [123, 122, 23],
      folder2Courses: [123, 122, 23, 103, 51], // Have upto 5 stock courses
      folder3Courses: [123, 122, 23, 103, 51], // Have upto 20 stock courses
      folder4Courses: [], // Unlimited courses ...
      preselectedCourses: [],
      additionalCourses: [],
      additionalCoursesCopy:[],
      additionalCopy: [],
      defaultSelectedCourses: [],
      selectedCourses: [],
      loading: false,
      paymentModalMessage: false,
      baseUrl: this.$baseUrl,
      agreementModal: false,
      courseSelectionFocused: false,
      showPaymentOption: false,
      search: "",
      courses: [],
      checked_courses: [],
      formData: {
        employee_first_name: "",
        employee_last_name: "",
        user_type: "",
        employee_address: "",
        employee_city: "",
        employee_state: "",
        employee_zipcode: "",
        employee_email: "",
        employee_phone_num: "",
        phone: "",
        password: "",
        address: ""
      },
      employee: {
        first_name: "",
        last_name: "",
        emial: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
        username: "",
        password: ""
      },
      passwordFieldType: "password",
      showPricePlan: false,
      total_cost: "",
      total_discount: "",
      discountperlocation: "",
      perYearCost: "",
      discount_msg: "",
      lead_id: "",
      services: [],
      siteName: "",
      infoEmail: "",
      promo_code: "",
      appliedText: "",
      showPromoCodeOption: false,
      promoCodeApplied: false,
      course_cost: "",
      discounted_cost: "",
      ispromocode: 0,
      isContinueButtonDisabled: true,
      enablePaymentButton: false,
    };
  },
  mounted() {
    this.siteName = Dynamic.SITE_NAME;
    this.infoEmail = Dynamic.INFO_EMAIL;
    this.$gtag.event("Individual signup", { method: "Google" });
    history.pushState(null, null, window.location.href);
    window.onpopstate = this.handleBackButton;
  },
  created() {
    if (this.$route.query.redirection === "yes") {
      this.employee.first_name = localStorage.getItem("fname");
      this.employee.last_name = localStorage.getItem("lname");
      this.employee.email = localStorage.getItem("email");
      this.checked_courses = JSON.parse(localStorage.getItem("courses"));
    } else {
      localStorage.removeItem("fname");
      localStorage.removeItem("lname");
      localStorage.removeItem("email");
      localStorage.removeItem("courses");
    }
    this.$http.get("user/discountRules").then(resp => {
      for (let course of resp.data.courses) {
        let obj = {
          checked: false,
          id: course.id,
          course_name: course.name,
          course_type: course.course_type,
          cost: course.cost
        };
        this.courses.push(obj);
      }
      this.selectedCourses = this.courses.filter((course) =>
        this.defaultSelectedCourses.includes(course.id)
      );
      this.preSelectedCourses = this.courses.filter((course) =>
        this.defaultSelectedCourses.includes(course.id)
      );
      this.additionalCourses = this.courses.filter(
        (course) => !this.defaultSelectedCourses.includes(course.id)
      );
      this.additionalCourses.sort((a, b) => {
        if (a.course_name.toLowerCase() < b.course_name.toLowerCase()) return -1;
        if (a.course_name.toLowerCase() > b.course_name.toLowerCase()) return 1;
        return 0;
      });
      this.additionalCoursesCopy = this.additionalCourses.filter(
        (course) => course.course_type === 0
      );
      for (let service of resp.data.services) {
        let obj = {
          id: service.id,
          name: service.name,
          price: service.price,
          frequency: service.frequency
        };
        this.services.push(obj);
      }
    });
  },
  computed: {
    filteredAdditionalCourses() {
      // Dynamically filter based on search input
      return this.additionalCoursesCopy.filter((course) =>
        course.course_name
          .toLowerCase()
          .includes(this.searchAdditionalCourse.toLowerCase())
      );
    },
  },
  methods: {
    tokenCreated(token) {
      this.card.token = token;
      this.card.zip = this.employee.zipcode;
      this.card.address = this.employee.address;
      this.card.state = this.employee.state;
      this.card.city = this.employee.city;
      this.payClicked(this.card);
      // handle the token
      // send it to your server
    },
    async moveNext() {
      try {
        const token = await this.$refs.elementRef.submit();  // Ensure it submits correctly
        this.agreementModal = false;
        // this.nextStep();
        // this.ProceedToPayment();
      } catch (error) {
        console.error("Error generating token:", error);
      }
    },

    nextStep() {
      if (this.active == 1) {
        if (this.employee.first_name == '' || this.employee.last_name == '' || this.employee.email == '' || this.employee.phone == '') {
          // if(this.employee.first_name=='' || this.employee.last_name=='' || this.employee.email=='' || this.employee.phone=='' || this.employee.address=='' || this.employee.city=='' || this.employee.state=='' || this.employee.zipcode==''){
          Swal.fire({
            icon: 'warning',
            title: 'Missing Field',
            text: `Please fill all the fields.`,
            confirmButtonText: 'OK',
          });
          return;
        }
      }

      if (this.active <= 4) {
        this.active++;
      }
    },
    prevStep() {
      if (this.active > 1) {
        this.active--;
      }
    },
    InputModelOpen() {
      if (this.selectedCourses.length === 0) {
        Swal.fire({
          icon: "warning",
          title: "Missing Field",
          text: `Please select atleast one course.`,
          confirmButtonText: "OK"
        });
        return;
      }
      this.showInputModal = true;
    },
    InputModelClose() {
      this.showInputModal = false;
    },
    handleBackButton(event) {
      // Prevent the default back button behavior
      event.preventDefault();

      // Change the showForm state to show or hide the form
      this.showForm = true; // Toggle showForm state when back button is clicked

      // Optionally, you can display a confirmation dialog before changing the form state
      if (!this.showForm) {
        Swal.fire({
          icon: 'info',
          title: 'Are you sure?',
          text: 'You are leaving this page. Do you want to go back?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then(result => {
          if (result.isConfirmed) {
            // If user confirms, allow normal back navigation
            history.back();  // Go back to the previous page
          } else {
            // Otherwise, we reset the state without navigating
            history.pushState(null, null, window.location.href);
          }
        });
      }
    },
    isLimitReached() {
      // Check if the selected folder has a limit and if the number of selected courses exceeds the limit
      if (this.openFolder2 || this.openFolder3) {
        return this.selectedCourses.length >= this.selectedFolderLimit;
      }
      return false; // No limit for folder 1 and folder 4
    },
    async selectFolder(id) {
      for (let i = 1; i <= 4; i++) {
        this[`openFolder${i}`] = i === id;
      }

      if (id === 1) {
        this.defaultSelectedCourses = this.folder1Courses;
      } else if (id === 2) {
        this.defaultSelectedCourses = this.folder2Courses;
        this.selectedFolderLimit = this.folder2stockLimit;
        this.showFolderLimit = this.folder2stockLimitShow

      } else if (id === 3) {
        this.defaultSelectedCourses = this.folder3Courses;
        this.selectedFolderLimit = this.folder3stockLimit;
        this.showFolderLimit = this.folder3stockLimitShow
      } else if (id === 4) {
        this.defaultSelectedCourses = this.folder4Courses;
      } else {
        this.defaultSelectedCourses = this.folder4Courses;
        this.selectedFolderLimit = '';
      }
      this.selectedCourses = this.courses.filter((course) =>
        this.defaultSelectedCourses.includes(course.id)
      );
      this.preSelectedCourses = this.courses.filter((course) =>
        this.defaultSelectedCourses.includes(course.id)
      );
      this.additionalCourses = this.courses.filter(
        (course) => !this.defaultSelectedCourses.includes(course.id)
      );

      await this.submitLead();

    },
    isCourseSelected(course) {
      return this.selectedCourses.some((selected) => selected.id === course.id);
    },
    toggleCourse(course) {
      const courseIndex = this.selectedCourses.findIndex(
        (c) => c?.id === course?.id
      );

      if (courseIndex !== -1) {
        // Course is already selected, remove it from the array
        this.selectedCourses.splice(courseIndex, 1);
        // this.limitstart=this.selectedCourses.length;
        this.limitstart = this.selectedCourses.length;
      } else {
        if (this.openFolder2 || this.openFolder3) {
          // Get the selected folder's limit (folder 2 has limit 10, folder 3 has limit 25)
          const limit = this.selectedFolderLimit;

          // Only allow adding more courses if the current limit is not reached
          if (this.selectedCourses.length >= limit) {
            alert(`Limit of ${limit} courses reached for this folder`);
            return; // Prevent adding more courses if the limit is reached
          }
        }
        // Course is not selected, add it to the array
        this.selectedCourses = [
          ...this.selectedCourses.filter((c) => c.id !== course.id),
          course,
        ];
      }

      if (this.selectedCourses.length > 0 && this.preSelectedCourses.length === 0) {
        this.openFolder4 = true; // Enable folder 4
      } else if (this.selectedCourses.length === 0) {
        this.openFolder4 = false; // Disable folder 4 when no courses are selected
      }

      this.submitLead();
    },
    getPreAndAdditionalCourses() {
      this.selectedCourses = this.courses.filter((course) =>
        this.defaultSelectedCourses.includes(course.id)
      );
      this.preSelectedCourses = this.courses.filter((course) =>
        this.defaultSelectedCourses.includes(course.id)
      );
      this.additionalCourses = this.courses.filter(
        (course) => !this.defaultSelectedCourses.includes(course.id)
      );
    },
    showCoursePage() {
      // Define only the fields you want to validate
      const requiredFields = {
        first_name: "First Name",
        last_name: "Last Name",
        email: "Email",
        // address: "Address",
        // city: "City",
        // state: "State",
        // zipcode: "Zip Code",
        // password:"Password"
      };

      for (const field in requiredFields) {
        if (!this.employee[field]) {
          Swal.fire({
            icon: "warning",
            title: "Missing Field",
            text: `Please fill in the ${requiredFields[field]}.`,
            confirmButtonText: "OK"
          });
          return; // Stop further execution
        }
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
      // Proceed to show the course page if all required fields are filled
      this.showForm = false;
    },

    showFormPage() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.showForm = true;
    },
    applyPromoCode() {

      let data = {
        amount: this.sub_total,
        promocode: this.promo_code,
      }
      this.$http
        .post("promocode/apply_promocode", data)
        .then(resp => {
          this.sub_total = resp.data.final_amount;
          this.course_cost = resp.data.previous_amount;
          this.discounted_cost = resp.data.discounted_amount;
          this.discount_percentage = resp.data.discount_percentage;
          this.submitLead();

        }).catch(function (error) {
          if (error.response.status === 422) {
            return Swal.fire({
              title: "Error!",
              html: error.response.data.message,
              icon: "error"
            });
          }
        });

    },
    finalCreateAccount() {
      this.agreementModal = false;
      this.showPaymentOption = true;
    },
    showDone() {
      this.courseSelectionFocused = true;
    },
    doneClicked() {
      this.courseSelectionFocused = false;
    },
    cancelAgreement() {
      this.agreementModal = false;
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    formatPrice(value) {
      return (
        "$ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
      );
    },
    acceptNumber() {
      var x = this.employee.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.employee.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    showAgreement() {
      const requiredFields = {
        address: "Address",
        city: "City",
        state: "State",
        zipcode: "Zip Code",
        password: "Password"
      };

      for (const field in requiredFields) {
        if (!this.employee[field]) {
          Swal.fire({
            icon: "warning",
            title: "Missing Field",
            text: `Please fill in the ${requiredFields[field]}.`,
            confirmButtonText: "OK"
          });
          return; // Stop further execution
        }
      }
      if (this.employee.password == "") {
        return Swal.fire({
          title: "Error!",
          text: "Please enter password to continue.",
          icon: "error"
        });
      }
      this.showInputModal = false;
      const courseIds = this.selectedCourses.map(course => course.id);
      this.formData = {
        employee_first_name: this.employee.first_name,
        employee_last_name: this.employee.last_name,
        user_type: "individual",
        employee_address: this.employee.address,
        employee_city: this.employee.city,
        employee_state: this.employee.state,
        employee_zipcode: this.employee.zipcode,
        employee_email: this.employee.email,
        employee_phone_num: this.employee.phone,
        password: this.employee.password,
        address: this.employee.address,
        courses: courseIds,
        i_agree: true
      };
      this.agreementModal = true;
    },
    submitLead() {
      if (
        this.employee.first_name &&
        this.employee.last_name &&
        // this.employee.address &&
        // this.employee.city &&
        // this.employee.state &&
        // this.employee.zipcode &&
        this.employee.email
      ) {
        const courseIds = this.selectedCourses.map(course => course.id);
        // this.loading = true;
        let data = {
          company_name: "individual",
          first_name: this.employee.first_name,
          last_name: this.employee.last_name,
          number_of_locations: 1,
          number_of_employees: 1,
          phone_num: this.employee.phone || '',
          email: this.employee.email || '',
          user_type: "individual",
          course_ids: courseIds,
          promo_code: this.promo_code,
          course_cost: this.course_cost,
          discounted_cost: this.sub_total

        };
        this.$http
          .post("user/lead", data)
          .then(resp => {
            if (!this.promo_code) {
              this.lead_id = resp.data.user_id;
              this.companyEstimateDetailModel = false;
              this.total_cost = resp.data.total;
              this.sub_total = resp.data.sub_total;
              this.total_discount = resp.data.discount_value;
              if (resp.data.discount != null) {
                this.discount_msg = resp.data.discount.title;
              }

              this.showPricePlan = true;
              this.promo_code = "";
              this.showPromoCodeOption = true;
              this.appliedText = "";
              this.promoCodeApplied = false;
              this.perYearCost = resp.data.perYearCost;
            } else {
              this.promoCodeApplied = true;
              this.showPromoCodeOption = false;
              this.promo_code = "";
            }
            this.loading = false;
            this.isContinueButtonDisabled = false;
          })
          .catch(function (error) {
            this.loading = false;
            if (error.response.status === 422) {
              return Swal.fire({
                title: "Error!",
                html: error.response.data.message,
                icon: "error"
              });
            }
          });
      } else {
        this.loading = false;
        Swal.fire({
          title: "Error!",
          text: "Please fill all mandatory fields.",
          icon: "error"
        });
      }
    },
    // async ProceedToPayment() {
    // //  await this.$refs.elementRef.submit();
    //  await this.payClicked(this.card);
    //   // alert('proceed');
    // },
    payClicked(cardData) {
      // console.log(cardData);

      // return;
      this.loading = true;
      this.paymentModalMessage = true;
      let payment = {
        payment_type: cardData.paymentType,
        cardholder_street_address:
          cardData.address + "," + cardData.city + "," + cardData.state,
        cardholder_zip: cardData.zip,
        transaction_amount: "",
        token: cardData.token
      };
      if (payment.payment_type == "monthly") {
        payment.transaction_amount = this.sub_total.toFixed(2);
      }
      if (payment.payment_type == "yearly") {
        payment.transaction_amount = this.perYearCost.toFixed(2);
      }
      if (this.promoCodeApplied) {
        this.formData.promo_code = this.promo_code;
        this.formData.course_cost = this.course_cost;
        this.formData.discounted_cost = this.discounted_cost;
      }
      this.formData.payment = payment;
      this.formData.employee_address = cardData.address;
      this.formData.address = cardData.address;
      this.formData.employee_state = cardData.state;
      this.formData.employee_city = cardData.city;
      this.formData.employee_zipcode = cardData.zip;
      this.loading = false;
      this.createAccount(this.formData);
    },
    createAccount(formDataSubmitted) {
      this.loading = true;
      delete this.$http.defaults.headers["authorization"];
      this.$http
        .post("employees/register", formDataSubmitted)
        .then(resp => {
          let ids = [];
          let obj = {
            id: resp.data.id
          };
          ids.push(obj);
          this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
          this.paymentModalMessage = false;
          Swal.fire({
            title: "Success!",
            html: `Your account has been created and is now active! <a href="https://lms.homeoftraining.com/#/login">Click here</a> to Login`,
            icon: "success",
            confirmButton: "btn btn-success",
            confirmButtonText: "Ok"
          }).then(result => {
            if (result.value) {
              this.nextStep();


              // this.$router.push("/login");
            }
          });
        })
        .catch((error) => {
          this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
          this.paymentModalMessage = false;
          Swal.fire({
            title: "Error!",
            text: error.response.data.message,
            icon: "error"
          });
          this.loading = false;
        }).finally(() => (this.loading = false));
    }
  },
  watch: {
    lead_id: function () {
      if (this.lead_id == "") {
        this.isContinueButtonDisabled = true;
      }
    },
    checked_courses: function () {
      this.isContinueButtonDisabled = true;
    },
  },
};
</script>
<style scoped>

.folder-card {
  height: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}

.folder-card .text-right img {
  max-width: 20px;
  height: auto;
}

.folder-card img {
  max-width: 100%;
  height: auto;
}

.folder-card h2 {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}



.flexed {
  display: flex;
}

.f-col {
  flex-direction: column;
}

.gap-2 {
  gap: 20px;
}

.gap-3 {
  gap: 20px;
}

.top-selection-banner {
  display: flex;
  gap: 20px;
}

.top-selection-banner .inner-info {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.text-overlay {
  position: absolute;
  bottom: -92px !important;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: white;
  z-index: 100;
  text-align: left;
}

.text-content {
  z-index: 100 !important;
  position: relative;
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.text-content img {
  width: 120px;
  height: auto;
}

.overlay-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent 0%, black 100%);

}

.modal-dialog {
  justify-content: center !important;
}

.modal-dialog .modal-content {
  width: fit-content;
}

.text-title {
  font-size: 32px;
  font-weight: bolder;
  line-height: 1.2;
  margin-bottom: 10px;
  color: white
}

.text-subtitle {
  font-size: 13px;
  line-height: 1.5;
  max-width: 80%;
  color: white;
}

.active-fldr {
  background-color: #E66412;
}

.nrml-fldr-text {
  color: #767676;
  font-size: 16px;
}

.active-fldr-text {
  color: #ffffff;
  font-size: 16px;
}

input[type="checkbox"] {
  accent-color: #E66412;
  /* Change this to your desired color */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.search-bar {
  width: 76% !important;
  position: relative;
}

.search-icon {
  position: absolute;
  right: 15px;
  top: 14px;
}

.search-bar>input {
  width: 100%;
  height: 54px;
  /* opacity: 0px; */
  border: 1px solid #dddddd;
}

.search-bar input[type="text"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.course-list-ul {
  max-height: 400px;
  /* Adjust based on your needs */
  /* over-flow-y:auto; */
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
  color: #787676;
}

.mgbtm {
  margin-bottom: 100px;
}

.course-list-ul li {
  line-height: 16px;
  /* Line spacing */
  margin-bottom: 14px;
  /* Add bottom margin to each list item */
}

.btn-black {
  width: 100%;
  background-color: black;
  color: white;
  border: none;
  border-radius: 0%;
  padding: 10px;
  /* Adjust padding for desired height */
  font-size: 16px;
  /* Adjust font size */
  text-align: center;
  cursor: pointer;
  border-radius: 5px !important;
}

.btn-black-red {
  width: 100%;
  background-color: #E66412;
  color: white;
  border: none;
  border-radius: 0%;
  padding: 10px;
  /* Adjust padding for desired height */
  font-size: 16px;
  /* Adjust font size */
  text-align: center;
  cursor: pointer;
}

.btn-black:hover {
  background-color: #333;
  /* Slightly lighter black for hover effect */
}

.payment-modal-text {
  animation: textBlink 2s linear infinite;
}

@keyframes textBlink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.form-section {
  background-color: transparent;
  padding: 40px;
  border-right: 1px solid #999999;
}

.promocode-applied {
  color: #eb1a26;
  font-weight: bold;
  font-size: 14px;
}

.py-5 {
  padding-bottom: 0px !important;
}

.mt--6 {
  margin-top: -6rem !important;
}

.mt--12 {
  margin-top: -12rem !important;
}

hr {
  margin-top: 2px !important;
  margin-bottom: 20px !important;
}

#selected_course li {
  font-size: 0.89em;
}

#serviceAgreement {
  float: left;
  height: 300px;
  overflow: auto;
}

#serviceAgreement p {
  font-size: 0.81rem;
  text-align: justify;
}

.el-select-group__title {
  font-size: 14px !important;
  font-weight: bolder;
  color: #28c0e7;
}

.el-select-dropdown__item {
  font-size: 13px !important;
}

.reduceFont {
  font-weight: 400 !important;
}

.price-area .row {
  margin-top: 5px;
}

.bg-gradient-primary {
  background: linear-gradient(87deg, #07c9fb 0, #ffffff 100%) !important;
}

.req {
  color: red;
}

.show-on-mobile {
  display: none;
}

@media (min-width: 992px) {

  .pt-lg-9,
  .py-lg-9 {
    padding-top: 3rem !important;
  }


}

.courses-box {
  background: white;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 475px;
}

.courses-box h2 {
  margin: 0;
  padding: 0;
}

.checkbox-flex {
  display: flex;
  gap: 10px;
  margin: 10px 0px;
}

.height-cart {
  padding: 20px 20px;
}

.sticky-submit-btn {
  display: none;
  margin-bottom: 80px;
}

.inner-info .selection-type {
  color: black;
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
}

.inner-info .selection-desc {
  color: #7A7A7A;
  font-size: 13px;
  line-height: normal;
}

@media (max-width: 990px) {
  .show-on-mobile {
    display: block;
  }

  .mobile-margin-top-10 {
    margin-top: 10px;
  }

  .height-cart {
    height: fit-content;
    padding: 20px 20px;
  }

  .hide-on-mobile {
    display: none;
  }



  .sticky-submit-btn {
    display: flex;
    justify-content: space-between;
    background-color: rgb(30, 30, 30);
    color: white;
    border-radius: 0px !important;
    position: fixed;
    width: 95%;
    margin: 0px 0px 10px -3px !important;
    padding: 8px 20px !important;
    border-radius: 100px !important;
    bottom: 0;
    z-index: 100;
    align-items: center;
  }


  .margin-0-mobile {
    margin-top: 0px;
  }

  .top-selection-banner {
    gap: 8px;
  }

  .top-selection-banner img {
    width: 32px;
    margin-top: 5px;
  }

  .inner-info .selection-type {
    color: black;
    font-size: 15px;
    line-height: normal;
    font-weight: 600;
  }

  .inner-info .selection-desc {
    color: #7A7A7A;
    font-size: 12px;
    line-height: normal;
  }

  .inner-info {
    display: none !important;
  }

}

.discount-btn {
  border-radius: 5px !important;
  background-color: rgb(230, 100, 18);
  color: white;
  margin-left: 10px;
  position: absolute;
  right: 0px;
  height: 43px;
  border: 0;
  padding: 0px 20px;
}

.mobile-cart-total {
  display: flex;
  flex-direction: column;
}

.mobile-total-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mobile-courses-label {
  font-weight: 400;
  font-size: 12px;
}

.course-cost-label {
  color: #1E1E1E;
  font-weight: 200;
  font-size: 13px;
}

.logo-section {
  display: flex;
  justify-content: space-between;
}

.logo-section img.logo {
  width: 100px !important;
}

.cart-box {
  background-color: #FFE9DA;
  padding: 10px;
  border: 1px solid #FFDCC6;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.form-control:focus {
  box-shadow: none;
  outline: none;
}

</style>
