<template>
    <div v-loading.fullscreen.lock="loading">
      <transition name="fade">
      <div>
        <div class="logo-section-mobile">
                <img src="signup-img/hot.svg" alt="Hot Icon" class="logo">
                <a href="/#/login" style="text-decoration: underline;">Login</a>
            </div>
        <div class="container-fluid" v-if="showForm">
        <div class="row">
          <!-- Left Section -->
          <div class="col-md-6 p-0 position-relative hide-on-mobile">

            <img
              src="signup-img/bg-left.jpg"
              alt="Left Image"
              class="img-fluid w-100"
              style="object-fit: cover;height: 95vh;"
              />

              <div class="text-overlay">
                <div class="text-content">
                  <img src="signup-img/hot-white.png" class="img-fluid" alt="">

                <p class="text-subtitle">
                  Our mission is to help businesses stay compliant with industry regulations and ensure the safety of their customers.
                </p>
              </div>
                <div class="overlay-bg"></div>
              </div>
          </div>

          <!-- Right Section -->
          <div class="col-md-6 mt-3 mb-3">
            <div class="container">
             <div class="flexed f-col gap-3 mb-5">
              <div class="logo-section hide-on-mobile">
                <img src="signup-img/hot.svg" alt="Hot Icon" class="logo">

            </div>

              <div class="row">
                <div class="col-12">
                  <!-- <span style="color: #4F4F4F; font-size: 13px;">
                    If you are a staff member of a company that uses our service, please  <a
                    :href="'mailto:' + infoEmail"
                    style="text-decoration: underline;"
                    >click here</a
                  > to email our Support team, and they will add you to the system. For an individual currently not working for a company we provide training to, click Individual User. To sign up a company currently not using our service, select Company. Thank you for your business!
                  </span> -->

                  <ul style="color: rgb(79, 79, 79); font-size: 14px; padding: 0 18px; display: flex ; flex-direction: column; gap: 8px; margin: 0;">
                    <li><strong>Current users</strong> - If you are a team member of a company that uses our service, please  <span class="login-link" style="color:#e66412;cursor: pointer;"><a href="mailto:support@homeoftraining.com?subject=Please%20add%20me%20as%20a%20user%20to%20the%20following%20location:%20%20(list%20your%20name%20and%20job%20title%20below)">(click here)</a></span> to email our Support team, and they will add you to the system.</li>
                    <li><strong>New user</strong> - For an individual currently not working for a company we provide training to, click Individual User.</li>
                    <li><strong>New Company</strong> - To sign up a company currently not using our service, select Company.</li>
                  </ul>

                  <p style="color: rgb(79, 79, 79); font-size: 15px !important; margin: 15px 0px 0px; font-weight: 500;">Thank you for your business!</p>

                </div>
              </div>
            


             <h2 class="account-heading">Choose Your <span class="highlight-heading">Account</span> Type</h2>

             <div class="flexed gap-3">

              <div class="flexed f-col account-selection" @click="$router.push('/new-user_register')">
                <img src="signup-img/ind-user-icon.svg" class="img-fluid" alt="">
                <span class="selection-type">Individual</span>
                <span class="selection-desc">New single user</span>
              </div>
              <div class="flexed f-col account-selection" @click="showConfirmationModal">
                <img src="signup-img/company-icon.svg" class="img-fluid" alt="">
                <span class="selection-type">Company</span>
                <span class="selection-desc">New company signup</span>
              </div>


            </div>
            <span style="color:#525f7f;">Already have an account? <span class="login-link" style="color:#e66412;cursor: pointer;" @click="$router.push('/login')">Login</span></span>
            
            <span style="color:#525f7f;display:flex; gap:5px;flex-wrap: wrap;">Already work for a location and need to be added to the system? <span class="login-link" style="color:#e66412;cursor: pointer;"><a href="mailto:support@homeoftraining.com?subject=Please%20add%20me%20as%20a%20user%20to%20the%20following%20location:%20%20(list%20your%20name%20and%20job%20title%20below)">(click here)</a></span></span>
            



             </div>
            </div>
            </div>
          </div>
        </div>




      </div>
    </transition>
    <NewSignupFooter/>
    </div>





  </template>
  <script>
  import Vue from "vue";
  import {Option, OptionGroup, Select, Table, TableColumn} from "element-ui";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import "sweetalert2/src/sweetalert2.scss";
  import CreditCard from "@/views/Widgets/CreditCard";
  import Agreement from "./Agreement.vue";
  import {Dynamic} from "../../wl";
  import VueGtag from "vue-gtag";
  import NewSignupFooter from "../Components/NewSignupFooter.vue";

  // Vue.use(VueGtag, {
  //     config: {id: "AW-754017760"}
  // });
  export default {
      name: "register",
      components: {
          Agreement,
          CreditCard,
          [Select.name]: Select,
          [Option.name]: Option,
          [OptionGroup.name]: OptionGroup,
          [Table.name]: Table,
          [TableColumn.name]: TableColumn,
          NewSignupFooter,
      },
      data() {
          return {
              searchAdditionalCourse:"",
              showInputModal:false,
              sub_total:0,
              showForm:true,
              folder2stockLimit:10,
              folder2stockLimitShow:5,
              folder3stockLimit:25,
              folder3stockLimitShow:20,
              showFolderLimit:'',
              limitstart:0,
              selectedFolderLimit:'',
              openFolder1:false,
              openFolder2:false,
              openFolder3:false,
              openFolder4:false,
              folder1Courses :[123,122,23],
              folder2Courses :[123,122,23,103,51], // Have upto 5 stock courses
              folder3Courses :[123,122,23,103,51], // Have upto 20 stock courses
              folder4Courses :[], // Unlimited courses ...
              preselectedCourses : [],
              additionalCourses: [],
              additionalCopy: [],
              defaultSelectedCourses : [],
              selectedCourses : [],
              loading: false,
              paymentModalMessage: false,
              baseUrl: this.$baseUrl,
              agreementModal: false,
              courseSelectionFocused: false,
              showPaymentOption: false,
              search: "",
              courses: [],
              checked_courses: [],
              formData: {
                  employee_first_name: "",
                  employee_last_name: "",
                  user_type: "",
                  employee_address: "",
                  employee_city: "",
                  employee_state: "",
                  employee_zipcode: "",
                  employee_email: "",
                  employee_phone_num: "",
                  password: "",
                  address: ""
              },
              employee: {
                  first_name: "",
                  last_name: "",
                  emial: "",
                  phone: "",
                  address: "",
                  city: "",
                  state: "",
                  zipcode: "",
                  username: "",
                  password: ""
              },
              passwordFieldType: "password",
              showPricePlan: false,
              total_cost: "",
              total_discount: "",
              discountperlocation: "",
              perYearCost: "",
              discount_msg: "",
              lead_id: "",
              services: [],
              siteName: "",
              infoEmail: "",
              promo_code: "",
              appliedText: "",
              showPromoCodeOption: false,
              promoCodeApplied: false,
              course_cost: "",
              discounted_cost: "",
              ispromocode: 0,
              isContinueButtonDisabled: true,
              enablePaymentButton: false,
          };
      },
      // mounted() {
      //     this.siteName = Dynamic.SITE_NAME;
      //     this.infoEmail = Dynamic.INFO_EMAIL;
      //     this.$gtag.event("Individual signup", {method: "Google"});
      //     history.pushState(null, null, window.location.href);
      //     window.onpopstate = this.handleBackButton;
      // },
      // created() {
      //     if (this.$route.query.redirection === "yes") {
      //         this.employee.first_name = localStorage.getItem("fname");
      //         this.employee.last_name = localStorage.getItem("lname");
      //         this.employee.email = localStorage.getItem("email");
      //         this.checked_courses = JSON.parse(localStorage.getItem("courses"));
      //     } else {
      //         localStorage.removeItem("fname");
      //         localStorage.removeItem("lname");
      //         localStorage.removeItem("email");
      //         localStorage.removeItem("courses");
      //     }
      //     this.$http.get("user/discountRules").then(resp => {
      //         for (let course of resp.data.courses) {
      //             let obj = {
      //                 checked: false,
      //                 id: course.id,
      //                 course_name: course.name,
      //                 course_type: course.course_type,
      //                 cost: course.cost
      //             };
      //             this.courses.push(obj);
      //         }
      //         this.selectedCourses = this.courses.filter((course) =>
      //         this.defaultSelectedCourses.includes(course.id)
      //       );
      //       this.preSelectedCourses = this.courses.filter((course) =>
      //         this.defaultSelectedCourses.includes(course.id)
      //       );
      //       this.additionalCourses = this.courses.filter(
      //         (course) => !this.defaultSelectedCourses.includes(course.id)
      //       );
      //       this.additionalCoursesCopy = this.additionalCourses.filter(
      //           (course) => course.course_type === 0
      //         );
      //         for (let service of resp.data.services) {
      //             let obj = {
      //                 id: service.id,
      //                 name: service.name,
      //                 price: service.price,
      //                 frequency: service.frequency
      //             };
      //             this.services.push(obj);
      //         }
      //     });
      // },
      // computed: {
      // filteredAdditionalCourses() {
      //   // Dynamically filter based on search input
      //   return this.additionalCoursesCopy.filter((course) =>
      //     course.course_name
      //       .toLowerCase()
      //       .includes(this.searchAdditionalCourse.toLowerCase())
      //   );
      // },
      // },
      methods: {
        showConfirmationModal() {
        Swal.fire({
          title: 'Sign Up as a Company',
    html: `
      <div class="row">
        <div class="col-12">
          <span style="color: #4F4F4F; font-size: 13px;">
            <ul style="list-style-type: none; padding: 0;text-align: justify; margin-top: 15px;">
              <li style="margin-bottom: 8px;">
                <strong>Current Users:</strong> If you are a team member of a company that uses our service, please
                <a href="mailto:support@homeoftraining.com?subject=Please%20add%20me%20as%20a%20user%20to%20the%20following%20location:%20%20(list%20your%20name%20and%20job%20title%20below)">(click here)</a> to email our Support team to be added to the system.
              </li>
              <li style="margin-bottom: 8px;">
                <strong>New User:</strong> If you are an individual not currently working for a company we train, select 'Individual User.'
              </li>
              <li>
                <strong>New Company:</strong> To sign up a company not currently using our service, select 'Continue.'
              </li>
            </ul>
          </span>
        </div>
      </div>
    `,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        reverseButtons: true,

      }).then((result) => {
        if (result.isConfirmed) {
          // Navigate to the company registration page
          this.$router.push('/company_register');
        }
      });
    },

        InputModelOpen()
        {
          this.showInputModal=true;
        },
        InputModelClose()
        {
          this.showInputModal=false;
        },
        handleBackButton(event) {
      // Prevent the default back button behavior
         event.preventDefault();

      // Change the showForm state to show or hide the form
      this.showForm = true; // Toggle showForm state when back button is clicked

      // Optionally, you can display a confirmation dialog before changing the form state
      if (!this.showForm) {
        Swal.fire({
          icon: 'info',
          title: 'Are you sure?',
          text: 'You are leaving this page. Do you want to go back?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then(result => {
          if (result.isConfirmed) {
            // If user confirms, allow normal back navigation
            history.back();  // Go back to the previous page
          } else {
            // Otherwise, we reset the state without navigating
            history.pushState(null, null, window.location.href);
          }
        });
      }
    },
        isLimitReached() {
       // Check if the selected folder has a limit and if the number of selected courses exceeds the limit
      if (this.openFolder2 || this.openFolder3) {
        return this.selectedCourses.length >= this.selectedFolderLimit;
      }
      return false; // No limit for folder 1 and folder 4
      },
      async selectFolder(id) {
        for (let i = 1; i <= 4; i++) {
          this[`openFolder${i}`] = i === id;
        }

          if(id===1){
            this.defaultSelectedCourses = this.folder1Courses;
          }else if(id===2){
            this.defaultSelectedCourses = this.folder2Courses;
            this.selectedFolderLimit = this.folder2stockLimit;
            this.showFolderLimit = this.folder2stockLimitShow

          }else if(id===3){
            this.defaultSelectedCourses = this.folder3Courses;
            this.selectedFolderLimit = this.folder3stockLimit;
            this.showFolderLimit = this.folder3stockLimitShow
          }else if(id===4){
            this.defaultSelectedCourses = this.folder4Courses;
          }else{
            this.defaultSelectedCourses = this.folder4Courses;
            this.selectedFolderLimit = '';
          }
             this.selectedCourses = this.courses.filter((course) =>
              this.defaultSelectedCourses.includes(course.id)
            );
            this.preSelectedCourses = this.courses.filter((course) =>
              this.defaultSelectedCourses.includes(course.id)
            );
            this.additionalCourses = this.courses.filter(
              (course) => !this.defaultSelectedCourses.includes(course.id)
            );

            await this.submitLead();

       },
        isCourseSelected(course)
        {
          return this.selectedCourses.some((selected) => selected.id === course.id);
        },
        toggleCourse(course)
        {
          const courseIndex = this.selectedCourses.findIndex(
          (c) => c?.id === course?.id
        );

        if (courseIndex !== -1) {
          // Course is already selected, remove it from the array
          this.selectedCourses.splice(courseIndex, 1);
          // this.limitstart=this.selectedCourses.length;
          this.limitstart = this.selectedCourses.length;
         } else {
          if (this.openFolder2 || this.openFolder3) {
        // Get the selected folder's limit (folder 2 has limit 10, folder 3 has limit 25)
           const limit = this.selectedFolderLimit;

          // Only allow adding more courses if the current limit is not reached
          if (this.selectedCourses.length >= limit) {
            alert(`Limit of ${limit} courses reached for this folder`);
            return; // Prevent adding more courses if the limit is reached
          }
         }
          // Course is not selected, add it to the array
          this.selectedCourses = [
            ...this.selectedCourses.filter((c) => c.id !== course.id),
            course,
          ];
        }

        if (this.selectedCourses.length > 0 && this.preSelectedCourses.length === 0) {
          this.openFolder4 = true; // Enable folder 4
        } else if (this.selectedCourses.length === 0) {
          this.openFolder4 = false; // Disable folder 4 when no courses are selected
        }

        this.submitLead();
        },
        getPreAndAdditionalCourses()
        {
            this.selectedCourses = this.courses.filter((course) =>
              this.defaultSelectedCourses.includes(course.id)
            );
            this.preSelectedCourses = this.courses.filter((course) =>
              this.defaultSelectedCourses.includes(course.id)
            );
            this.additionalCourses = this.courses.filter(
              (course) => !this.defaultSelectedCourses.includes(course.id)
            );
        },
        showCoursePage() {
        // Define only the fields you want to validate
        const requiredFields = {
          first_name: "First Name",
          last_name: "Last Name",
          email: "Email",
          // address: "Address",
          // city: "City",
          // state: "State",
          // zipcode: "Zip Code",
          // password:"Password"
        };

        for (const field in requiredFields) {
          if (!this.employee[field]) {
            Swal.fire({
              icon: "warning",
              title: "Missing Field",
              text: `Please fill in the ${requiredFields[field]}.`,
              confirmButtonText: "OK"
            });
            return; // Stop further execution
          }
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
        // Proceed to show the course page if all required fields are filled
        this.showForm = false;
      },

          showFormPage()
          {
            window.scrollTo({ top: 0, behavior: "smooth" });
            this.showForm=true;
          },
          applyPromoCode() {

              let data = {
                  amount: this.sub_total,
                  promocode: this.promo_code,
              }
              this.$http
                  .post("promocode/apply_promocode", data)
                  .then(resp => {
                      this.sub_total = resp.data.final_amount;
                      this.course_cost = resp.data.previous_amount;
                      this.discounted_cost = resp.data.discounted_amount;
                      this.discount_percentage = resp.data.discount_percentage;
                      this.submitLead();

                  }).catch(function (error) {
                  if (error.response.status === 422) {
                      return Swal.fire({
                          title: "Error!",
                          html: error.response.data.message,
                          icon: "error"
                      });
                  }
              });

          },
          finalCreateAccount() {
              this.agreementModal = false;
              this.showPaymentOption = true;
          },
          showDone() {
              this.courseSelectionFocused = true;
          },
          doneClicked() {
              this.courseSelectionFocused = false;
          },
          cancelAgreement() {
              this.agreementModal = false;
          },
          switchVisibility() {
              this.passwordFieldType =
                  this.passwordFieldType === "password" ? "text" : "password";
          },
          formatPrice(value) {
              return (
                  "$ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
              );
          },
          acceptNumber() {
              var x = this.employee.phone
                  .replace(/\D/g, "")
                  .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
              this.employee.phone = !x[2]
                  ? x[1]
                  : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
          },
          showAgreement() {
              const requiredFields = {
              address: "Address",
              city: "City",
              state: "State",
              zipcode: "Zip Code",
              password:"Password"
          };

          for (const field in requiredFields) {
            if (!this.employee[field]) {
              Swal.fire({
                icon: "warning",
                title: "Missing Field",
                text: `Please fill in the ${requiredFields[field]}.`,
                confirmButtonText: "OK"
              });
              return; // Stop further execution
            }
          }
                if (this.employee.password == "") {
                    return Swal.fire({
                        title: "Error!",
                        text: "Please enter password to continue.",
                        icon: "error"
                    });
                }
                this.showInputModal=false;
              const courseIds = this.selectedCourses.map(course => course.id);
              this.formData = {
                  employee_first_name: this.employee.first_name,
                  employee_last_name: this.employee.last_name,
                  user_type: "individual",
                  employee_address: this.employee.address,
                  employee_city: this.employee.city,
                  employee_state: this.employee.state,
                  employee_zipcode: this.employee.zipcode,
                  employee_email: this.employee.email,
                  employee_phone_num: this.employee.phone,
                  password: this.employee.password,
                  address: this.employee.address,
                  courses: courseIds,
                  i_agree: true
              };
              this.agreementModal = true;
          },
          submitLead() {
              if (
                  this.employee.first_name &&
                  this.employee.last_name &&
                  // this.employee.address &&
                  // this.employee.city &&
                  // this.employee.state &&
                  // this.employee.zipcode &&
                  this.employee.email
              ) {
                const courseIds = this.selectedCourses.map(course => course.id);
                  // this.loading = true;
                  let data = {
                      company_name: "individual",
                      first_name: this.employee.first_name,
                      last_name: this.employee.last_name,
                      number_of_locations: 1,
                      number_of_employees: 1,
                      phone_num: this.employee.phone || '',
                      email: this.employee.email || '',
                      user_type: "individual",
                      course_ids: courseIds,
                      promo_code: this.promo_code,
                      course_cost: this.course_cost,
                      discounted_cost: this.sub_total

                  };
                  this.$http
                      .post("user/lead", data)
                      .then(resp => {
                          if (!this.promo_code) {
                              this.lead_id = resp.data.user_id;
                              this.companyEstimateDetailModel = false;
                              this.total_cost = resp.data.total;
                              this.sub_total = resp.data.sub_total;
                              this.total_discount = resp.data.discount_value;
                              if (resp.data.discount != null) {
                                  this.discount_msg = resp.data.discount.title;
                              }

                              this.showPricePlan = true;
                              this.promo_code = "";
                              this.showPromoCodeOption = true;
                              this.appliedText = "";
                              this.promoCodeApplied = false;
                              this.perYearCost = resp.data.perYearCost;
                          } else {
                              this.promoCodeApplied = true;
                              this.showPromoCodeOption = false;
                              this.promo_code = "";
                          }
                          this.loading = false;
                          this.isContinueButtonDisabled = false;
                      })
                      .catch(function (error) {
                          this.loading = false;
                          if (error.response.status === 422) {
                              return Swal.fire({
                                  title: "Error!",
                                  html: error.response.data.message,
                                  icon: "error"
                              });
                          }
                      });
              } else {
                  this.loading = false;
                  Swal.fire({
                      title: "Error!",
                      text: "Please fill all mandatory fields.",
                      icon: "error"
                  });
              }
          },
          payClicked(cardData) {
              this.loading = true;
              this.paymentModalMessage = true;
              let payment = {
                  payment_type: cardData.paymentType,
                  cardholder_street_address:
                      cardData.address + "," + cardData.city + "," + cardData.state,
                  cardholder_zip: cardData.zip,
                  transaction_amount: "",
                  token: cardData.token
              };
              if (payment.payment_type == "monthly") {
                  payment.transaction_amount = this.sub_total.toFixed(2);
              }
              if (payment.payment_type == "yearly") {
                  payment.transaction_amount = this.perYearCost.toFixed(2);
              }
              if (this.promoCodeApplied) {
                  this.formData.promo_code = this.promo_code;
                  this.formData.course_cost = this.course_cost;
                  this.formData.discounted_cost = this.discounted_cost;
              }
              this.formData.payment = payment;
              this.formData.employee_address = cardData.address;
              this.formData.address = cardData.address;
              this.formData.employee_state = cardData.state;
              this.formData.employee_city = cardData.city;
              this.formData.employee_zipcode = cardData.zip;
              this.loading = false;
              this.createAccount(this.formData);
          },
          createAccount(formDataSubmitted) {
              this.loading = true;
              delete this.$http.defaults.headers["authorization"];
              this.$http
                  .post("employees/register", formDataSubmitted)
                  .then(resp => {
                      let ids = [];
                      let obj = {
                          id: resp.data.id
                      };
                      ids.push(obj);
                      this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                      this.paymentModalMessage = false;
                      Swal.fire({
                          title: "Success!",
                          html: `Your account has been created and is now active! <a href="https://lms.homeoftraining.com/#/login">Click here</a> to Login`,
                          icon: "success",
                          confirmButton: "btn btn-success",
                          confirmButtonText: "Ok"
                      }).then(result => {
                          if (result.value) {
                              this.$router.push("/login");
                          }
                      });
                  })
                  .catch((error) => {
                      this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                      this.paymentModalMessage = false;
                      Swal.fire({
                          title: "Error!",
                          text: error.response.data.message,
                          icon: "error"
                      });
                      this.loading = false;
                  }).finally(() => (this.loading = false));
          }
      },
      watch: {
          lead_id: function () {
              if (this.lead_id == "") {
                  this.isContinueButtonDisabled = true;
              }
          },
          checked_courses: function () {
              this.isContinueButtonDisabled = true;
          },
      },
  };
  </script>
  <style scoped>
  .folder-card {
    height: 150px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    transition: all 0.3s ease-in-out;
  }

  .folder-card .text-right img {
    max-width: 20px;
    height: auto;
  }

  .folder-card img {
    max-width: 100%;
    height: auto;
  }

  .folder-card h2 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
  }



  .flexed {
    display:flex;
  }

  .f-col {
    flex-direction: column;
  }

  .gap-2 {
    gap:20px;
  }

  .gap-3 {
    gap:20px;
  }

  .top-selection-banner {
    display:flex;
    gap:20px;
  }

  .top-selection-banner .inner-info {
    display:flex;
    flex-direction: column;
    align-self: center;
  }

  .text-overlay {
      position: absolute;
      bottom: -92px !important;
      width: 100%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      color: white;
      z-index: 100;
      text-align: left;
  }

  .text-content {
    z-index: 100 !important;
    position: relative;
      padding: 40px 40px;
      display: flex;
      flex-direction: column;
      gap: 10px;
  }

  .text-content img {
    width:120px;
    height: auto;
  }

  .overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 0%, black 100%);

  }

  .modal-dialog {
    justify-content: center !important;
  }

  .modal-dialog .modal-content {
      width: fit-content;
  }

  .text-title {
    font-size: 32px;
    font-weight: bolder;
    line-height: 1.2;
    margin-bottom: 10px;
    color:white
  }

  .text-subtitle {
    font-size: 13px;
    line-height: 1.5;
    max-width: 80%;
    color: white;
  }

  .active-fldr{
    background-color: #E66412;
  }
  .nrml-fldr-text{
    color: #767676;
    font-size: 16px;
  }
  .active-fldr-text{
    color: #ffffff;
    font-size: 16px;
  }
  input[type="checkbox"] {
    accent-color: #E66412; /* Change this to your desired color */
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .search-bar {
    width: 76% !important;
    position: relative;
  }
  .search-icon {
    position: absolute;
    right: 15px;
    top: 14px;
  }

  .search-bar > input {
    width: 100%;
    height: 54px;
    /* opacity: 0px; */
    border: 1px solid #dddddd;
  }

  .search-bar input[type="text"] {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    margin-right: 10px;
  }
  .course-list-ul {
    max-height: 400px; /* Adjust based on your needs */
    /* over-flow-y:auto; */
    overflow-y: auto;
    padding: 0;
    margin: 0;
    list-style-type: none;
    color: #787676;
  }
  .mgbtm{
    margin-bottom: 100px;
  }
  .course-list-ul li {
    line-height: 16px; /* Line spacing */
    margin-bottom: 14px; /* Add bottom margin to each list item */
  }
  .btn-black {
    width: 100%;
    background-color: black;
    color: white;
    border: none;
    border-radius: 0%;
    padding: 10px; /* Adjust padding for desired height */
    font-size: 16px; /* Adjust font size */
    text-align: center;
    cursor: pointer;
    border-radius: 5px !important;
  }
  .btn-black-red {
    width: 100%;
    background-color: #E66412;
    color: white;
    border: none;
    border-radius: 0%;
    padding: 10px; /* Adjust padding for desired height */
    font-size: 16px; /* Adjust font size */
    text-align: center;
    cursor: pointer;
  }

  .btn-black:hover {
    background-color: #333; /* Slightly lighter black for hover effect */
  }
  .payment-modal-text {
      animation: textBlink 2s linear infinite;
  }
  @keyframes textBlink{
      0%{
          opacity: 0;
      }
      50%{
          opacity: 1;
      }
      100%{
          opacity: 0;
      }
  }

  .form-section {
    background-color: transparent;
    padding: 40px;
    border-right: 1px solid #999999;
  }
  .promocode-applied{
    color:#5ec75e;
    font-weight:bold;
    font-size:14px;
  }
  .py-5 {
    padding-bottom: 0px !important;
  }
  .mt--6 {
    margin-top: -6rem !important;
  }
  .mt--12 {
    margin-top: -12rem !important;
  }
  hr {
    margin-top: 2px !important;
    margin-bottom: 20px !important;
  }
  #selected_course li {
    font-size: 0.89em;
  }
  #serviceAgreement {
    float: left;
    height: 300px;
    overflow: auto;
  }
  #serviceAgreement p {
    font-size: 0.81rem;
    text-align: justify;
  }
  .el-select-group__title {
    font-size: 14px !important;
    font-weight: bolder;
    color: #28c0e7;
  }
  .el-select-dropdown__item {
    font-size: 13px !important;
  }
  .reduceFont {
    font-weight: 400 !important;
  }
  .price-area .row {
    margin-top: 5px;
  }
  .bg-gradient-primary {
    background: linear-gradient(87deg, #07c9fb 0, #ffffff 100%) !important;
  }
  .req {
    color: red;
  }

  .show-on-mobile {
    display: none !important;
  }
  @media (min-width: 992px) {

    .pt-lg-9,
    .py-lg-9 {
      padding-top: 3rem !important;
    }


  }

  .courses-box {
    background:white;
    padding: 15px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      height: 475px;
  }

  .courses-box h2 {
    margin: 0;
      padding: 0;
  }

  .checkbox-flex {
      display: flex;
      gap: 10px;
      margin: 10px 0px;
  }

  .height-cart {
      padding: 20px 20px;
  }
  .sticky-submit-btn {
    display: none;
  }

  .inner-info .selection-type {
    color: black;
    font-size: 22px;
    line-height: normal;
    font-weight: 600;
  }

  .inner-info .selection-desc {
    color: #7A7A7A;
    font-size: 13px;
    line-height: normal;
  }

  .logo-section-mobile {
    display: none;
  }
  @media (max-width: 990px) {
    .show-on-mobile {
      display:block;
    }
    .mobile-margin-top-10 {
      margin-top: 10px;
    }
    .height-cart {
    height: fit-content;
      padding: 20px 20px;
  }

  .hide-on-mobile {
    display: none !important;
  }

  .logo-section-mobile {
    align-items: center;
      display: flex;
      justify-content: space-between;
    background: white;
    padding: 15px 30px;
    box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
  }

  .sticky-submit-btn {
    display: flex;
          justify-content: space-between;
    background-color: rgb(30, 30, 30);
      color: white;
      border-radius: 0px !important;
      position: fixed;
      width: 95%;
      margin: 0px 0px 10px -3px !important;
      padding: 8px 20px !important;
      border-radius: 100px !important;
      bottom: 0;
      z-index: 100;
      align-items: center;
  }


  .margin-0-mobile {
    margin-top: 0px;
  }

  .top-selection-banner {
    gap: 8px;
  }
  .top-selection-banner img {
    width: 32px;
    margin-top: 5px;
  }

  .inner-info .selection-type {
    color: black;
    font-size: 15px;
    line-height: normal;
    font-weight: 600;
  }

  .inner-info .selection-desc {
    color: #7A7A7A;
    font-size: 12px;
    line-height: normal;
  }

  .inner-info {
    display: none !important;
  }

  }

  .discount-btn {
    border-radius: 5px !important;
    background-color: rgb(230, 100, 18);
    color: white;
    margin-left: 10px;
    position: absolute;
    right: 0px;
    height: 43px;
    border: 0;
    padding: 0px 20px;
  }

  .mobile-cart-total {
      display: flex;
      flex-direction: column;
  }

  .mobile-total-price {
    display: flex;
      flex-direction: column;
      align-items: flex-end;
  }

  .mobile-courses-label {
    font-weight: 400;
      font-size: 12px;
  }

  .course-cost-label {
    color: #1E1E1E;
    font-weight: 200;
    font-size: 13px;
  }

  .logo-section {
    align-items: center;
      display: flex;
      justify-content: space-between;
  }

  .logo-section img.logo, .logo-section-mobile img.logo{
      width: 100px !important;
  }



  .cart-box {
    background-color: #FFE9DA; padding: 10px; border: 1px solid #FFDCC6; box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }

  .account-heading {
    font-size: 24px;
    font-weight: 800;
    color: #1E1E1E;
  }

  .highlight-heading {
    color: #E66412;
  }

  .account-selection {
    display: flex;
    gap: 5px;
    align-items: center;
    background: white;
    padding: 25px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .account-selection:hover {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    transition: all 0.3s ease-in-out;
  }

  .account-selection .selection-type {
    font-size: 16px;
    font-weight: 600;
    color: #1E1E1E;
  }

  .account-selection .selection-desc {
    font-size: 13px;
    color: #7A7A7A;
  }

  .account-selection img{
height:36px;
  }
  </style>
