import { render, staticRenderFns } from "./UserRegistrationND.vue?vue&type=template&id=546e9752&scoped=true"
import script from "./UserRegistrationND.vue?vue&type=script&lang=js"
export * from "./UserRegistrationND.vue?vue&type=script&lang=js"
import style0 from "./UserRegistrationND.vue?vue&type=style&index=0&id=546e9752&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546e9752",
  null
  
)

export default component.exports