<template>
  <div v-loading.fullscreen.lock="loading">
    <div class="generic-layout">
      <Header />
      <section class="py-4 py-md-5">
        <div class="container">
          <div class="steps-wrap position-relative">
            <div class="steps-tab-wrap-outer steps-wrap-width" v-if="paymentSuccess==false">
              <router-link v-if="active == 1" to="/new_registration">
                <span :disabled="active === 1" class="back-btn">
                  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM20 7L1 7V9L20 9V7Z"
                      fill="black" />
                  </svg>
                  <span class="ps-2 fw-bold text-dark">Back</span>
                </span>
              </router-link>

              <span :disabled="active === 1" v-else @click="prevStep" class="back-btn">
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM20 7L1 7V9L20 9V7Z"
                    fill="black" />
                </svg>
                <span class="ps-2 fw-bold">Back</span>
              </span>
              <el-steps class="steps-tab-wrap pb-4 pb-md-5" :active="active" finish-status="success">
                <el-step></el-step>
                <el-step></el-step>
                <el-step></el-step>
                <el-step></el-step>
              </el-steps>
            </div>
          </div>
          <div class="steps pt-4">
            <div class="steps-wrap-width" v-if="active === 0">
              <div class="ac-list-block border p-4 rounded-4 mb-4 mb-md-5">
                <ul class="list-unstyled">
                  <li class="d-flex mb-3">
                    <span><img src="img/price/orange-tick.svg" alt="" /></span>
                    <div class="ac-list-content ps-3">
                      <h6 class="fw-bold mb-1">Current users</h6>
                      <p class="lh-sm">If you are a team member of a company that uses our service, please <a
                          href="mailto:support@homeoftraining.com" class="fw-bold">click here</a> to email our Support
                        team, and they will add you to the system.
                      </p>
                    </div>
                  </li>
                  <li class="d-flex mb-3">
                    <span><img src="img/price/orange-tick.svg" alt="" /></span>
                    <div class="ac-list-content ps-3">
                      <h6 class="fw-bold mb-1">New user</h6>
                      <p class="lh-sm">For an individual currently not working for a company we provide training to,
                        click Individual User.</p>
                    </div>
                  </li>
                  <li class="d-flex mb-4">
                    <span><img src="img/price/orange-tick.svg" alt="" /></span>
                    <div class="ac-list-content ps-3">
                      <h6 class="fw-bold mb-1">New Company</h6>
                      <p class="lh-sm"> To sign up a company currently not using our service, select Company.</p>
                    </div>
                  </li>
                </ul>
                <h6 class="fw-bold">Thank you for your business! 🎉</h6>
              </div>
            </div>
            <div class="steps-wrap-width" v-show="active === 1">
              <div class="selected_user d-flex align-items-center mb-4">
                <span><img src="img/price/company.svg" alt="" /></span>
                <div class="select-user-con pl-3">
                  <h5 class="fw-bold mb-0">Company</h5>
                  <span>New company signup</span>
                </div>
              </div>
              <div class="form-list-block border p-4 rounded-4 mb-4">
                <form class="general-form">
                  <div class="row">
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">First Name<span class="requireField"> *</span></label>
                      <base-input type="text" name="First Name" placeholder="Enter your first name" rules="required"
                        v-model="company.first_name">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Last Name<span class="requireField"> *</span></label>
                      <base-input type="text" name="Last Name" placeholder="Enter your Last Name" rules="required"
                        v-model="company.last_name">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Email Address<span class="requireField">
                          *</span></label>
                      <base-input type="email" name="Email Address" placeholder="Enter your email address"
                        rules="required" v-model="company.email">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Phone Number<span class="requireField"> *</span></label>
                      <base-input type="text" name="Phone Number" placeholder="Enter your phone number"
                        rules="required|min:10" v-model="company.telephone_no" @input="acceptNumber">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Company Name<span class="requireField"> *</span></label>
                      <base-input type="text" name="Company Name" placeholder="Enter your company name"
                        rules="required" v-model="company.name">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Estimated # of Users<span class="requireField">
                          *</span></label>
                      <base-input type="text" name="Estimated # of Users" placeholder="Enter your estimated user"
                        rules="required" v-model="company.no_of_employees">
                      </base-input>
                    </div>
                    <div class="col-md-12">
                      <label class="form-control-label fw-bold"># of Locations<span class="requireField">
                          *</span></label>
                      <base-input type="text" name="# of Locations" placeholder="Enter your location" rules="required"
                        v-model="company.no_of_locations">
                      </base-input>
                    </div>

                  </div>
                  <base-button type="primary" :disabled="active === 4" @click="nextStep"
                    class="primary-orange border-0 w-100 py-2 rounded-3">Next</base-button>
                </form>
              </div>
              <div class="ac-list-block border p-4 rounded-4 mb-3 mb-md-5">
                <ul class="list-unstyled">
                  <li class="d-flex mb-3">
                    <span><img src="img/price/orange-tick.svg" alt="" /></span>
                    <div class="ac-list-content ps-3">
                      <h6 class="fw-bold mb-1">Current users</h6>
                      <p class="lh-sm">If you are a team member of a company that uses our service, please <a
                          href="mailto:support@homeoftraining.com" class="fw-bold">click here</a> to email our Support
                        team, and they will add you to the system.
                      </p>
                    </div>
                  </li>
                  <li class="d-flex mb-3">
                    <span><img src="img/price/orange-tick.svg" alt="" /></span>
                    <div class="ac-list-content ps-3">
                      <h6 class="fw-bold mb-1">New user</h6>
                      <p class="lh-sm">For an individual currently not working for a company we provide training to,
                        click Individual User.</p>
                    </div>
                  </li>
                  <li class="d-flex mb-4">
                    <span><img src="img/price/orange-tick.svg" alt="" /></span>
                    <div class="ac-list-content ps-3">
                      <h6 class="fw-bold mb-1">New Company</h6>
                      <p class="lh-sm"> To sign up a company currently not using our service, select Company.</p>
                    </div>
                  </li>
                </ul>
                <h6 class="fw-bold">Thank you for your business! 🎉</h6>
              </div>

              <div class="links-area text-left mb-4">
                <p class="mb-2">If you have any questions, please contact us at.</p>
                <ul class="list-unstyled d-flex gap-2 gap-sm-4 flex-column flex-sm-row">
                  <li>
                    <a href="mailto:support@homeoftraining.com" style="color:#252525; ">
                      <span class="icon-img pe-2"><img src="img/price/sms.svg"></span>
                      <span class="icon-cont fw-bold">support@homeoftraining.com</span>
                    </a>
                  </li>
                  <li>
                    <a href="tel:954.676.7900" style="color:#252525; ">
                      <span class="icon-img pe-2"><img src="img/price/call.svg"></span>
                      <span class="icon-cont fw-bold">954-676-7900</span>
                    </a>
                  </li>
                </ul>
              </div>

            </div>
            <div v-show="active === 2">

              <div class="row mb-5">
                <div class="col-xl-8">
                  <div class="d-flex align-items-center mb-3">
                    <img src="img/price/sizzle-plan.svg" alt="">
                    <h4 class="mb-0 px-3 fw-bold">{{ planName }}<div class="text-red fw-bold"
                        style="font-size:13px; margin-top:2px;">{{ freeTrail == false ?
                          ''
                          : ' Free Trial' }}</div>
                    </h4>
                    <!-- <span class="ps-2">
                      <img src="img/price/info.svg" alt="">
                    </span> -->
                  </div>
                  <p class="fs-5" v-if="plan == 'hot'">{{ hot_des }}</p>
                  <p class="fs-5" v-if="plan == 'sizzling'">{{ siz_des }}</p>
                  <p class="fs-5" v-if="plan == 'scorching'">{{ sco_des }}</p>
                  <!-- <p class="fs-5">Includes the required Food and Alcohol compliance courses, Sexual Harassment, 24
                    course saftey program.</p> -->
                  <div class="row">
                    <div class="col-md-6 mb-3 mb-md-0" v-if="plan != 'default'"
                      :class="{ 'col-md-12': freeTrail == 'true' || plan === 'scorching' }">
                      <div class="ac-list-block border p-4 rounded-4 h-100 d-flex flex-column">
                        <h5 class="fw-bold mb-3">Included Courses</h5>
                        <div class="flex-grow-1" style="height: 285px; overflow-y: auto;">
                          <ul class="list-unstyled list-group m-0 p-0">
                            <!-- Free Trial Courses Heading and List -->
                            <li class="list-group-item border-0 p-0 pb-2" v-if="freeTrialCourses.length > 0">
                              <h6 class="fw-bold mt-2 mb-2" v-if="this.freeTrail">Free Trial</h6>
                            </li>
                            <li class="list-group-item border-0 p-0 pb-2" v-for="(course, index) in freeTrialCourses"
                              :key="course.id">
                              <span class="d-flex align-items-center">
                                <input type="checkbox" :id="'included-course-free' + index"
                                  :checked="isCourseSelected(course)" :disabled="true" @change="toggleCourse(course)" />
                                <label :for="'included-course-free' + index" class="mb-0 ml-3">{{ course.course_name
                                  }}</label>
                                <div v-if="specialCourses.some((special) => special.id === course.id)"
                                  class="special-course-options ml-2">
                                  <img v-if="selectedCourses.some((selected) => selected.id === course.id)"
                                    src="signup-img/signup-group-icon.svg" class="img-fluid" width="20px"
                                    @click="showInput(course)" alt="User Icon" style="cursor: pointer" />
                                </div>
                              </span>
                            </li>

                            <!-- Other Courses Heading and List -->
                            <li class="list-group-item border-0 p-0 pb-2" v-if="otherCourses.length > 0">
                              <h6 class="fw-bold mt-3 mb-2" v-if="freeTrail">Plan Courses</h6>
                            </li>
                            <li class="list-group-item border-0 p-0 pb-2" v-for="(course, index) in otherCourses"
                              :key="course.id">
                              <span class="d-flex align-items-center">
                                <input type="checkbox" :id="'included-course-other' + index"
                                  :checked="isCourseSelected(course)" :disabled="true" @change="toggleCourse(course)" />
                                <label :for="'included-course-other' + index" class="mb-0 ml-3">{{ course.course_name
                                  }}</label>
                                <div v-if="specialCourses.some((special) => special.id === course.id)"
                                  class="special-course-options ml-2">
                                  <img v-if="selectedCourses.some((selected) => selected.id === course.id)"
                                    src="signup-img/signup-group-icon.svg" class="img-fluid" width="20px"
                                    @click="showInput(course)" alt="User Icon" style="cursor: pointer" />
                                </div>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Compliace Courses  -->
                    <div class="col-md-6 mb-3 mb-md-0" v-if="plan == 'default'">
                      <div class="ac-list-block border p-4 rounded-4 h-100 d-flex flex-column">
                        <h5 class="fw-bold mb-3">Compliance Courses</h5>
                        <div class="flex-grow-1" style="height: 285px; overflow-y: auto;">
                          <ul class="list-unstyled list-group m-0 p-0">
                            <li class="list-group-item border-0 p-0 pb-2"
                              v-for="(course, index) in (plan === 'default' ? filteredAdditionalCourses.filter(c => c.course_type === 1) : filteredAdditionalCourses)"
                              :key="course.id">
                              <span class="d-flex align-items-center">
                                <input type="checkbox" :id="'included-course' + index"
                                  :checked="isCourseSelected(course)"
                                  :disabled="isLimitReached() && !isCourseSelected(course)"
                                  @change="toggleCourse(course)" />
                                <label :for="'included-course' + index" class="mb-0 ml-3">
                                  {{ course.course_name }}</label>
                                <div v-if="
                                  specialCourses.some(
                                    (special) => special.id === course.id
                                  )
                                " class="special-course-options ml-2">
                                  <img v-if="
                                    selectedCourses.some(
                                      (selected) => selected.id === course.id
                                    )
                                  " src="signup-img/signup-group-icon.svg" class="img-fluid" width="20px"
                                    @click="showInput(course)" alt="User Icon" style="cursor: pointer" />
                                </div>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Additional Courses  -->
                    <div class="col-md-6 mb-3 mb-md-0" v-if="freeTrail != 'true' && additionalCourses.length > 0">
                      <div class="ac-list-block border p-4 rounded-4 h-100">
                        <h5 class="fw-bold mb-3">Additional Courses </h5>
                        <input type="text" class="form-control mb-3" placeholder="Search Courses"
                          v-model="searchAdditionalCourse">
                        <ul class="list-unstyled list-group m-0 p-0" style="height: 285px; overflow-y: auto;">
                          <!-- // Here Foreach based on selected folder -->


                          <li class="list-group-item border-0 p-0 pb-2"
                            v-for="(course, index) in (plan === 'default' ? filteredAdditionalCourses.filter(c => c.course_type === 0) : filteredAdditionalCourses)"
                            :key="course.id">
                            <span class="d-flex align-items-center">
                              <input type="checkbox" :id="'additional-course' + index"
                                :checked="isCourseSelected(course)"
                                :disabled="isLimitReached() && !isCourseSelected(course)"
                                @change="toggleCourse(course)" />
                              <label :for="'additional-course' + index" class="mb-0 ml-3">
                                {{ course.course_name }}</label>

                              <!-- Special course input (only visible for special courses) -->
                              <div v-if="
                                specialCourses.some(
                                  (special) => special.id === course.id
                                )
                              " class="special-course-options ml-2">
                                <img v-if="
                                  selectedCourses.some(
                                    (selected) => selected.id === course.id
                                  )
                                " src="signup-img/signup-group-icon.svg" class="img-fluid" width="20px"
                                  @click="showInput(course)" alt="User Icon" style="cursor: pointer" />
                              </div>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-0 mt-md-4">
                    <div class="col-12">
                      <div class="ac-list-block border p-4 rounded-4">
                        <h4 class="fw-bold mb-4">Add-ons</h4>
                        <div class="row g-3">
                          <!-- <div class="col-12" v-if="!this.selectedCourses.some(course => course.id === 123)"> -->
                          <div class="col-12">
                            <div class="position-relative">
                              <input class="checkbox-design" type="checkbox"
                                @change="addtoggleCourse(123)" :checked="CheckisCourseSelected(course)">
                              <div class="adds-on-block p-3 border rounded-4 position-relative">
                                <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                                <div class="row align-items-center">
                                  <span class="img-wrap col-2 col-md-auto">
                                    <img src="img/price/food-manager.svg" alt="">
                                  </span>
                                  <div class="col-6 text-start mb-0 adds-on-content">
                                    <h5 class="m-0 fw-bold">Food Manager</h5>
                                    <span>Safe food handling, hygiene, and regulatory</span>
                                  </div>
                                  <div class="action-area col-4 ms-auto">
                                    <div class="action-block p-3 rounded-4">
                                      <strong class="d-block text-center">$99.00</strong>
                                      <span class="d-block text-center">Per exam</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="col-12" v-if="!this.selectedCourses.some(course => course.id === 51)"> -->
                          <div class="col-12">
                            <div class="position-relative">
                              <input class="checkbox-design" type="checkbox"
                              @change="addtoggleCourse(51)" :checked="CheckisCourseSelected(course)">
                              <div class="adds-on-block p-3 border rounded-4">
                                <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                                <div class="row align-items-center">
                                  <span class="img-wrap col-2 col-md-auto">
                                    <img src="img/price/human_trafficking.svg" alt="">
                                  </span>
                                  <div class="col-6 text-start mb-0 adds-on-content">
                                    <h5 class="m-0 fw-bold">Human Trafficking</h5>
                                    <span>Awareness, prevention, and legal response.</span>
                                  </div>
                                  <div class="action-area col-4 ms-auto">
                                    <div class="action-block p-3 rounded-4">
                                      <strong class="d-block text-center">$0.99</strong>
                                      <span class="d-block text-center">Per month</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="position-relative">
                              <input class="checkbox-design" type="checkbox"
                                 @change="addtoggleCourse(105)" :checked="CheckisCourseSelected(course)"/>

                              <div class="adds-on-block p-3 border rounded-4">
                                <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                                <div class="row align-items-center">
                                  <span class="img-wrap col-2 col-md-auto">
                                    <img src="img/price/sexual_harassment.svg" alt="">
                                  </span>
                                  <div class="col-6 text-start mb-0 adds-on-content">
                                    <h5 class="m-0 fw-bold">Sexual Harassment Prevention</h5>
                                    <span>Prevention, reporting, and workplace safety.</span>
                                  </div>
                                  <div class="action-area col-4 ms-auto">
                                    <div class="action-block p-3 rounded-4">
                                      <strong class="d-block text-center">$1.25</strong>
                                      <span class="d-block text-center">Per month</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="position-relative">
                              <input class="checkbox-design" type="checkbox" v-model="is24hourSafetyChecked"
                                @change="updateTotalPrice('HoursSafety', 3.0)">
                              <div class="adds-on-block p-3 border rounded-4 position-relative">
                                <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                                <div class="row align-items-center">
                                  <span class="img-wrap col-2 col-md-auto">
                                    <img src="img/price/food-manager.svg" alt="">
                                  </span>
                                  <div class="col-6 text-start mb-0 adds-on-content">
                                    <h5 class="m-0 fw-bold">24- course Safety Package</h5>
                                    <span>
                                      Gather anonymous feedback
                                    </span>
                                  </div>
                                  <div class="action-area col-4 ms-auto">
                                    <div class="action-block p-3 rounded-4">
                                      <strong class="d-block text-center">$3.00</strong>
                                      <span class="d-block text-center">Per month</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="position-relative">
                              <input class="checkbox-design" type="checkbox" v-model="isUploadTrainingChecked"
                                @change="updateTotalPrice('UploadTraining', 5.00)">
                              <div class="adds-on-block p-3 border rounded-4">
                                <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                                <div class="row justify-content-end align-items-center">
                                  <span class="img-wrap col-2 col-md-auto">
                                    <img src="img/price/upload_your_own.svg" alt="">
                                  </span>
                                  <div class="col-6 text-start mb-0 adds-on-content">
                                    <h5 class="m-0 fw-bold">Upload Your Own Trainings</h5>
                                    <span>Prevention, reporting, and workplace safety.</span>
                                  </div>
                                  <div class="action-area col-4 ms-auto">
                                    <div class="action-block p-3 rounded-4">
                                      <strong class="d-block text-center">$5.00</strong>
                                      <span class="d-block text-center">Per month</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="position-relative">
                              <input class="checkbox-design" type="checkbox" v-model="isSMSChecked"
                                @change="updateTotalPrice('SMS', 9.0)">
                              <div class="adds-on-block p-3 border rounded-4">
                                <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                                <div class="row align-items-center">
                                  <span class="img-wrap col-2 col-md-auto">
                                    <img src="img/price/sms_support.svg" alt="">
                                  </span>
                                  <div class="col-6 text-start mb-0 adds-on-content">
                                    <h5 class="m-0 fw-bold">SMS Support</h5>
                                    <span> Course & Compliance Notifications
                                    </span>
                                  </div>
                                  <div class="action-area col-4 ms-auto">
                                    <div class="action-block p-3 rounded-4">
                                      <strong class="d-block text-center">$9.00</strong>
                                      <span class="d-block text-center">Per month , Per 100 users</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="position-relative">
                              <input class="checkbox-design" type="checkbox" v-model="isCallLocationChecked"
                                @change="updateTotalPrice('Call Location', 4.0)">
                              <div class="adds-on-block p-3 border rounded-4">
                                <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                                <div class="row align-items-center">
                                  <span class="img-wrap col-2 col-md-auto">
                                    <img src="img/price/customer_service_skills.svg" alt="">
                                  </span>
                                  <div class="col-6 text-start mb-0 adds-on-content">
                                    <h5 class="m-0 fw-bold">Call Location</h5>
                                    <span>Quarterly service calls
                                    </span>
                                  </div>
                                  <div class="action-area col-4 ms-auto">
                                    <div class="action-block p-3 rounded-4">
                                      <strong class="d-block text-center">$4.00</strong>
                                      <span class="d-block text-center">Per month , Per Location</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="position-relative">
                              <input class="checkbox-design" type="checkbox" v-model="isSurveysChecked"
                                @change="updateTotalPrice('Surveys', 5.0)">
                              <div class="adds-on-block p-3 border rounded-4 position-relative">
                                <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                                <div class="row align-items-center">
                                  <span class="img-wrap col-2 col-md-auto">
                                    <img src="img/price/food-manager.svg" alt="">
                                  </span>
                                  <div class="col-6 text-start mb-0 adds-on-content">
                                    <h5 class="m-0 fw-bold">Surveys</h5>
                                    <span>
                                      Gather anonymous feedback
                                    </span>
                                  </div>
                                  <div class="action-area col-4 ms-auto">
                                    <div class="action-block p-3 rounded-4">
                                      <strong class="d-block text-center">$5.00</strong>
                                      <span class="d-block text-center">Per month</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="position-relative">
                              <input class="checkbox-design" type="checkbox" v-model="isOnboardingChecked"
                                @change="updateTotalPrice('Onboarding', 3.0)">
                              <div class="adds-on-block p-3 border rounded-4">
                                <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                                <div class="row align-items-center">
                                  <span class="img-wrap col-2 col-md-auto">
                                    <img src="img/price/human_trafficking.svg" alt="">
                                  </span>
                                  <div class="col-6 text-start mb-0 adds-on-content">
                                    <h5 class="m-0 fw-bold">Onboarding</h5>
                                    <span>Uploading team members</span>
                                  </div>
                                  <div class="action-area col-4 ms-auto">
                                    <div class="action-block p-3 rounded-4">
                                      <strong class="d-block text-center">$3.00</strong>
                                      <span class="d-block text-center">Per month , Per Location</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="payment-area p-4 rounded-4 mt-4">
                    <div class="d-flex justify-content-between">
                      <h5 class="fw-bold mb-3">Payment Details</h5>

                <el-tabs v-model="tabActiveName" @tab-click="handleClick">
                  <el-tab-pane label="Monthly" class="px-0" name="Monthly"></el-tab-pane>
                  <el-tab-pane label=" Annually" name="Annually"></el-tab-pane>
                </el-tabs>

    <!-- Tab Content -->



                    </div>
                    <div class="tab-content">
                      <div v-if="tabActiveName === 'Monthly'">
                        <div class="monthly-plan">
                    <ul class="border-top border-gray custom-border-color list-unstyled p-0 pt-3 m-0 mb-3">
                      <li class="mb-2 d-flex justify-content-between">
                        <span>No of locations</span>
                        <span>
                          {{ company.no_of_locations }}
                        </span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between">
                        <span>No of users</span>
                        <span>
                          {{ company.no_of_employees }}
                        </span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between">
                        <span>Total cost per month <span v-if="promoCodeApplied">(including discount)</span></span>
                        <span>
                          {{ formatPrice(total_cost) }}
                        </span>
                      </li>
                    </ul>
                    <ul class="border-top border-gray custom-border-color list-unstyled p-0 pt-3 m-0 mb-3">
                      <li  class="mb-2 d-flex justify-content-between" v-if="this.card.paymentType=='yearly'">
                        <span>Annual <span v-if="!specialCourseFlag">(10% Off)</span>:
                        </span>
                        <span>
                          {{ formatPrice(perYearCost) }}
                        </span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between">
                        <span>Monthly cost per location</span>
                        <span>
                          {{ formatPrice(discountperlocation) }}

                        </span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between">
                        <span>Monthly cost per user</span>
                        <span>
                          {{ formatPrice(discountperemp) }}
                        </span>
                      </li>
                    </ul>
                    <div class="discount-code-area border-top pt-3 custom-border-color" v-if="!promoCodeApplied">
                      <h6 class="fw-bold mb-3">Discount Code?</h6>
                      <form class="d-flex mb-3" role="search">
                        <input class="form-control me-3 rounded-4 py-2" v-model="promo_code" type="text"
                          placeholder="Promotional Code">
                        <button
                          class="create-account_btn btn btn-primary nav-link rounded-3 px-4 text-white fw-medium rounded-4 py-2"
                          type="submit" @click.prevent="applyPromoCode">APPLY</button>
                      </form>
                    </div>
                    <div class="d-flex justify-content-between mb-2" v-else>
                      <span class="promocode-applied m-0">Promotional Discount Applied ({{
                            discount_percentage
                          }}%)
                          </span>
                          <span style="cursor: pointer;" @click.prevent="submitLead()">
                            <i class="fas fa-times"></i>                           </span>
                    </div>
                    <div >
                      <!-- <form class="d-flex " role="search">
                        <button
                          class="create-account_btn btn btn-primary nav-link rounded-3 px-4 text-white fw-medium rounded-4 py-2"
                          type="submit" @click.prevent="submitLead()">
                          Re Estimate
                        </button>
                      </form> -->
                      <!-- <div class="col-md-12 my-2">
                        <span class="promocode-applied m-0">
                          </span>
                      </div> -->
                    </div>
                    <div>
                      <base-button type="primary" :disabled="active === 4" @click="CardEnable"
                        class="primary-orange border-0 w-100 py-2 rounded-3">Proceed to Payment</base-button>
                    </div>
                  </div>
                      </div>
                      <div v-if="tabActiveName === 'Annually'">
                        <div class="monthly-plan">
                    <ul class="border-top border-gray custom-border-color list-unstyled p-0 pt-3 m-0 mb-3">
                      <li class="mb-2 d-flex justify-content-between">
                        <span>No of locations</span>
                        <span>
                          {{ company.no_of_locations }}
                        </span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between">
                        <span>No of users</span>
                        <span>
                          {{ company.no_of_employees }}
                        </span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between">
                        <span>Total cost per month <span v-if="promoCodeApplied">(including discount)</span></span>
                        <span>
                          {{ formatPrice(total_cost) }}
                        </span>
                      </li>
                    </ul>
                    <ul class="border-top border-gray custom-border-color list-unstyled p-0 pt-3 m-0 mb-3">
                      <li class="mb-2 d-flex justify-content-between" v-if="this.card.paymentType=='yearly'">
                        <span>Annual <span v-if="!specialCourseFlag" >(10% Off)</span>:
                        </span>
                        <span>
                          {{ formatPrice(perYearCost) }}
                        </span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between">
                        <span>Monthly cost per location</span>
                        <span>
                          {{ formatPriceWithDiscount(discountperlocation) }}

                        </span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between">
                        <span>Monthly cost per user</span>
                        <span>
                          {{ formatPriceWithDiscount(discountperemp) }}
                        </span>
                      </li>
                    </ul>
                    <div class="discount-code-area border-top pt-3 custom-border-color" v-if="!promoCodeApplied">
                      <h6 class="fw-bold mb-3">Discount Code?</h6>
                      <form class="d-flex mb-3" role="search">
                        <input class="form-control me-3 rounded-4 py-2" v-model="promo_code" type="text"
                          placeholder="Promotional Code">
                        <button
                          class="create-account_btn btn btn-primary nav-link rounded-3 px-4 text-white fw-medium rounded-4 py-2"
                          type="submit" @click.prevent="applyPromoCode">APPLY</button>
                      </form>
                    </div>
                    <div class="d-flex justify-content-between mb-2" v-else>
                      <span class="promocode-applied m-0">Promotional Discount Applied ({{
                            discount_percentage
                          }}%)
                          </span>
                          <span style="cursor: pointer;" @click.prevent="submitLead()">
                            <i class="fas fa-times"></i>                           </span>
                    </div>
                    <div>
                      <base-button type="primary" :disabled="active === 4" @click="CardEnable"
                        class="primary-orange border-0 w-100 py-2 rounded-3">Proceed to Payment</base-button>
                    </div>
                  </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>

            </div>
            <div v-show="active === 3">

              <div class="row mb-5">
                <div class="col-xl-8">
                  <div class="mb-3">
                    <h4 class="mb-0 fw-bold">Billing Details</h4>
                    <p class="fs-5">Please Enter Your Company Informations.</p>

                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Address <span class="req"> *</span></label>
                      <base-input type="text" name="Address" placeholder="Address" rules="required"
                        v-model="company.address_1">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">City <span class="req"> *</span></label>
                      <base-input type="text" name="City" placeholder="city" rules="required" v-model="company.city">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">State <span class="req"> *</span></label>
                      <base-input type="text" name="State" placeholder="State" rules="required" v-model="company.state">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Zip code <span class="req"> *</span></label>
                      <base-input type="number" name="Zip code" placeholder="Zip" rules="required"
                        v-model="company.zip">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <base-input type="text" name="Website" placeholder="Website" v-model="company.website">
                        <template #label>
                          <label class="form-control-label fw-bold">Website</label>
                        </template>
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Company Logo</label>
                      <form>
                        <file-input v-on:change="onImageChange"></file-input>
                      </form>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Company Type </label><br />
                      <el-select class="mr-3" style="width: 100%" placeholder="Select Company Type" rules="required"
                        v-model="company.company_type">
                        <el-option v-for="(option, index) in company_types" class="select-primary" :value="option.value"
                          :label="option.label" :key="'company_type_' + index">
                        </el-option>
                      </el-select>
                    </div>

                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Password <span class="req"> *</span></label>
                      <base-input :type="passwordFieldType" v-if="!(company_id !== '')" name="Password"
                        placeholder="Password" rules="required" v-model="company.password">
                      </base-input>
                    </div>
                  </div>
                  <div class="mb-3">
                    <h4 class="mb-0 fw-bold">Payment</h4>
                    <p class="fs-5">All transactions are secure and encrypted.</p>
                  </div>
                  <div class=" border rounded-4 mb-3" :class="{ 'payment-block-wrap': paymentType == 'creditCard' }">
                    <div :class="{ 'payment-header': paymentType == 'creditCard' }"
                      class="border-bottom d-flex justify-content-between align-items-center p-3 mb-3">
                      <div class="left d-flex align-items-center" style="cursor: pointer;">
                        <input type="radio" id="creditCard" name="creditCard" value="creditCard" v-model="paymentType">
                        <h6 class="fw-bold m-0 pl-2">Credit card</h6>
                      </div>
                      <div class="right">
                        <ul class="list-unstyled m-0 p-0 d-flex gap-2">
                          <li><img src="img/price/payment1.svg" alt="" /> </li>
                          <li><img src="img/price/payment2.svg" alt="" /> </li>
                          <li><img src="img/price/payment3.svg" alt="" /> </li>
                        </ul>
                      </div>
                    </div>
                    <div class="payment-body p-3 mb-3" v-show="paymentType === 'creditCard'">
                      <div class="row">
                        <div v-if="card.paymentType == 'monthly' && freeTrail != 'true'" class="col-6 col-md-4">
                          <!-- <input type="radio" name="paymenttype" value="monthly" v-model="card.paymentType" /> -->
                          Monthly ( {{ formatPrice(total_cost) }} )
                        </div>
                        <!-- v-if="!specialCourseFlag" -->
                        <div class="col-6 col-md-4" v-if="card.paymentType == 'yearly' && freeTrail != 'true'">
                          <!-- <input type="radio" value="yearly" name="paymenttype" v-model="card.paymentType" /> -->
                          Yearly ( {{ formatPrice(perYearCost) }} )
                        </div>
                        <div class="col-6 col-md-4" v-if="freeTrail == 'true'">
                          <!-- <input type="radio" value="yearly" name="paymenttype" v-model="card.paymentType" /> -->
                          Free Trial ($ {{ 0.00 }} )
                        </div>
                        <div class="col-12">
                          <div class="my-3">
                            <input type="text" class="form-control rounded-4 " placeholder="Name on card">
                          </div>
                        </div>
                        <span class="mb-3">
                          <!-- Stripe Element   -->
                          <stripe-element-payment ref="paymentRef" :pk="publicKey" :elements-options="elementsOptions"
                            :confirm-params="confirmParams"
                            v-if="paymentType === 'creditCard' && elementsOptions.clientSecret" />
                          <!-- End Stripe Element   -->
                        </span>
                        <base-button type="primary" :disabled="active === 4" @click.prevent="showAgreement()"
                        class="primary-orange border-0 w-25 py-2 rounded-3 mx-auto">Pay Now</base-button>

                      </div>
                    </div>
                  </div>
                  <div v-if="freeTrail != 'true'" class="border rounded-4 mb-3"
                    :class="{ 'payment-block-wrap': paymentType == 'ach' }">
                    <div :class="{ 'payment-header': paymentType == 'ach' }"
                      class="border-bottom d-flex justify-content-between align-items-center p-3 mb-3">
                      <div class="left d-flex align-items-center">
                        <input type="radio" id="Ach" name="creditCard" value="ach" v-model="paymentType">
                        <h6 class="fw-bold m-0 pl-2">ACH</h6>
                      </div>
                    </div>
                    <div class="payment-body p-3 mb-3" v-if="paymentType === 'ach'">
                      <div class="row mb-3">
                        <div class="col-6 col-md-4">
                          <input type="radio" name="creditCard" value="monthly"
                            v-model="paymentObjects.paymentMethod" />
                          Monthly ({{ formatPrice(total_cost) }})
                        </div>
                        <div class="col-6 col-md-4">
                          <input type="radio" value="yearly" name="paymenttype"
                            v-model="paymentObjects.paymentMethod" />
                          Yearly ({{ formatPrice(perYearCost) }})
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="form-control-label">Transit Number / ABA Routing Number
                            <span class="req"> *</span></label>
                          <base-input type="text" name="transit_number"
                            placeholder="Transit Number / ABA Routing Number" rules="required"
                            v-model="ACH.transit_number">
                          </base-input>
                        </div>
                        <div class="col-md-6">
                          <label class="form-control-label">Account Number <span class="req"> *</span></label>
                          <base-input type="text" name="account_number" placeholder="Account Number" rules="required"
                            v-model="ACH.account_number">
                          </base-input>
                        </div>
                        <div class="col-md-6">
                          <label class="form-control-label">Financial Institution <span class="req"> *</span></label>
                          <base-input type="text" name="financial_instituation" placeholder="Financial Institution"
                            rules="required" v-model="ACH.financial_instituation">
                          </base-input>
                        </div>
                        <div class="col-md-6">
                          <label class="form-control-label">Address <span class="req"> *</span></label>
                          <base-input type="text" name="Address" placeholder="Address" rules="required"
                            v-model="company.address_1">
                          </base-input>
                        </div>
                        <div class="col-md-6">
                          <label class="form-control-label">City <span class="req"> *</span></label>
                          <base-input type="text" name="City" placeholder="city" rules="required"
                            v-model="company.city">
                          </base-input>
                        </div>
                        <div class="col-md-6">
                          <label class="form-control-label">State <span class="req"> *</span></label>
                          <base-input type="text" name="State" placeholder="State" rules="required"
                            v-model="company.state">
                          </base-input>
                        </div>
                        <div class="col-md-6">
                          <label class="form-control-label">Zip code <span class="req"> *</span></label>
                          <base-input type="number" name="Zip code" placeholder="Zip" rules="required"
                            v-model="company.zip">
                          </base-input>
                        </div>
                        <div class="col-md-6">
                          <label class="form-control-label">Branch <span class="req"> *</span></label>
                          <base-input type="text" name="branch" placeholder="Branch" rules="required"
                            v-model="ACH.branch">
                          </base-input>
                        </div>
                        <base-button type="primary" :disabled="active === 4" @click.prevent="showAgreement()"
                        class="primary-orange border-0 w-25 mx-auto py-2 rounded-3">Pay Now</base-button>
                      </div>
                    </div>
                  </div>
                  <div v-if="freeTrail != 'true'" class="border rounded-4 mb-3"
                    :class="{ 'payment-block-wrap': paymentType == 'invoice' }">
                    <div :class="{ 'payment-header': paymentType == 'invoice' }"
                      class="border-bottom d-flex justify-content-between align-items-center p-3 mb-3">
                      <div class="left d-flex align-items-center">
                        <input type="radio" id="invoice" name="creditCard" value="invoice" v-model="paymentType">
                        <h6 class="fw-bold m-0 pl-2" for="invoice">Payment using Invoice (Additional 10%)
                        </h6>
                      </div>

                    </div>
                    <div class="payment-body p-3 mb-3" v-if="paymentType === 'invoice'">
                      <div class="row mb-2">
                        <div class="col-5">
                          <input type="radio" name="paymenttype" value="monthly"
                            v-model="paymentObjects.paymentMethod" />
                          Monthly ({{ formatPrice(adjustedMonthlyCost) }})
                        </div>
                        <div class="col-5">
                          <input type="radio" value="yearly" name="paymenttype"
                            v-model="paymentObjects.paymentMethod" />
                          Yearly ({{ formatPrice(adjustedYearlyCost) }})
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label class="form-control-label">Name of Recipient <span class="req"> *</span></label>
                          <base-input type="text" name="invoice_name" placeholder="Invoice Name" rules="required"
                            v-model="Invoice.invoice_name">
                          </base-input>
                        </div>
                        <div class="col-md-12">
                          <label class="form-control-label">2. Recipient email address ( email1@gmail.com,
                            email2@gmail.com)
                            <span class="req"> *</span></label>
                          <base-input type="text" name="invoice_emails" placeholder="Invoice Emails" rules="required"
                            v-model="Invoice.invoice_emails">
                          </base-input>
                        </div>
                        <div class="col-md-12">
                          <label class="form-control-label">Phone <span class="req"> *</span></label>
                          <base-input name="Phone Number" placeholder="Phone" rules="required"
                            v-model="company.telephone_no" @input="acceptNumber">
                          </base-input>
                        </div>
                        <base-button type="primary" :disabled="active === 4" @click.prevent="showAgreement()"
                        class="primary-orange border-0 w-25 mx-auto py-2 rounded-3">Pay Now</base-button>
                      </div>
                    </div>
                  </div>




                  <!-- <div class="border rounded-4 mb-3">
                    <div class="payment-header d-flex justify-content-between align-items-center p-3">
                      <div class="left d-flex align-items-center">
                        <input type="radio">
                        <h6 class="fw-bold m-0 pl-2">Apple Pay</h6>
                      </div>
                      <div class="right">
                        <ul class="list-unstyled m-0 p-0 d-flex gap-2">
                          <li><img src="img/price/apple_pay.svg" alt="" /> </li>
                        </ul>
                      </div>
                    </div>
                    <div class="payment-body p-3 mb-3 d-none">
                      <div class="row">
                        <div class="col-6">
                          <div class="input-group mb-3">
                            <input type="text" class="form-control rounded-4 rounded-end-0 border-right-0"
                              placeholder="Username" aria-label="Username">
                            <span class="input-group-text rounded-4 rounded-start-0 bg-transparent">
                              <svg width="19" height="18" viewBox="0 0 19 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.3">
                                  <path d="M5 7.5V6C5 3.5175 5.75 1.5 9.5 1.5C13.25 1.5 14 3.5175 14 6V7.5"
                                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                  <path
                                    d="M9.5 13.875C10.5355 13.875 11.375 13.0355 11.375 12C11.375 10.9645 10.5355 10.125 9.5 10.125C8.46447 10.125 7.625 10.9645 7.625 12C7.625 13.0355 8.46447 13.875 9.5 13.875Z"
                                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                  <path
                                    d="M13.25 16.5H5.75C2.75 16.5 2 15.75 2 12.75V11.25C2 8.25 2.75 7.5 5.75 7.5H13.25C16.25 7.5 17 8.25 17 11.25V12.75C17 15.75 16.25 16.5 13.25 16.5Z"
                                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                </g>
                              </svg>
                            </span>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="mb-3">
                            <input type="date" class="form-control rounded-4 " placeholder="MM/YY">
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group mb-3">
                            <input type="text" class="form-control rounded-4 rounded-end-0 border-right-0"
                              placeholder="Security code" aria-label="Username">
                            <span class="input-group-text rounded-4 rounded-start-0 bg-transparent">
                              <svg width="19" height="18" viewBox="0 0 19 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M9.50734 17.055C9.03484 17.055 8.56984 16.8975 8.20234 16.5825L7.01734 15.57C6.89734 15.465 6.59734 15.36 6.43984 15.36H5.12734C4.01734 15.36 3.11734 14.46 3.11734 13.35V12.0675C3.11734 11.91 3.01234 11.61 2.91484 11.4975L1.89484 10.2975C1.27984 9.56999 1.27984 8.42999 1.89484 7.69499L2.91484 6.49499C3.01234 6.38249 3.11734 6.08249 3.11734 5.92499V4.64999C3.11734 3.53999 4.01734 2.63999 5.12734 2.63999H6.42484C6.58234 2.63999 6.87484 2.52749 7.00234 2.42249L8.18734 1.40999C8.92234 0.78749 10.0698 0.78749 10.8048 1.40999L11.9898 2.42249C12.1098 2.52749 12.4173 2.63249 12.5748 2.63249H13.8498C14.9598 2.63249 15.8598 3.53249 15.8598 4.64249V5.91749C15.8598 6.07499 15.9723 6.36749 16.0773 6.49499L17.0898 7.67999C17.7198 8.42249 17.7123 9.56999 17.0898 10.2975L16.0773 11.4825C15.9723 11.61 15.8673 11.9025 15.8673 12.06V13.335C15.8673 14.445 14.9673 15.345 13.8573 15.345H12.5823C12.4248 15.345 12.1323 15.4575 11.9973 15.5625L10.8123 16.575C10.4448 16.8975 9.97234 17.055 9.50734 17.055ZM5.12734 3.76499C4.63984 3.76499 4.24234 4.16249 4.24234 4.64999V5.92499C4.24234 6.35249 4.04734 6.89249 3.76984 7.22249L2.74984 8.42249C2.49484 8.72999 2.49484 9.26999 2.74984 9.56999L3.76234 10.7625C4.03234 11.07 4.23484 11.6325 4.23484 12.06V13.3425C4.23484 13.83 4.63234 14.2275 5.11984 14.2275H6.42484C6.84484 14.2275 7.39984 14.43 7.72984 14.7075L8.92234 15.7275C9.22984 15.99 9.76984 15.99 10.0773 15.7275L11.2623 14.715C11.5998 14.43 12.1473 14.235 12.5673 14.235H13.8423C14.3298 14.235 14.7273 13.8375 14.7273 13.35V12.075C14.7273 11.655 14.9298 11.1075 15.2073 10.77L16.2273 9.57749C16.4898 9.26999 16.4898 8.72999 16.2273 8.42249L15.2148 7.23749C14.9298 6.89999 14.7348 6.35249 14.7348 5.93249V4.64999C14.7348 4.16249 14.3373 3.76499 13.8498 3.76499H12.5748C12.1473 3.76499 11.5923 3.56249 11.2623 3.28499L10.0698 2.26499C9.76234 2.00249 9.22984 2.00249 8.91484 2.26499L7.73734 3.28499C7.39984 3.56249 6.85234 3.76499 6.42484 3.76499H5.12734Z"
                                  fill="#292D32" />
                                <path
                                  d="M9.5 12.6525C9.0875 12.6525 8.75 12.315 8.75 11.9025C8.75 11.49 9.08 11.1525 9.5 11.1525C9.9125 11.1525 10.25 11.49 10.25 11.9025C10.25 12.315 9.92 12.6525 9.5 12.6525Z"
                                  fill="#292D32" />
                                <path
                                  d="M9.5 10.29C9.1925 10.29 8.9375 10.035 8.9375 9.72747V6.09747C8.9375 5.78997 9.1925 5.53497 9.5 5.53497C9.8075 5.53497 10.0625 5.78997 10.0625 6.09747V9.71997C10.0625 10.035 9.815 10.29 9.5 10.29Z"
                                  fill="#292D32" />
                              </svg>

                            </span>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="mb-3">
                            <input type="text" class="form-control rounded-4 " placeholder="Name on card">
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-check mb-0">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                            <label class="form-check-label" for="flexCheckChecked">
                              Use shipping address as billing address
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <h6 class="fw-bold mb-2">Remember me</h6>
                  <div class="form-check mb-0">
                    <input class="form-check-input" type="checkbox" value="" checked>
                    <label class="form-check-label">
                      Save my information for a faster checkout
                    </label>
                  </div> -->

                </div>
                <div class="col-xl-4">
                  <div class="payment-area p-4 rounded-4 mt-4">
                    <h5 class="fw-bold mb-3">Payment Details</h5>
                    <ul class="border-top border-gray custom-border-color list-unstyled p-0 pt-3 m-0 mb-3">
                      <li class="mb-2 d-flex justify-content-between">
                        <span>No of locations</span>
                        <span>
                          {{ company.no_of_locations }}
                        </span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between">
                        <span>No of users</span>
                        <span>
                          {{ company.no_of_employees }}
                        </span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between">
                        <span>Total cost per month <span v-if="promoCodeApplied">(including discount)</span></span>
                        <span>
                          {{ formatPrice(total_cost) }}
                        </span>
                      </li>
                    </ul>
                    <ul class="border-top border-gray custom-border-color list-unstyled p-0 pt-3 m-0 mb-3">
                      <li class="mb-2 d-flex justify-content-between" v-if="this.card.paymentType=='yearly'">
                        <span>Annual <span v-if="!specialCourseFlag">(10% Off)</span>:
                        </span>
                        <span>
                          {{ formatPrice(perYearCost) }}
                        </span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between">
                        <span>Monthly cost per location</span>
                        <span>
                          {{ formatPrice(discountperlocation) }}

                        </span>
                      </li>
                      <li class="mb-2 d-flex justify-content-between">
                        <span>Monthly cost per user</span>
                        <span>
                          {{ formatPrice(discountperemp) }}
                        </span>
                      </li>
                    </ul>
                    <div>
                      <base-button type="primary" :disabled="active === 4" @click.prevent="showAgreement()"
                        class="primary-orange border-0 w-100 py-2 rounded-3">Pay Now</base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="active === 4"
              class="thankyou-wrap border p-5 rounded-4 mb-5 d-flex flex-column align-items-center">
              <img width="110" class="mb-3" src="img/price/thankyou_tick.svg" alt="">
              <h5 class="fw-bold mb-3">Payment Confirmed</h5>
              <p class="text-center">Congratulations! 🎉 Your payment has been successfully processed. Thank you </p>
              <router-link to="/login">
                <base-button type="primary" class="primary-orange border-0 px-5 py-2 fw-bold">Go to Login</base-button>
              </router-link>
            </div>
          </div>
        </div>

      </section>
      <Footer />



      <modal :show.sync="agreementModal" class="user-modal">
        <h3 slot="header">Service Activation Agreement</h3>
        <form>
          <div class="agreement-content">
            <agreement type="company"></agreement>
          </div>
          <div class="text-center mt-2">
            <!-- <base-button type="success" @click.prevent="finalCreateAccount">
            I Agree
          </base-button> -->
            <base-button class="btn base-button primary-orange border-0 py-2 rounded-3 btn-primary"
              @click.prevent="moveNext()">
              I Agree
            </base-button>
            <base-button class="btn base-button primary-orange border-0 py-2 rounded-3 btn-primary"
              @click.prevent="cancelAgreement">
              Cancel
            </base-button>
          </div>
        </form>
      </modal>

      <modal :show.sync="showusermodel">
        <h4 slot="header" style="color: #444c57" class="title title-up text-center">
          {{ specialCourseName }}
        </h4>

        <div class="col-md-8">
          <base-input label="How many users will be taking this course:" type="text" name="# Users"
            :key="specialCourseName" v-model="users"></base-input>
        </div>
        <div class="col-md-6">
          <base-button size="md" class="custom-btn right"
            style="background-color: #e66412; color: white; border-color: #e66412"
            @click.prevent="updateUsers()">Done</base-button>
        </div>
      </modal>
      <!-- ACH inputs  -->
      <modal :show.sync="achModal">
        <div class="d-flex align-items-center justify-content-between">
          <h4 slot="header" style="color: #444c57" class="title title-up text-center">
            Payment using ACH
          </h4>
          <button type="button" @click.prevent="paymenrModelOpen()" class="btn"
            style="background-color: #e66412; color: white">
            Cancel
          </button>
        </div>
        <div class="container">
          <div class="row mb-3">
            <div class="col-6 col-md-4">
              <input type="radio" name="paymenttype" value="monthly" v-model="paymentObjects.paymentMethod" />
              Monthly ({{ formatPrice(total_cost) }})
            </div>
            <div class="col-6 col-md-4">
              <input type="radio" value="yearly" name="paymenttype" v-model="paymentObjects.paymentMethod" />
              Yearly ({{ formatPrice(perYearCost) }})
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="form-control-label">Transit Number / ABA Routing Number
                <span class="req"> *</span></label>
              <base-input type="text" name="transit_number" placeholder="Transit Number / ABA Routing Number"
                rules="required" v-model="ACH.transit_number">
              </base-input>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Account Number <span class="req"> *</span></label>
              <base-input type="text" name="account_number" placeholder="Account Number" rules="required"
                v-model="ACH.account_number">
              </base-input>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Financial Institution <span class="req"> *</span></label>
              <base-input type="text" name="financial_instituation" placeholder="Financial Institution" rules="required"
                v-model="ACH.financial_instituation">
              </base-input>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Address <span class="req"> *</span></label>
              <base-input type="text" name="Address" placeholder="Address" rules="required" v-model="company.address_1">
              </base-input>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">City <span class="req"> *</span></label>
              <base-input type="text" name="City" placeholder="city" rules="required" v-model="company.city">
              </base-input>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">State <span class="req"> *</span></label>
              <base-input type="text" name="State" placeholder="State" rules="required" v-model="company.state">
              </base-input>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Zip code <span class="req"> *</span></label>
              <base-input type="number" name="Zip code" placeholder="Zip" rules="required" v-model="company.zip">
              </base-input>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Branch <span class="req"> *</span></label>
              <base-input type="text" name="branch" placeholder="Branch" rules="required" v-model="ACH.branch">
              </base-input>
            </div>
            <base-button class="custom-btn" @click.prevent="ACHpayment()" block style="
              background-color: #e66412;
              color: white;
              border-color: #e66412;
            ">Create Account</base-button>
          </div>
        </div>
      </modal>
      <!-- Invoice inputs  -->
      <modal :show.sync="invoiceModal">
        <div class="d-flex align-items-center justify-content-between">
          <h4 slot="header" style="color: #444c57" class="title title-up">
            Payment using Invoice (Additional 10%)
          </h4>
          <button type="button" @click.prevent="paymenrModelOpen()" class="btn"
            style="background-color: #e66412; color: white">
            Cancel
          </button>
        </div>
        <div class="container">
          <div class="row mb-2">
            <div class="col-5">
              <input type="radio" name="paymenttype" value="monthly" v-model="paymentObjects.paymentMethod" />
              Monthly ({{ formatPrice(adjustedMonthlyCost) }})
            </div>
            <div class="col-5">
              <input type="radio" value="yearly" name="paymenttype" v-model="paymentObjects.paymentMethod" />
              Yearly ({{ formatPrice(adjustedYearlyCost) }})
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-control-label">Name of Recipient <span class="req"> *</span></label>
              <base-input type="text" name="invoice_name" placeholder="Invoice Name" rules="required"
                v-model="Invoice.invoice_name">
              </base-input>
            </div>
            <div class="col-md-12">
              <label class="form-control-label">2. Recipient email address ( email1@gmail.com, email2@gmail.com)
                <span class="req"> *</span></label>
              <base-input type="text" name="invoice_emails" placeholder="Invoice Emails" rules="required"
                v-model="Invoice.invoice_emails">
              </base-input>
            </div>
            <div class="col-md-12">
              <label class="form-control-label">Phone <span class="req"> *</span></label>
              <base-input name="Phone Number" placeholder="Phone" rules="required" v-model="company.telephone_no"
                @input="acceptNumber">
              </base-input>
            </div>

            <base-button class="custom-btn" @click.prevent="Invoicepayment()" block style="
              background-color: #e66412;
              color: white;
              border-color: #e66412;
            ">Create Account</base-button>
            <!-- <div class="col-md-12 mt-4 mb-4 text-end">
                    <button @click.prevent="ACHpayment()" style="border-radius:20px !important;color:white;background-color: #E66412;" class="btn">Create Account</button>
                  </div> -->
          </div>
        </div>
      </modal>
      <modal :show.sync="paymentModalMessage">
        <h1 class="text-capitalize text-center text-strong m-0 payment-modal-text text-danger font-weight-500">
          Your payment is processing...
        </h1>
      </modal>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import FileInput from "@/components/Inputs/FileInput";
import Header from '../Common/Header.vue';
import Footer from '../Common/Footer.vue';
import { Option, OptionGroup, Select, Table, TableColumn, Steps, Step , Tabs , TabPane } from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
// import CreditCard from "@/views/Widgets/CreditCard";
// import { StripeElementCard } from '@vue-stripe/vue-stripe';
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import Agreement from "./Agreement.vue";
import { Dynamic } from "../../wl";
import VueGtag from "vue-gtag";
import NewSignupFooter from "../Components/NewSignupFooter.vue";

Vue.use(VueGtag, {
  config: { id: "AW-754017760" },
});
export default {
  name: "register",
  // props: {
  //   plan,
  //   type
  // },
  components: {
    Agreement,
    FileInput,
    // CreditCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Table.name]: Table,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,

    [TableColumn.name]: TableColumn,
    NewSignupFooter,
    Header,
    Footer,
    [Steps.name]: Steps,
    [Step.name]: Step,
    // StripeElementCard,
    StripeElementPayment

  },
  data() {
    return {
      hot_des: "Includes the required Food and Alcohol compliance courses only.",
      siz_des: "Includes the required Food and Alcohol compliance courses, Sexual Harassment, 26 course safety program.",
      sco_des: "Includes Food and Alcohol compliance courses, Sexual Harassment, and our entire library.",
      plan: "",
      planName: "Courses",
      publicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      postalCode: true,
      card: {
        cardNumber: "",
        expire: "",
        paymentType: "monthly",
        address: "",
        state: "",
        city: "",
        zip: "",
        token: null,
      },
      active: 1,
      showModalOfCourses: false,
      paymentObjects: {
        paymentMethod: "yearly",
        transaction_amount: "",
      },
      invoiceModal: false,
      achModal: false,
      ACH: {
        account_holder_name: "",
        transit_number: "",
        account_number: "",
        financial_instituation: "",
        branch: "",
      },
      Invoice: {
        invoice_name: "",
        invoice_emails: [],
      },
      specialCourseQueue: [], // Initialize an empty queue if not already defined
      paymentType: "creditCard",
      paymentOptionModal: false,
      creditCardOption: false,
      aCHOption: false,
      invoiceOption: false,
      searchAdditionalCourse: "",
      showInputModal: false,
      isCallLocationChecked: false, // To check if Call Location is selected
      isSMSChecked: false, // To check if SMS is selected
      isFoodChecked: false,
      isHumanChecked: false,
      isSexualChecked: false,
      is24hourSafetyChecked: false,
      isUploadTrainingChecked: false,
      isOnboardingChecked: false, // To check if Onboarding is selected
      isSurveysChecked: false, // To check if Surveys is selected
      isEditing: {},
      folder2stockLimit: 10,
      folder2stockLimitShow: 5,
      folder3stockLimit: 25,
      folder3stockLimitShow: 20,
      showFolderLimit: "",
      limitstart: 0,
      selectedFolderLimit: "",
      openFolder1: false,
      openFolder2: false,
      openFolder3: false,
      openFolder4: false,
      freeTrailCourses: [21, 55, 68, 53, 65],
      sizzlingCourses: [
        122, 532, 23, 103, 104, 21, 32, 40, 118, 283, 119, 47, 48, 56, 57, 101,
        59, 280, 260, 77, 295, 82, 83, 84, 86, 120, 87, 121, 90, 68, 64
      ],
      hotCourses:[
        122, 532 , 23, 103, 104, 21, 32, 40, 118, 283, 119,
      ],
      folder1Courses: [123, 122, 23],
      folder2Courses: [123, 122, 23, 103, 51], // Have upto 5 stock courses
      folder3Courses: [123, 122, 23, 103, 51], // Have upto 20 stock courses
      folder4Courses: [], // Unlimited courses ...
      preselectedCourses: [],
      additionalCourses: [],
      additionalCoursesCopy: [],
      defaultSelectedCourses: [],
      selectedCourses: [],
      showForm: true,
      loading: false,
      paymentModalMessage: false,
      users: null,
      courseSelectionFocused: false,
      focused: "blue-theme",
      formData: {
        company_name: "",
        first_name: "",
        last_name: "",
        company_location_num: "",
        company_employee_num: "",
        company_address_1: "",
        company_address_2: "",
        company_phone: "",
        company_email: "",
        company_zip: "",
        website: "",
        company_type: "",
        username: "",
        parent_id: "",
        image: "",
        company_city: "",
        company_state: "",
        company_password: "",
      },
      value_true: true,
      baseUrl: this.$baseUrl,
      complete: false,
      hot_user: "",
      hot_token: "",
      config: "",
      company_id: "",
      creatAccountClicked: false,
      company_types: [],
      parent_companies: [],
      payment_screen: false,
      image: "",
      sub_total: 0,
      special_courses: [],
      company: {
        first_name: "",
        last_name: "",
        company_type: "",
        parent_company: "",
        name: "",
        administrator: "",
        no_of_locations: null,
        no_of_employees: null,
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zip: "",
        logo: "",
        telephone_no: "",
        email: "",
        password: "",
      },

      search: "",
      estimate: false,
      showPass: false,
      contactUsModal: false,
      agreementModal: false,
      passwordFieldType: "password",
      courses: [],
      services: [],
      contact: {
        name: "",
        phone: "",
        email: "",
        message: "",
      },
      siteName: "",
      infoEmail: "",
      lead_id: "",
      checked_courses: [],
      companyEstimateDetailModel: false,
      showCompanyinformation: false,
      showPricePlan: false,
      total_cost: 0,
      total_discount: "",
      specialCourseFlag: "",
      special_courses_with_users: "",
      discountperlocation: 0,
      perYearCost: 0,
      discount_msg: "",
      employees_count: "",
      locations_count: "",
      discountperemp: 0,
      discount_per: "",
      specialCourses: [],
      course: "",
      role: "",
      showPaymentOption: false,
      spacialCourseFlag: [],
      selectedRows: [],
      selectedUsers: [],
      olde: "",
      showusermodel: false,
      specialCourseId: "",
      specialCourseName: "",
      old_length: "",
      specialCoursescopy: [],
      cardProcessing: false,
      promo_code: "",
      appliedText: "",
      showPromoCodeOption: false,
      promoCodeApplied: false,
      course_cost_monthly: "",
      yearlyAmount: "",
      discount_percentage: "",
      isContinueButtonDisabled: true,
      enablePaymentButton: false,
      elementsOptions: {
        appearance: {
          theme: "stripe", // Default Stripe theme
        }, // Custom UI settings
        clientSecret: "", // Will be set dynamically
      },
      confirmParams: {
        return_url: 'http://localhost:3000', // Redirect after payment
      },
      freeTrail: false,
      tabActiveName:"Monthly",
      paymentSuccess:false,
    };
  },
  mounted() {
    this.plan = this.$route.query.plan || "";
    if (this.plan == "sizzling") {
      this.planName = "Sizzling Plan";
    } else if (this.plan == "hot") {
      this.planName = "Hot Plan";
    } else if (this.plan == 'scorching') {
      this.planName = "Scorching Plan";
    }

    this.card.paymentType = this.$route.query.type || "monthly";
    this.tabActiveName = this.$route.query.type == 'yearly' ? 'Annually' : 'Monthly'
    this.freeTrail = this.$route.query.freeTrial || false;

    if (this.freeTrail == "true") {
      this.freeTrailCourses = [21, 55, 68, 53, 65];
    }

    // await this.generatePaymentIntent();
    this.siteName = Dynamic.SITE_NAME;
    this.infoEmail = Dynamic.INFO_EMAIL;
    this.$gtag.event("Company signup", { method: "Google" });
    history.pushState(null, null, window.location.href);
    window.onpopstate = this.handleBackButton;
  },
  created() {
    localStorage.removeItem("fname");
    localStorage.removeItem("lname");
    localStorage.removeItem("email");
    localStorage.removeItem("courses");
    if (this.$route.query.role) {
      this.role = this.$route.query.role;
    }
    this.$http.post("company/company_dropdown_data", {}).then((resp) => {
      for (let type of resp.data.companytype) {
        let obj = {
          label: type.type,
          value: type.id,
        };
        this.company_types.push(obj);
      }
    });
    this.$http.get("user/discountRules").then((resp) => {
      for (let course of resp.data.courses) {
        let obj = {
          checked: false,
          id: course.id,
          course_name: course.name,
          course_type: course.course_type,
          cost: course.cost,
          discounted: course.is_discounted_course,
        };
        this.courses.push(obj);
      }

      this.specialCourses = [];

      for (let course of resp.data.non_discounted_courses) {
        let obj = {
          checked: false,
          id: course.id,
          course_name: course.name,
          course_type: course.course_type,
          cost: course.cost,
          discounted: course.is_discounted_course,
          discounted_comment: course.discounted_course_comment,
          users: 0,
        };
        this.specialCourses.push(obj);
      }
      this.specialCoursescopy = JSON.parse(JSON.stringify(this.specialCourses));

      for (let service of resp.data.services) {
        let obj = {
          id: service.id,
          name: service.name,
          price: service.price,
          frequency: service.frequency,
        };
        this.services.push(obj);
      }

      // Handling selected courses based on plan and free trial status
    // Handling selected courses based on plan and free trial status
    if (this.plan === "scorching") {
    if (this.freeTrail) {
      // Free trial courses go into selectedCourses
      this.selectedCourses = this.courses.filter((course) =>
        this.freeTrailCourses.includes(course.id)
      );

      // Remaining courses go into both planCourses & selectedCourses
      this.planCourses = this.courses.filter(
        (course) => !this.freeTrailCourses.includes(course.id)
      );

      this.selectedCourses = [...this.selectedCourses, ...this.planCourses];
    } else {
      // If free trial is not active, all courses go into both planCourses & selectedCourses
      this.selectedCourses = [...this.courses];
      this.planCourses = [...this.courses];
    }

    // No additional courses for scorching plan
    this.additionalCourses = [];
    this.additionalCoursesCopy = [];
  }  else {
        let planCourses = [];

        if (this.plan === "hot") {
          planCourses = this.hotCourses;
        } else if (this.plan === "sizzling") {
          planCourses = this.sizzlingCourses;
        }

        if (this.freeTrail) {
          // If free trial is enabled, separate courses into free trial & plan courses
          this.selectedCourses = this.courses.filter((course) =>
            this.freeTrailCourses.includes(course.id)
          );

          // Remove free trial courses from plan courses
          const filteredPlanCourses = planCourses.filter(
            (courseId) => !this.freeTrailCourses.includes(courseId)
          );

          // Add the remaining plan courses
          this.selectedCourses = [
            ...this.selectedCourses,
            ...this.courses.filter((course) => filteredPlanCourses.includes(course.id)),
          ];
        } else {
          this.freeTrailCourses = []; // Reset free trial courses if not active
          this.selectedCourses = this.courses.filter((course) =>
            planCourses.includes(course.id)
          );
        }
      }


      // Set defaultSelectedCourses to match selectedCourses
      this.defaultSelectedCourses = [...this.selectedCourses];

      // Populate additionalCourses with remaining courses (only for non-scorching plans)
      if (this.plan !== "scorching") {
        this.additionalCourses = this.courses.filter(
          (course) => !this.selectedCourses.some((sel) => sel.id === course.id)
        );

        this.additionalCourses.sort((a, b) => {
          return a.course_name.localeCompare(b.course_name);
        });

        this.additionalCoursesCopy = [...this.additionalCourses];
      } else {
        this.additionalCourses = [];
        this.additionalCoursesCopy = [];
      }
    });

  },
  computed: {
    filteredAdditionalCourses() {
      // Dynamically filter based on search input
      return this.additionalCoursesCopy.filter((course) =>
        course.course_name
          .toLowerCase()
          .includes(this.searchAdditionalCourse.toLowerCase())
      );
    },
    freeTrialCourses() {
      return this.selectedCourses.filter((course) =>
        this.freeTrailCourses.includes(course.id)
      );
    },
    otherCourses() {
      return this.selectedCourses.filter(
        (course) => !this.freeTrailCourses.includes(course.id)
      );
    },
    // Add 10% surcharge for monthly cost if Invoice type is selected
    adjustedMonthlyCost() {
      return this.paymentType === "invoice"
        ? this.total_cost * 1.1 // Add 10% surcharge
        : this.total_cost; // Default price
    },
    // Add 10% surcharge for yearly cost if Invoice type is selected
    adjustedYearlyCost() {
      return this.paymentType === "invoice"
        ? this.perYearCost * 1.1 // Add 10% surcharge
        : this.perYearCost; // Default price
    },
  },
  methods: {
    async CardEnable() {
      this.nextStep();
      this.paymentType = "creditCard"; // Set payment type first
      await this.generatePaymentIntent(); // Then generate payment intent
    },

    async pay() {
      if (!this.$refs.paymentRef) {
        console.error("Stripe payment reference not found");
        return;
      }

      const stripe = this.$refs.paymentRef.stripe;
      const elements = this.$refs.paymentRef.elements;

      if (!stripe || !elements) {
        console.error("Stripe or Elements not initialized");
        return;
      }

      try {
        if (this.freeTrail) {
          const { error, setupIntent } = await stripe.confirmSetup({
            elements,
            confirmParams: {
              return_url: '', // No redirect needed
            },
            redirect: 'if_required',
          });
          if (error) {
            console.error('Setup failed:', error.message);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.message,
            });
          } else if (setupIntent && setupIntent.status === 'succeeded') {
            // Extract Payment Method ID from setupIntent
            const paymentMethodId = setupIntent.payment_method;
            this.formData.paymentMethodID = paymentMethodId;
            this.formData.freeTrail = true;
            this.formData.amount = this.card.paymentType === 'yearly' ? this.perYearCost : this.total_cost;
            this.createAccount(this.formData);

          }
        } else {
          const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: "",
            },
            redirect: "if_required", // Prevent automatic redirection
          });

          if (error) {
            console.error("Payment failed:", error.message);
          } else if (paymentIntent && paymentIntent.status === "succeeded") {
            // Show success message
            Swal.fire({
              icon: "success",
              title: "Payment Successful!",
              text: "Your payment was completed successfully.",
              confirmButtonText: "OK",
            });

            this.paymentObjects.transaction_amount =
              this.paymentObjects.paymentMethod == "yearly"
                ? this.perYearCost
                : this.total_cost;

            this.formData.transaction_amount = this.paymentObjects.transaction_amount;
            this.formData.paymentMethod = this.paymentObjects.paymentMethod;


            this.createAccount(this.formData);


          }
        }
      } catch (err) {
        console.error("Error confirming payment:", err);
      }
    },
    async generatePaymentIntent() {
      try {
        // Calculate transaction amount based on payment type
        const amount = this.card.paymentType === 'yearly'
          ? this.perYearCost || 0
          : this.total_cost || 0;
        const response = await this.$http.post('/generate-secret', {
          amount: amount,
          free_trail: this.freeTrail
        });


        if (response.data && response.data.client_secret) {
          this.elementsOptions.clientSecret = response.data.client_secret;
        } else {
        }
      } catch (error) {
        console.error("Error fetching client secret:", error);
      }
    },


    tokenCreated(token) {
      this.card.token = token;
      this.card.zip = this.company.zip;
      this.card.address = this.company.address_1;
      this.card.state = this.company.state;
      this.card.city = this.company.city;
      this.payClicked(this.card);
      // handle the token
      // seformDatur server
    },
    async moveNext() {
      try {
        // ACH Payments
        if (this.paymentType == 'ach') {
          await this.ACHpayment();
          this.agreementModal = false;
          return;
        }
        // Invoice Payment
        if (this.paymentType == 'invoice') {
          await this.Invoicepayment();
          this.agreementModal = false;
          return;
        }
        // Invoice Payment
        if (this.paymentType == 'creditCard') {
          this.$http.post("validate-data", this.company)
            .then(async (response) => {
              if (response.data.success) {
                await this.pay();
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: response.data.message || "An error occurred while processing your request.",
                  confirmButtonText: "OK",
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response?.data?.message || "An unexpected error occurred.",
                confirmButtonText: "OK",
              });
            });
          this.agreementModal = false;
          return;
        }
        this.agreementModal = false;
        // this.nextStep();
        // this.ProceedToPayment();
      } catch (error) {
      }
    },

    checkvalidation() {
      this.$http.post("validate-data", this.company)
        .then((response) => {
          if (response.data.success) {
            // Proceed with the company details
            console.log("API call successful, proceeding with company details.");
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.data.message || "An error occurred while processing your request.",
              confirmButtonText: "OK",
            });
            return; // Stop further execution
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response?.data?.message || "An unexpected error occurred.",
            confirmButtonText: "OK",
          });
          return; // Stop further execution
        });
    },
    async nextStep() {
      if (this.active == 1) {
        const requiredFields = {
          first_name: "First Name",
          last_name: "Last Name",
          name: "Company Name",
          no_of_locations: "Number of Locations",
          no_of_employees: "Number of Employees",
          email: "Email",
        };

        for (const field in requiredFields) {
          if (!this.company[field]) {
            Swal.fire({
              icon: "warning",
              title: "Missing Field",
              text: `Please fill in the ${requiredFields[field]}.`,
              confirmButtonText: "OK",
            });
            return; // Stop further execution if a field is empty
          }
        }
        // Convert the string inputs to numbers
        const no_of_locations = parseInt(this.company.no_of_locations, 10);
        const no_of_employees = parseInt(this.company.no_of_employees, 10);

        // Validate if 'no_of_locations' and 'no_of_employees' are valid integers
        if (!Number.isInteger(no_of_locations) || no_of_locations <= 0) {
          Swal.fire({
            icon: "warning",
            title: "Invalid Input",
            text: "Please enter a valid number for Locations (positive integer).",
            confirmButtonText: "OK",
          });
          return; // Stop further execution if the value is not a valid integer
        }

        if (!Number.isInteger(no_of_employees) || no_of_employees <= 0) {
          Swal.fire({
            icon: "warning",
            title: "Invalid Input",
            text: "Please enter a valid number for Employees (positive integer).",
            confirmButtonText: "OK",
          });
          return; // Stop further execution if the value is not a valid integer
        }
        if (!this.company.email.includes("@") || !this.company.email.includes(".")) {
          Swal.fire({
            icon: "warning",
            title: "Invalid Email",
            text: "Please enter a valid email address.",
            confirmButtonText: "OK",
          });
          return;
        }

        if (this.company.telephone_no.replace(/\D/g, "").length !== 10) {
          Swal.fire({
            icon: "warning",
            title: "Invalid Phone Number",
            text: "Phone number must be exactly 10 digits.",
            confirmButtonText: "OK",
          });
          return;
        }
        await this.submitLead();

      }
      if (this.active == 2) {
        // this.pay()
        if (this.selectedCourses.length == 0) {
          Swal.fire({
            icon: "warning",
            title: "Missing Field",
            text: `Please select atleast one course`,
            confirmButtonText: "OK",
          });
          return;
        }
        // this.showInputModal = true;
      }

      if (this.active <= 4) {
        this.active++;
      }
    },
    prevStep() {
      if (this.active > 1) {
        this.active--;

        if (this.active == 1) {
          if (this.isCallLocationChecked) {
            this.isCallLocationChecked = false;
          }
          if (this.isSMSChecked) {
            this.isSMSChecked = false;
          }
          if (this.isOnboardingChecked) {
            this.isOnboardingChecked = false;
          }
          if (this.isSurveysChecked) {
            this.isSurveysChecked = false;
          }
          if (this.isFoodChecked) {
            this.isFoodChecked = false;
          }
          if (this.isHumanChecked) {
            this.isHumanChecked = false;
          }
          if (this.isSexualChecked) {
            this.isSexualChecked = false;
          }
          if (this.is24hourSafetyChecked) {
            this.is24hourSafetyChecked = false;
          }
          if (this.isUploadTrainingChecked) {
            this.isUploadTrainingChecked = false;
          }

        }
      }
    },
    Invoicepayment() {
      const requiredFields = {
        invoice_name: "Invoice Name",
        invoice_emails: "Invoice Emails",
      };
      for (const field in requiredFields) {
        if (!this.Invoice[field]) {
          Swal.fire({
            icon: "warning",
            title: "Missing Field",
            text: `Please fill in the ${requiredFields[field]}.`,
            confirmButtonText: "OK",
          });
          return; // Stop further execution if a field is empty
        }
      }
      this.paymentObjects.transaction_amount =
        this.paymentObjects.paymentMethod == "yearly"
          ? this.perYearCost
          : this.total_cost;
      this.formData.paymentMethod = this.paymentObjects.paymentMethod;
      this.formData.invoice = true;
      this.formData.invoice_name = this.Invoice.invoice_name;
      this.formData.invoice_emails = this.Invoice.invoice_emails;
      this.formData.transaction_amount =
        this.paymentObjects.transaction_amount * 1.1;

      this.createAccount(this.formData);
    },
    ACHpayment() {
      const requiredFields = {
        transit_number: "Transit Number",
        account_number: "Account Number",
        financial_instituation: "Financial Institution",
        branch: "Branch",
      };
      for (const field in requiredFields) {
        if (!this.ACH[field]) {
          Swal.fire({
            icon: "warning",
            title: "Missing Field",
            text: `Please fill in the ${requiredFields[field]}.`,
            confirmButtonText: "OK",
          });
          return; // Stop further execution if a field is empty
        }
      }

      this.paymentObjects.transaction_amount =
        this.paymentObjects.paymentMethod == "yearly"
          ? this.perYearCost
          : this.total_cost;

      this.formData.transaction_amount = this.paymentObjects.transaction_amount;
      this.formData.paymentMethod = this.paymentObjects.paymentMethod;
      this.formData.ach = true;
      this.formData.ACH = {
        account_holder_name: this.ACH.account_holder_name || "",
        transit_number: this.ACH.transit_number || "",
        account_number: this.ACH.account_number || "",
        financial_instituation: this.ACH.financial_instituation || "",
        branch: this.ACH.branch || "",
      };
      this.createAccount(this.formData);

      // console.log(this.paymentObjects);
    },
    InputModelOpen() {
      if (this.selectedCourses.length == 0) {
        Swal.fire({
          icon: "warning",
          title: "Missing Field",
          text: `Please select atleast one course`,
          confirmButtonText: "OK",
        });
        return;
      }
      this.showInputModal = true;
    },
    InputModelClose() {
      this.showInputModal = false;
    },
    paymenrModelOpen() {
      this.showPaymentOption = false;
      this.paymentOptionModal = true;
      this.agreementModal = false;
      this.achModal = false;
      this.invoiceModal = false;
    },
    paymenrModelClose() {
      this.paymentOptionModal = false;
    },
    handleBackButton(event) {
      // Prevent the default back button behavior
      event.preventDefault();

      // Change the showForm state to show or hide the form
      this.showForm = true; // Toggle showForm state when back button is clicked

      // Optionally, you can display a confirmation dialog before changing the form state
      if (!this.showForm) {
        Swal.fire({
          icon: "info",
          title: "Are you sure?",
          text: "You are leaving this page. Do you want to go back?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            // If user confirms, allow normal back navigation
            history.back(); // Go back to the previous page
          } else {
            // Otherwise, we reset the state without navigating
            history.pushState(null, null, window.location.href);
          }
        });
      }
    },

    updateTotalPrice(item, price) {
      const itemsConfig = {
        "Call Location": {
          unitCount: this.company.no_of_locations,
          isChecked: this.isCallLocationChecked,
          discountVar: "discountperlocation",
          perLocation: true, // Indicates it's a location-based service
        },
        "Onboarding": {
          unitCount: this.company.no_of_locations,
          isChecked: this.isOnboardingChecked,
          discountVar: "discountperlocation",
          perLocation: true,
        },
        "Surveys": {
          unitCount: this.company.no_of_locations,
          isChecked: this.isSurveysChecked,
          discountVar: "discountperlocation",
          perLocation: true,
        },
        "SMS": {
          unitCount: Math.ceil(this.company.no_of_employees / 100), // SMS is per 100 users
          isChecked: this.isSMSChecked,
          discountVar: "discountperemp",
          perUser: true,
        },
        "Food": {
          unitCount: this.company.no_of_employees,
          isChecked: this.isFoodChecked,
          discountVar: "discountperemp",
          perUser: true,
        },
        "Human": {
          unitCount: this.company.no_of_employees,
          isChecked: this.isHumanChecked,
          discountVar: "discountperemp",
          perUser: true,
        },
        "Sexual": {
          unitCount: this.company.no_of_employees,
          isChecked: this.isSexualChecked,
          discountVar: "discountperemp",
          perUser: true,
        },
        "HoursSafety": {
          unitCount: this.company.no_of_employees,
          isChecked: this.is24hourSafetyChecked,
          discountVar: "discountperemp",
          perUser: true,
        },
        "UploadTraining": {
          unitCount: Math.ceil(this.company.no_of_employees / 5), // Training is per 5 users
          isChecked: this.isUploadTrainingChecked,
          discountVar: "discountperemp",
          perUser: true,
        },
      };

      const config = itemsConfig[item];
      if (!config) return; // Exit if item is not recognized

      let totalCostPerMonth = price * config.unitCount;

      // Ensure proper location-based cost calculation
      if (config.perLocation) {
        totalCostPerMonth *= this.company.no_of_locations; // Multiply by locations if it's a location-based service
      }

      let perUserCost = config.perUser ? totalCostPerMonth / this.company.no_of_employees : 0; // Only apply per-user logic where needed

      if (config.isChecked) {
        this.total_cost += totalCostPerMonth;
        this.perYearCost += totalCostPerMonth * 12;

        if (config.perUser) {
          this.discountperemp += perUserCost;
        } else if (config.perLocation) {
          this.discountperlocation += price; // Correct per-location discount
        }
      } else {
        this.total_cost -= totalCostPerMonth;
        this.perYearCost -= totalCostPerMonth * 12;

        if (config.perUser) {
          this.discountperemp -= perUserCost;
        } else if (config.perLocation) {
          this.discountperlocation -= price;
        }
      }
    },



    showInput(course) {
      this.showusermodel = true;
      this.specialCourseName = course.course_name;
      this.specialCourseId = course.id;
      // Show input and button when the question mark is clicked
      //  this.$set(this.isEditing, courseId, true);
    },
    async showCoursePage() {
      // Define all the fields explicitly for validation
      const requiredFields = {
        first_name: "First Name",
        last_name: "Last Name",
        name: "Company Name",
        no_of_locations: "Number of Locations",
        no_of_employees: "Number of Employees",
        //address_1: "Address Line 1",
        // city: "City",
        // state: "State",
        // zip: "Zip Code",
        // telephone_no: "Telephone Number",
        email: "Email",
        // password: "Password"
      };

      for (const field in requiredFields) {
        if (!this.company[field]) {
          Swal.fire({
            icon: "warning",
            title: "Missing Field",
            text: `Please fill in the ${requiredFields[field]}.`,
            confirmButtonText: "OK",
          });
          return; // Stop further execution if a field is empty
        }
      }
      // Convert the string inputs to numbers
      const no_of_locations = parseInt(this.company.no_of_locations, 10);
      const no_of_employees = parseInt(this.company.no_of_employees, 10);

      // Validate if 'no_of_locations' and 'no_of_employees' are valid integers
      if (!Number.isInteger(no_of_locations) || no_of_locations <= 0) {
        Swal.fire({
          icon: "warning",
          title: "Invalid Input",
          text: "Please enter a valid number for Locations (positive integer).",
          confirmButtonText: "OK",
        });
        return; // Stop further execution if the value is not a valid integer
      }

      if (!Number.isInteger(no_of_employees) || no_of_employees <= 0) {
        Swal.fire({
          icon: "warning",
          title: "Invalid Input",
          text: "Please enter a valid number for Employees (positive integer).",
          confirmButtonText: "OK",
        });
        return; // Stop further execution if the value is not a valid integer
      }
      await this.submitLead();

      // Add "You May Also Like" prices if checked
      // Uncheck all "You May Also Like" options if they are checked
      if (this.isCallLocationChecked) {
        this.isCallLocationChecked = false;
      }
      if (this.isSMSChecked) {
        this.isSMSChecked = false;
      }
      if (this.isOnboardingChecked) {
        this.isOnboardingChecked = false;
      }
      if (this.isSurveysChecked) {
        this.isSurveysChecked = false;
      }

      //   window.scrollTo({ top: 0, behavior: "smooth" });
      this.showForm = false;
      // Proceed to show the course page if all required fields are filled
    },
    showFormPage() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.showForm = true;
    },
    isLimitReached() {
      // Check if the selected folder has a limit and if the number of selected courses exceeds the limit
      if (this.openFolder2 || this.openFolder3) {
        return this.selectedCourses.length >= this.selectedFolderLimit;
      }
      return false; // No limit for folder 1 and folder 4
    },
    async selectFolder(id) {
      for (let i = 1; i <= 4; i++) {
        this[`openFolder${i}`] = i === id;
      }

      if (id === 1) {
        this.defaultSelectedCourses = this.folder1Courses;
      } else if (id === 2) {
        this.defaultSelectedCourses = this.folder2Courses;
        this.selectedFolderLimit = this.folder2stockLimit;
        this.showFolderLimit = this.folder2stockLimitShow;
      } else if (id === 3) {
        this.defaultSelectedCourses = this.folder3Courses;
        this.selectedFolderLimit = this.folder3stockLimit;
        this.showFolderLimit = this.folder3stockLimitShow;
      } else if (id === 4) {
        this.defaultSelectedCourses = this.folder4Courses;
      } else {
        this.defaultSelectedCourses = this.folder4Courses;
        this.selectedFolderLimit = "";
      }
      this.selectedCourses = this.courses.filter((course) =>
        this.defaultSelectedCourses.includes(course.id)
      );
      this.preSelectedCourses = this.courses.filter((course) =>
        this.defaultSelectedCourses.includes(course.id)
      );
      this.additionalCourses = this.courses.filter(
        (course) => !this.defaultSelectedCourses.includes(course.id)
      );

      // Check for special courses and apply logic
      // Check for special courses and apply logic
      this.specialCourseQueue = []; // Initialize an empty queue if not already defined
      this.defaultSelectedCourses.forEach((selectedId) => {
        const specialCourse = this.specialCourses.find(
          (course) => course.id === selectedId
        );

        if (specialCourse) {
          this.specialCourseQueue.push({
            course_name: specialCourse.course_name,
            id: specialCourse.id,
          });
        }
      });
      // Function to display the next modal in the queue
      const showNextModal = () => {
        if (this.specialCourseQueue.length > 0) {
          const nextCourse = this.specialCourseQueue.shift(); // Get the next special course
          this.specialCourseName = nextCourse.course_name;
          this.specialCourseId = nextCourse.id;
          this.showusermodel = true; // Show the modal
        }
      };

      // Show the first modal
      showNextModal();

      // Trigger the next modal after user interaction
      this.$watch("showusermodel", (newValue) => {
        if (!newValue) {
          // When the modal is closed, show the next modal if available
          showNextModal();
        }
      });

      // Uncheck and deduct prices for "You May Also Like" options
      if (this.isCallLocationChecked) {
        this.total_cost -= 35.0; // Deduct the price for Call Location
        this.perYearCost -= 35.0 * 12; // Deduct the price for Call Location
      }
      if (this.isSMSChecked) {
        this.total_cost -= 0.25 * this.company.no_of_employees; // Deduct the price for SMS per user
        this.perYearCost -= 0.25 * this.company.no_of_employees * 12; // Deduct the price for SMS per user
      }
      if (this.isOnboardingChecked) {
        this.total_cost -= 3.0; // Deduct the price for Onboarding
        this.perYearCost -= 3.0 * 12; // Deduct the price for Onboarding
      }
      if (this.isSurveysChecked) {
        this.total_cost -= 10.0; // Deduct the price for Surveys
        this.perYearCost -= 10.0 * 12; // Deduct the price for Surveys
      }

      // Uncheck all "You May Also Like" options
      this.isCallLocationChecked = false;
      this.isSMSChecked = false;
      this.isOnboardingChecked = false;
      this.isSurveysChecked = false;

      // Reset users for all special courses
      this.specialCourses.forEach((specialCourse) => {
        if (specialCourse.users !== 0) {
          specialCourse.users = 0; // Reset user count to zero for special courses
        }
      });

      await this.submitLead();
      // this.submitLead();
    },
    isCourseSelected(course) {
      return this.selectedCourses.some((selected) => selected.id === course.id);
    },
    addtoggleCourse(id) {
      const course = this.courses.find((c) => c.id === id);
      if (course) {
      this.toggleCourse(course);
      }
    },
    CheckisCourseSelected(id){
      return this.selectedCourses.some((selected) => selected.id === id);

    },
    async toggleCourse(course) {
      const courseIndex = this.selectedCourses.findIndex(
        (c) => c?.id === course?.id
      );

      // Check if the course is in special courses
      const specialCourse = this.specialCourses.find(
        (special) => special.id === course.id
      );
      if (specialCourse && specialCourse.users == 0) {
        this.specialCourseName = specialCourse.course_name;
        this.specialCourseId = specialCourse.id;
        this.showusermodel = true;
      }
      if (specialCourse && specialCourse.users !== 0) {
        specialCourse.users = 0; // Reset user count to zero
        // console.log(specialCourse);
      }
      if (courseIndex !== -1) {
        // Course is already selected, remove it from the array
        this.selectedCourses.splice(courseIndex, 1);
        // and if the user count is not zero

        // this.limitstart=this.selectedCourses.length;
        this.limitstart = this.selectedCourses.length;
      } else {
        if (this.openFolder2 || this.openFolder3) {
          // Get the selected folder's limit (folder 2 has limit 10, folder 3 has limit 25)
          const limit = this.selectedFolderLimit;

          // Only allow adding more courses if the current limit is not reached
          if (this.selectedCourses.length >= limit) {
            alert(`Limit of ${limit} courses reached for this folder`);
            return; // Prevent adding more courses if the limit is reached
          }
        }
        // Course is not selected, add it to the array
        this.selectedCourses = [
          ...this.selectedCourses.filter((c) => c.id !== course.id),
          course,
        ];
      }

      if (
        this.selectedCourses.length > 0
      ) {
        this.openFolder4 = true; // Enable folder 4
      } else if (this.selectedCourses.length === 0) {
        this.openFolder4 = false; // Disable folder 4 when no courses are selected
      }

      await this.submitLead();
    },
    applyPromoCode() {
      // this.loading = true;
      let data = {
        monthlyAmount: this.total_cost,
        yearlyAmount: this.perYearCost,
        locationAmount: this.discountperlocation,
        userAmount: this.discountperemp,
        promocode: this.promo_code,
      };
      this.$http
        .post("promocode/apply_promocode", data)
        .then((resp) => {
          this.total_cost = resp.data.final_amount_monthly;
          this.course_cost_monthly = resp.data.previous_amount_monthly;
          this.course_cost_yearly = resp.data.previous_amount_yearly;
          this.perYearCost = resp.data.final_amount_yearly;
          this.discount_percentage = resp.data.discount_percentage;
          this.discountperlocation = resp.data.final_location_amount;
          this.discountperemp = resp.data.final_user_amount;
          this.loading = false;
          this.submitLead();
        })
        .catch(function (error) {
          this.loading = false;
          if (error.response.status === 422) {
            return Swal.fire({
              title: "Error!",
              html: error.response.data.message,
              icon: "error",
            });
          }
        });
    },
    //  async updateUsers(courseId) {
    //     // Ensure `courseId` and `users` are valid
    //     if (!courseId || !this.users || isNaN(parseInt(this.users))) {
    //         console.warn("Invalid courseId or users value");
    //         return;
    //     }

    //     // Find and update the matching course
    //     const courseToUpdate = this.specialCourses.find(course => course.id === courseId);
    //     if (courseToUpdate) {
    //         courseToUpdate.users = parseInt(this.users); // Update users field
    //         console.log(`Updated users for course ID ${courseId}:`, courseToUpdate);
    //     } else {
    //         console.warn(`No special course found with ID ${courseId}`);
    //     }
    //     console.log(`Updated user count for course ${courseId}`);
    //     this.$set(this.isEditing, courseId, false);

    //     // Reset modal state and input
    //     this.showusermodel = false;
    //     this.users = 0;
    //     await this.submitLead();
    //   },
    async updateUsers() {
      this.specialCourses.filter((obj) => {
        if (obj.id == this.specialCourseId) {
          obj.users = parseInt(this.users);
        }
      });

      this.showusermodel = false;
      this.users = null;
      await this.submitLead();
    },
    dropdownselectionChange(e) {
      if (e.length < this.old_length) {
        const result1 = this.specialCourses.map((data) => {
          return data.id;
        });
        let diff10 = result1.filter((data1) => e.indexOf(data1) === -1);
        if (diff10 === result1 || e.length == 0 || e.indexOf(result1) != -1) {
          // this.specialCourses = this.specialCoursescopy;

          this.resetSpecialCourseUsers();
        } else if (diff10.length < result1.length) {
          //  this.specialCourseId = diff10;
          let diff11 = this.olde.filter((data1) => e.indexOf(data1) === -1);
          this.specialCourseId = parseInt(diff11.toString());
          this.users = 0;
          this.updateUsers();
        } else {
          this.users = 0;
          this.updateUsers();
        }
      }

      var existSpecialCourse = this.specialCourses.filter((obj) => {
        return obj.id == e[e.length - 1];
      });

      if (existSpecialCourse.length > 0) {
        this.$nextTick(() => {
          this.$refs.dropdown.visible = false;
        });

        this.specialCourseId = e[e.length - 1];

        var result2 = this.courses.filter((obj) => {
          return obj.id === this.specialCourseId;
        });

        this.specialCourseName = result2[0].course_name;

        if (e.length > this.old_length) {
          this.showusermodel = true;
        } else {
        }
      }
      this.olde = e;
      this.old_length = e.length;
    },
    specialCourseUsers() {
      this.spacialCourseFlag = this.specialCourses.map((item) => {
        if (item.users > 0) {
          return true;
        }
        return false;
      });
    },
    resetSpecialCourseUsers() {
      for (let i = 0; i < this.specialCourses.length; i++) {
        this.specialCourses[i].users = 0;
      }

      return this.specialCourses;
    },
    showDone() {
      this.courseSelectionFocused = true;
    },
    doneClicked(e) {
      this.courseSelectionFocused = false;
    },
    cancelAgreement() {
      this.agreementModal = false;
    },
    showContactUs() {
      this.contactUsModal = true;
    },
    finalCreateAccount() {
      //this.createAccount(this.formData);
      if (this.paymentType === "creditCard") {
        this.agreementModal = false;
        this.showPaymentOption = true;
        this.paymentOptionModal = false;
        this.achModal = false;
        this.invoiceModal = false;
      }
      if (this.paymentType === "ach") {
        this.achModal = true;
        this.agreementModal = false;
        this.showPaymentOption = false;
        this.paymentOptionModal = false;
        this.invoiceModal = false;
      }
      if (this.paymentType === "invoice") {
        this.achModal = false;
        this.invoiceModal = true;
        this.agreementModal = false;
        this.showPaymentOption = false;
        this.paymentOptionModal = false;
      }
    },
    payClicked(cardData) {
      this.loading = true;
      this.paymentModalMessage = true;
      let payment = {
        payment_type: "",
        cardholder_street_address:
          cardData.address + "," + cardData.city + "," + cardData.state,
        cardholder_zip: cardData.zip,
        transaction_amount: "",
        token: cardData.token,
      };
      if (this.specialCourseFlag) {
        payment.payment_type = "one-time";
      } else {
        payment.payment_type = cardData.paymentType;
      }
      if (cardData.paymentType == "monthly") {
        payment.transaction_amount = this.total_cost.toFixed(2);
      }
      if (cardData.paymentType == "yearly") {
        payment.transaction_amount = this.perYearCost.toFixed(2);
      }
      this.formData.payment = payment;
      this.formData.address_1 = cardData.address;
      this.formData.company_address_1 = cardData.address;
      this.formData.company_state = cardData.state;
      this.formData.company_city = cardData.city;
      this.formData.company_zip = cardData.zip;

      if (this.promoCodeApplied) {
        this.formData.promo_code = this.promo_code;
        this.formData.course_cost_monthly = this.course_cost_monthly;
      }
      this.loading = false;
      this.createAccount(this.formData);
    },

    showAgreement() {
      const requiredFields = {
        first_name: "First Name",
        last_name: "Last Name",
        name: "Company Name",
        no_of_locations: "Number of Locations",
        no_of_employees: "Number of Employees",
        address_1: "Address Line 1",
        city: "City",
        state: "State",
        zip: "Zip Code",
        telephone_no: "Telephone Number",
        email: "Email",
        password: "Password",
      };

      for (const field in requiredFields) {
        if (!this.company[field]) {
          Swal.fire({
            icon: "warning",
            title: "Missing Field",
            text: `Please fill in the ${requiredFields[field]}.`,
            confirmButtonText: "OK",
          });
          return; // Stop further execution if a field is empty
        }
      }
      this.showInputModal = false;
      this.paymentOptionModal = false;
      let selectedCourses = [...this.selectedCourses]; // Assuming checked_courses contains all selected courses
      let specialCourseIds = this.specialCourses.map((course) => course.id); // Get all special course IDs

      let specialCourses = selectedCourses.filter((course) =>
        specialCourseIds.includes(course.id)
      );
      selectedCourses = selectedCourses.filter(
        (course) => !specialCourseIds.includes(course.id)
      );

      let idsOfNomralCourses = selectedCourses.map((course) => course.id); // IDs of regular courses
      const result1 = this.specialCourses.map((data) => {
        return data.id;
      });
      const diff1 = this.checked_courses.filter(
        (data1) => result1.indexOf(data1) === -1
      );
      for (let special of this.specialCourses) {
        this.selectedRows.push(special.id);
        this.selectedUsers.push(special.users);
      }
      this.formData = {
        company_name: this.company.name,
        first_name: this.company.first_name,
        last_name: this.company.last_name,
        company_location_num: this.company.no_of_locations,
        company_employee_num: this.company.no_of_employees,
        company_address_1: this.company.address_1,
        company_address_2: this.company.address_2,
        company_phone: this.company.telephone_no,
        company_email: this.company.email,
        company_zip: this.company.zip,
        website: this.company.website,
        company_type: this.company.company_type,
        username: this.company.email,
        parent_id: this.company.parent_company,
        image: this.image,
        company_city: this.company.city,
        company_state: this.company.state,
        company_password: this.company.password,
        course_ids: idsOfNomralCourses,
        // special_courses: this.selectedRows,
        // selected_users: this.selectedUsers,
        special_courses: this.specialCourses,
        status: 1,
        payment: [],
        card_info: [],
        i_agree: true,
        call_location: this.isCallLocationChecked,
        sms: this.isSMSChecked,
        onboarding: this.isOnboardingChecked,
        survey: this.isSurveysChecked,
        uploadOwnTraining : this.uploadOwnTraining,
      };
      this.agreementModal = true;
    },

    formatPrice(value) {
      return (
        "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
      );
    },
    formatPriceWithDiscount(value) {
  let discountedValue = value - (value * 10) / 100; // Apply 10% discount
  return (
    "$" + discountedValue.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  );
 },

    submitLead() {
      let selectedCourses = [...this.selectedCourses]; // Assuming checked_courses contains all selected courses
      let specialCourseIds = this.specialCourses.map((course) => course.id); // Get all special course IDs

      let specialCourses = selectedCourses.filter((course) =>
        specialCourseIds.includes(course.id)
      );
      selectedCourses = selectedCourses.filter(
        (course) => !specialCourseIds.includes(course.id)
      );

      let idsOfNomralCourses = selectedCourses.map((course) => course.id); // IDs of regular courses

      //  console.log(idsOfNomralCourses);
      //  return;

      const result = this.specialCourses.map((data) => {
        return data.id;
      });
      const diff = this.checked_courses.filter(
        (data1) => result.indexOf(data1) === -1
      );
      let selectedSpecialCourses = [];

      var result4 = this.specialCourses.filter((obj) => {
        return obj.users != 0;
      });

      selectedSpecialCourses = result4;

      var resulttest = selectedSpecialCourses.map(function (a) {
        return a.id;
      });
      const finalids = diff.concat(resulttest);
      if (
        this.company.no_of_locations !== "" &&
        this.company.no_of_employees !== "" &&
        this.company.no_of_locations < 2 &&
        this.company.no_of_employees < 2
      ) {
        if (
          this.company.name !== "" &&
          this.company.first_name !== "" &&
          this.company.last_name !== "" &&
          this.company.email !== ""
        ) {
          return Swal.fire({
            title: "Warning!",
            html: "Based on the details provided, it appears you requesting a quote for an individual user.  Please click OK to proceed.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonClass: "btn base-button dash-primary-orange border-0 py-2 px-4 rounded-3 btn-primary",
            cancelButtonClass: "btn base-button dash-primary-orange border-0 py-2 px-4 rounded-3 btn-primary",
            confirmButtonText: "Ok",
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.value) {
              localStorage.setItem("fname", this.company.first_name);
              localStorage.setItem("lname", this.company.last_name);
              localStorage.setItem("email", this.company.email);
              localStorage.setItem("courses", JSON.stringify(finalids));
              window.location.href = "#/user_register?redirection=yes";
            } else {
              this.company.no_of_locations = "";
              this.company.no_of_employees = "";
            }
          });
        } else {
          return Swal.fire({
            title: "Error",
            html: "Please fill all required feilds.",
            icon: "error",
          });
        }
      }
      // this.loading = true;
      let data = {
        company_name: this.company.name,
        first_name: this.company.first_name,
        last_name: this.company.last_name,
        number_of_locations: this.company.no_of_locations,
        number_of_employees: this.company.no_of_employees,
        phone_num: this.company.telephone_no,
        email: this.company.email,
        user_type: "corporate",
        // course_ids: diff,
        course_ids: idsOfNomralCourses,
        special_courses: selectedSpecialCourses,
        promo_code: this.promo_code,
        course_cost: this.course_cost_monthly,
        discounted_cost: this.total_cost,
        total_cost_per_year: this.perYearCost,
        per_location: this.discountperlocation,
        per_user: this.discountperemp,
      };
      this.$http
        .post("user/lead", data)
        .then((resp) => {
          if (!this.promo_code) {
            this.lead_id = resp.data.user_id;
            this.companyEstimateDetailModel = false;
            this.total_cost = resp.data.total;
            this.sub_total = resp.data.sub_total;
            this.total_discount = resp.data.discount_value;
            if (resp.data.discount != null) {
              this.discount_msg = resp.data.discount.title;
            }

            this.employees_count = resp.data.number_of_employees;
            this.locations_count = resp.data.number_of_locations;
            this.discountperemp =
              resp.data.total / resp.data.number_of_employees;
            this.discountperlocation = resp.data.per_location_cost;
            this.showPricePlan = true;
            this.specialCourseFlag = resp.data.onlySpecialCourse;
            this.special_courses_with_users = resp.data.special_courses_users;
            if (this.special_courses_with_users) {
              var result1 = this.special_courses_with_users.filter((obj) => {
                return obj.users != 0;
              });
              this.special_courses_with_users = result1;
            }
            this.promo_code = "";
            this.showPromoCodeOption = true;
            this.appliedText = "";
            this.promoCodeApplied = false;

            this.perYearCost = resp.data.perYearCost;
          } else {
            this.promoCodeApplied = true;
            this.showPromoCodeOption = false;
            this.promo_code = "";
          }
          this.loading = false;
          this.isContinueButtonDisabled = false;
        })
        .catch(function (error) {
          this.loading = false;
          if (error.response.status === 422) {
            return Swal.fire({
              title: "Error!",
              html: error.response.data.message,
              icon: "error",
            });
          }
        });
    },
    companyDetails() {
      this.showCompanyinformation = true;
    },
    companyEstimateDetail() {
      this.companyEstimateDetailModel = true;
    },
    hideModel() {
      this.companyEstimateDetailModel = false;
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    cancelContact() {
      this.contactUsModal = false;
    },
    saveContact() {
      this.loading = true;
      let data = {
        name: this.contact.name,
        email: this.contact.email,
        phone: this.contact.phone,
        message: this.contact.message,
      };
      this.$http
        .post("user/contact", data)
        .then((resp) => {
          this.contactUsModal = false;
          this.loading = false;
          Swal.fire({
            title: "Success!",
            text: resp.data.message,
            icon: "success",
          });
        })
        .catch(function (error) {
          this.loading = false;
          if (error.response.status === 422) {
            let respmessage = error.response.data.message.replace(/,/g, "\n");
            Swal.fire({
              title: "Error!",
              text: respmessage,
              icon: "error",
            });
          }
        });
    },

    acceptNumber() {
      var x = this.company.telephone_no
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.company.telephone_no = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

      var y = this.contact.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.contact.phone = !y[2]
        ? y[1]
        : "(" + y[1] + ") " + y[2] + (y[3] ? "-" + y[3] : "");
    },
    onImageChange(e) {
      let files = e;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    createAccount(formDataSubmitted) {
      this.loading = true;
      delete this.$http.defaults.headers["authorization"];
      this.creatAccountClicked = true;
      this.$http
        .post("company/register", formDataSubmitted)
        .then((resp) => {
          this.paymentSuccess = true;
          let ids = [];
          let obj = {
            id: resp.data.id,
          };
          ids.push(obj);
          this.agreementModal =
            this.showPaymentOption =
            this.showusermodel =
            this.loading =
            this.enablePaymentButton =
            false;
          this.welcomeEmail(formDataSubmitted, ids);
        })
        .catch((error) => {
          this.loading = false;
          this.agreementModal =
            this.showPaymentOption =
            this.showusermodel =
            this.loading =
            this.enablePaymentButton =
            false;
          if (error.response.status === 422) {
            Swal.fire({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false;
          }
        })
        .finally(() => {
          this.loading = false;
          this.paymentModalMessage = false;
        });
    },

    welcomeEmail(formDataSubmitted, ids) {
      this.loading = true;
      this.$http
        .post("company/welcome_email", {
          form_data: formDataSubmitted,
          password: this.company.password,
          ids: ids,
        })
        .then((resp) => {
          this.agreementModal =
            this.showPaymentOption =
            this.showusermodel =
            this.loading =
            this.enablePaymentButton =
            false;
          // this.$router.push("/login");
          this.nextStep();
          Swal.fire({
            title: "Success!",
            text: `Account created successfully.`,
            icon: "success",
          });
        })
        .catch(function (error) {
          this.agreementModal =
            this.showPaymentOption =
            this.showusermodel =
            this.loading =
            this.enablePaymentButton =
            false;
          this.$router.push("/login");
          Swal.fire({
            title: "Success!",
            text: "Account created successfully.",
            icon: "success",
          });
        });
    },
    handleClick(tab) {
      this.tabActiveName =  tab.name;

      if(tab.name=='Monthly') this.card.paymentType = 'monthly'
      else this.card.paymentType = 'yearly';
      console.log("Active Tab:", tab.name);
    },
  },
  watch: {
    lead_id: function () {
      if (this.lead_id == "") {
        this.isContinueButtonDisabled = true;
      }
    },
    checked_courses: function () {
      this.isContinueButtonDisabled = true;
    },
  },
};
</script>
<style scoped>
.payment-area >>> .el-tabs__header {
  margin:0;
}
.payment-area >>> .el-tabs__item {
  font-weight: bold;
  padding-left:20px;
  padding-right:0px;
  line-height: 27px;
}
.payment-area >>> .el-tabs__item.is-active {
  color:#e75f31;

}
.payment-area >>> .el-tabs__nav-wrap::after {
  background:transparent;
}
.payment-area >>> .el-tabs__active-bar {
  background:#e75f31;
}
.payment-area >>> .el-tabs__item:hover {
  color:#e75f31;
}

.payment-area .custom-border-color {
  border-color: rgba(37, 37, 37, .1) !important;
}

.steps-tab-wrap>>>.el-step__icon.is-text {
  background: #EEECEB;
  width: 50px;
  height: 50px;
  border: 0;
  color: #C2C2C2;
  font-weight: 700;
  font-size: 24px;
}

.steps-tab-wrap>>>.is-process .el-step__icon.is-text,
.steps-tab-wrap>>>.is-success .el-step__icon.is-text {
  background: linear-gradient(to right, #e4973a 0%, #eb1a26 100%);
  color: white;
}

.steps-tab-wrap>>>.el-step.is-horizontal .el-step__line {
  top: 25px;
}

.steps-tab-wrap>>>.el-step__head.is-success {
  border-color: #eb1a26;
}



@media (max-width: 768px) {
  .steps-tab-wrap>>>.el-step__icon.is-text {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }

  .steps-tab-wrap>>>.el-step.is-horizontal .el-step__line {
    top: 20px;
  }
}

.line-break {
  display: inline;
}

/* On larger screens (e.g., desktops) */
@media (min-width: 992px) {
  .line-break {
    display: block;
    /* Forces line break */
  }
}

.footer {
  background-color: black;
  color: white;
  font-size: 14px;
  padding: 10px 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1000;
  left: 0%;
}

.footer-text {
  color: rgba(255, 255, 255, 0.8);
}

.footer-links {
  display: flex;
  gap: 15px;
}

.footer-link {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
  color: white;
}

.question-mark {
  color: red;
  font-weight: bold;
  cursor: pointer;
  margin-left: 1rem;
}

/* Extend existing styles */
.course-list-ul {
  max-height: 400px;
  /* Retained existing max-height */
  overflow-y: auto;
  /* Enable vertical scrolling */
  padding: 0;
  margin: 0;
  list-style-type: none;
  color: #787676;
}

.course-list-ul li {
  line-height: 10px;
  /* Retained line spacing */

  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Add spacing between checkbox and special-course-options */
}

.course-checkbox {
  display: flex;
  align-items: center;
}

.course-label {
  margin-top: 12px;
  /* Add space between checkbox and label */
  margin-left: 10px;
  /* Add space between checkbox and label */
  font-size: 14px;
  color: inherit;
  /* Ensure it uses the parent color (#787676) */
}

.special-course-options {
  display: flex;
  align-items: center;
  gap: 8px;
  /* Add spacing between the input and button */
}

.small-input input {
  width: 50px;
  /* Adjust width to make it smaller */
  padding: 2px;
  /* Reduce padding */
  font-size: 14px;
  /* Adjust font size */
  text-align: center;
  /* Center align the text */
  border: 1px solid #ccc;
  /* Optional: Add border for clarity */
  border-radius: 4px;
  /* Optional: Slight border rounding */
}

.small-input input::-webkit-inner-spin-button,
.small-input input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  /* Remove default number input arrows */
}

.transparent-btn {
  background: none;
  border: none;
  color: green;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  padding: 0;
}

.transparent-btn:hover {
  color: darkgreen;
  /* Slight hover effect */
}

/*
Text on Image
*/

.text-title {
  font-size: 32px;
  font-weight: bolder;
  line-height: 1.2;
  margin-bottom: 10px;
  color: white;
}

.text-subtitle {
  font-size: 13px;
  line-height: 1.5;
  max-width: 80%;
  color: white;
}

/* Checkbox */
input[type="checkbox"] {
  accent-color: #e66412;
  /* Change this to your desired color */
}

/* NEXT BTN */
.btn-black {
  width: 100%;
  background-color: black;
  color: white;
  border: none;
  border-radius: 0%;
  padding: 10px;
  /* Adjust padding for desired height */
  font-size: 16px;
  /* Adjust font size */
  text-align: center;
  cursor: pointer;
  border-radius: 5px !important;
}

.btn-black:hover {
  background-color: #333;
  /* Slightly lighter black for hover effect */
}

/*Transitions Buttons*/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Folder Styling */
.active-fldr {
  background-color: #e66412;
}

.nrml-fldr-text {
  color: #767676;
  font-size: 16px;
}

.active-fldr-text {
  color: #ffffff;
  font-size: 16px;
}

.course-list-ul {
  max-height: 400px;
  /* Adjust based on your needs */
  /* over-flow-y:auto; */
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
  color: #787676;
}

.mgbtm {
  margin-bottom: 100px;
}

.course-list-ul li {
  line-height: 16px;
  /* Line spacing */
}

.payment-modal-text {
  animation: textBlink 2s linear infinite;
}

@keyframes textBlink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.form-section {
  background-color: transparent;
  padding: 40px;
  border-right: 1px solid #999999;
}

.promocode-applied {
  color: #eb1a26;
  font-weight: bold;
  font-size: 14px;
}

.course-section {
  padding: 40px;
  background-color: #ffffff !important;
}

.py-5 {
  padding-bottom: 0px !important;
}

.mt--6 {
  margin-top: -6rem !important;
}

.mt--12 {
  margin-top: -12rem !important;
}

.search-wrapper {
  position: relative;
}

.courseList {
  max-height: 250px !important;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}

hr {
  margin-top: 2px !important;
  margin-bottom: 20px !important;
}

.basebutton.disabled:hover {
  cursor: not-allowed;
}

@media (min-width: 992px) {

  .pt-lg-9,
  .py-lg-9 {
    padding-top: 3rem !important;
  }

  .password-eye span {
    border: 1px solid #808080b3;
    padding: 8px;
    border-radius: 5px;
    background: #80808029;
  }
}

@media (min-width: 768px) {
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 25.33333%;
  }
}

/* ============17/11/2020============ */

#selected_course li {
  font-size: 0.89em;
}

#serviceAgreement {
  float: left;
  height: 300px;
  overflow: auto;
}

#serviceAgreement p {
  font-size: 0.81rem;
  text-align: justify;
}

.el-select-group__title {
  text-decoration: underline !important;
  font-weight: bolder !important;
}

.el-select-dropdown__item {
  font-size: 13px !important;
}

.reduceFont {
  font-weight: 400 !important;
}

.price-area .row {
  margin-top: 5px;
}

.bg-gradient-primary {
  background: linear-gradient(87deg, #07c9fb 0, #ffffff 100%) !important;
}

.req {
  color: red;
}

.flexed {
  display: flex;
}

.f-col {
  flex-direction: column;
}

.gap-2 {
  gap: 20px;
}

.gap-3 {
  gap: 20px;
}

.top-selection-banner {
  display: flex;
  gap: 20px;
}

.top-selection-banner .inner-info {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.text-overlay {
  position: absolute;
  bottom: 0 !important;
  left: 0 !important;
  width: 100%;
  /* -webkit-transform: translateY(-50%);
    transform: translateY(-50%); */
  color: white;
  z-index: 100;
  text-align: left;
}

.text-content {
  z-index: 100 !important;
  position: relative;
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.text-content img {
  width: 120px;
  height: auto;
}

.overlay-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent 0%, black 100%);
}

.modal-dialog {
  justify-content: center !important;
}

.modal-dialog .modal-content {
  width: fit-content;
}

.text-title {
  font-size: 32px;
  font-weight: bolder;
  line-height: 1.2;
  margin-bottom: 10px;
  color: white;
}

.text-subtitle {
  font-size: 13px;
  line-height: 1.5;
  max-width: 80%;
  color: white;
}

.show-on-mobile {
  display: none;
}

@media (min-width: 992px) {

  .pt-lg-9,
  .py-lg-9 {
    padding-top: 3rem !important;
  }
}

.pre-courses-box {
  background: white;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.courses-box {
  background: white;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 475px;
}

.courses-box h2 {
  margin: 0;
  padding: 0;
}

.checkbox-flex {
  display: flex;
  gap: 10px;
  margin: 10px 0px;
}

.height-cart {
  padding: 20px 20px;
}

.sticky-submit-btn {
  display: none;
}

.inner-info .selection-type {
  color: black;
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
}

.inner-info .selection-desc {
  color: #7a7a7a;
  font-size: 13px;
  line-height: normal;
}

@media (max-width: 990px) {
  .show-on-mobile {
    display: block;
  }

  .mobile-margin-top-10 {
    margin-top: 10px;
  }

  .height-cart {
    height: fit-content;
    padding: 20px 20px;
  }

  .hide-on-mobile {
    display: none;
  }

  .sticky-submit-btn {
    display: flex;
    justify-content: space-between;
    background-color: rgb(30, 30, 30);
    color: white;
    border-radius: 0px !important;
    position: fixed;
    width: 95%;
    margin: 0px 0px 10px -3px !important;
    padding: 8px 20px !important;
    border-radius: 100px !important;
    bottom: 0;
    z-index: 100;
    align-items: center;
  }

  .margin-0-mobile {
    margin-top: 0px;
  }

  .top-selection-banner {
    gap: 8px;
  }

  .top-selection-banner img {
    width: 32px;
    margin-top: 5px;
  }

  .inner-info .selection-type {
    color: black;
    font-size: 15px;
    line-height: normal;
    font-weight: 600;
  }

  .inner-info .selection-desc {
    color: #7a7a7a;
    font-size: 12px;
    line-height: normal;
  }

  .inner-info {
    display: none !important;
  }
}

.discount-btn {
  border-radius: 5px !important;
  background-color: rgb(230, 100, 18);
  color: white;
  margin-left: 10px;
  position: absolute;
  right: 0px;
  height: 43px;
  border: 0;
  padding: 0px 20px;
}

.mobile-cart-total {
  display: flex;
  flex-direction: column;
}

.mobile-total-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mobile-courses-label {
  font-weight: 400;
  font-size: 12px;
}

.course-cost-label {
  color: #1e1e1e;
  font-weight: 400;
  font-size: 11px;
}

.course-cost-label-you {
  color: #1e1e1e;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  gap: 10px;
}

.logo-section {
  display: flex;
  justify-content: space-between;
}

.logo-section img.logo {
  width: 100px !important;
}

.cart-box {
  background-color: #ffe9da;
  padding: 10px;
  border: 1px solid #ffdcc6;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.info-container {
  position: relative;
  justify-self: right;
}

.info-container:hover .info-popup {
  display: block;
}

.info-popup {
  display: none;
  /* Initially hidden */
  position: absolute;
  top: 28px;
  /* Below the image */
  z-index: 1;
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
